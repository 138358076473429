import React, { useState, useEffect } from "react";
import Button from "../../form/Button";
import { useNavigate } from "react-router-dom";
import { appointmentClose } from "../../../service/videoCallService";
import { toast } from "react-toastify";
import { useSocket } from "../../socket/SocketProvider";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import usePageVisibility from "../../../util/PageVisibility";
import useBatteryStatus from "../../elements/hooks/useBatteryStatus";
import useOnlineStatus from "../../../util/OnlineStatus";
import LeaveModal from "../../elements/LeaveModal";
import { getProfileDetails } from "../../../service/authService";

dayjs.extend(duration);

const UserChatsHeader = ({
  toggleVideo,
  toggleAudio,
  isFullscreen,
  isAudioEnabled,
  isVideoEnabled,
  toggleFullscreen,
  setIsAudioEnabled,
  setIsVideoEnabled,
  isInterviewStarted,
  stopStreaming = () => {},
  subscriber,
}) => {
  const navigate = useNavigate();
  const socket = useSocket();
  const token = localStorage.getItem("auth_token");
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const appointmentDataLocal = JSON.parse(
    localStorage.getItem("appointmentData")
  );
  const [isOneHourPassed, setIsOneHourPassed] = useState(false);
  const [appointmentData, setAppointmentData] = useState(appointmentDataLocal);

  const onlineStatus = useOnlineStatus();
  const calculateElapsedTime = () => {
    const currentTime = dayjs();
    const time =
      appointmentData &&
      appointmentData.appointmentId &&
      appointmentData.sessionId
        ? appointmentData?.appointmentStartTime
        : currentTime;
    const startTime = dayjs(time);

    const duration = dayjs.duration(currentTime.diff(startTime));

    const hours = String(duration.hours()).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    // Check if elapsed time is one hour or more
    if (duration.asHours() >= 1 && !isOneHourPassed) {
      setIsOneHourPassed(true);
    }

    return `${hours} : ${minutes} : ${seconds}`;
  };

  useEffect(() => {
    setElapsedTime(calculateElapsedTime());

    const interval = setInterval(() => {
      setElapsedTime(calculateElapsedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [appointmentData]);

  const handleVideoToggle = () => {
    setIsVideoEnabled(!isVideoEnabled);
    toggleVideo(!isVideoEnabled);
  };

  const handleAudioToggle = () => {
    setIsAudioEnabled(!isAudioEnabled);
    toggleAudio(!isAudioEnabled);
  };

  useEffect(() => {
    token &&
      getProfileDetails(token).then((response) => {
        // console.log(response);
        if (response?.data?.status === 200) {
          if (response?.data?.docs?._id) {
            localStorage.setItem(
              "userDetails",
              JSON.stringify(response?.data?.docs)
            );
          }
          if (
            response?.data?.docs?.appointmentData?._id &&
            response?.data?.docs?.appointmentData?.status === "inProgress"
          ) {
            const appointment = {
              appointmentId: response?.data?.docs?.appointmentData?._id,
              appointmentStartTime:
                response?.data?.docs?.appointmentData?.consultantJoinTime,
              callerData: {
                consultant: {
                  id: response?.data?.docs?.appointmentData?.consultantId,
                  image:
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.image,
                  name:
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.firstName +
                    " " +
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.lastName,
                },
                user: {
                  id: response?.data?.docs?.appointmentData?.clientId,
                  image:
                    response?.data?.docs?.appointmentData?.clientDetails?.image,
                  name:
                    response?.data?.docs?.appointmentData?.clientDetails
                      ?.firstName +
                    " " +
                    response?.data?.docs?.appointmentData?.clientDetails
                      ?.lastName,
                },
              },
              consultantId: response?.data?.docs?.appointmentData?.consultantId,
              mode: response?.data?.docs?.appointmentData?.service?.mode?.slug,
              paymentMode: response?.data?.docs?.appointmentData?.paymentMode,
              sessionId: response?.data?.docs?.appointmentData?.sessionId,
              token: response?.data?.docs?.appointmentData?.sessionToken,
            };
            // appointment.appointmentId && setAppointmentProfile(appointment);
            appointment.appointmentId && setAppointmentData(appointment);
            appointment.appointmentId &&
              localStorage.setItem(
                "appointmentData",
                JSON.stringify(appointment)
              );
          } else {
            socket.emit("consultantBusy", {
              consultantId: appointmentData?.callerData?.consultant?.id || "",
              userId: appointmentData?.callerData?.user?.id,
              isBusy: false,
            });
            localStorage.removeItem("appointmentData");
          }
        }
      });
  }, []);

  //--------------Battery Percentage------------------
  const { level, charging } = useBatteryStatus();
  useEffect(() => {
    if (charging === false && Math.round(level * 100) === 20) {
      alert("Your battery is low!");
    } else if (charging === false && Math.round(level * 100) <= 5) {
      stopStreaming();
      appointmentClose({ appointmentId: appointmentData?.appointmentId });
      socket &&
        socket.emit("videoSessionDisconnect", {
          disconnect: true,
          appointmentId: appointmentData?.appointmentId,
        });
      socket &&
        userDetails?.roleCode == "consultant" &&
        socket.emit("consultantBusy", {
          consultantId: userDetails?._id,
          userId: appointmentData?.callerData?.user?.id,
          isBusy: false,
        });
      alert(
        "Your battery is critically low! Plug in your charger to continue."
      );
      navigate("/appointments");
    }
  }, [level, charging]);

  useEffect(() => {
    socket &&
      appointmentData &&
      appointmentData?.callerData?.consultant?.id &&
      socket.emit("consultantBusy", {
        consultantId: appointmentData?.callerData?.consultant?.id,
        userId: appointmentData?.callerData?.user?.id,
        isBusy: true,
      });
  }, [appointmentData, socket]);

  const onConfirm = () => {
    setIsOneHourPassed(false);
    setLoading(true);

    // socket &&
    //   appointmentData?.appointmentId &&
    //   socket.emit("videoSessionDisconnect", {
    //     disconnect: true,
    //     appointmentId: appointmentData?.appointmentId,
    //   });
    // socket &&
    //   userDetails &&
    //   userDetails?.roleCode == "consultant" &&
    //   socket.emit("consultantBusy", {
    //     consultantId: userDetails?._id,
    //     isBusy: false,
    //   });

    appointmentData &&
      appointmentData?.appointmentId &&
      appointmentClose({ appointmentId: appointmentData?.appointmentId })
        .then((res) => {
          console.log(res);

          if (res?.data?.status == 200 || res?.data?.status == 201) {
            setLoading(false);
            socket &&
              socket.emit("videoSessionDisconnect", {
                disconnect: true,
                appointmentId: appointmentData?.appointmentId,
              });

            socket &&
              userDetails?.roleCode == "consultant" &&
              socket.emit("consultantBusy", {
                consultantId: userDetails?._id,
                userId: appointmentData?.callerData?.user?.id,
                isBusy: false,
              });

            socket.emit("userDisconnectFromCallNotify", {
              disconnected: false,
              sessionId: appointmentData.sessionId,
              userId: userDetails?._id,
              appointmentId: appointmentData?.appointmentId,
            });
            toast.info(res?.data?.message);
            setIsOpen(false);
            if (userDetails?.roleCode === "user") {
              window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
            } else {
              window.location.href = "/appointments";
              localStorage.removeItem("appointmentData");
            }
            stopStreaming();
          } else if (res?.data?.status === 400) {
            setLoading(false);
            setIsOpen(false);
            toast.info(res?.data?.message, { autoClose: 3000 });
            if (userDetails?.roleCode === "user") {
              window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
            } else {
              window.location.href = "/appointments";
              localStorage.removeItem("appointmentData");
            }
          } else {
            setLoading(false);
            toast.error(res?.data?.message || res?.data?.error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  useEffect(() => {
    // Check if the flag is false
    if (!subscriber) {
      const timer = setTimeout(() => {
        onConfirm();
      }, 2 * 60 * 1000);
      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [subscriber]);

  // useEffect(() => {
  //   if (!appointmentData) window.location.href = "/appointments";
  // }, [appointmentData]);

  if (!onlineStatus) {
    toast.error("You are offline!", { autoClose: 5000 });
    !token && localStorage.removeItem("appointmentData");
    !token && navigate("/");
  }

  //one houre ---------------

  useEffect(() => {
    if (isOneHourPassed === true) {
      toast.info("You have reached your maximum calling duration!");
      onConfirm();
    }
  }, [isOneHourPassed]);

  useSocket("videoSessionDisconnectResponse", async (data) => {
    if (
      data?.disconnect === true &&
      data?.appointmentId === appointmentData?.appointmentId
    ) {
      if (userDetails?.roleCode === "user") {
        window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
      } else {
        window.location.href = "/appointments";
        localStorage.removeItem("appointmentData");
      }

      // appointmentClose({ appointmentId: data?.appointmentId }).then((res) => {
      //   console.log(first)
      //   if (res?.data?.status == 200 || res?.data?.status == 201) {
      //     if (userDetails?.roleCode === "user") {
      //       window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
      //     } else {
      //       window.location.href = "/appointments";
      //       localStorage.removeItem("appointmentData");
      //     }
      //   }
      // });
      // if (userDetails?.roleCode === "user") {
      //   window.location.href = `/review-appointment/${appointmentData?.appointmentId}`;
      // } else {
      //   window.location.href = "/appointments";
      //   localStorage.removeItem("appointmentData");
      // }
    }
  });
  usePageVisibility(token, socket, userDetails?._id);

  return (
    <div className="md:flex block bg-slate-900 md:px-8 px-5 py-4 absolute bottom-0 w-full z-10 justify-between">
      <div className="flex items-center md:space-x-5 space-x-2 text-gray-300 lg:w-5/12 md:w-9/12 w-full md:justify-start justify-between">
        <div className="flex space-x-2 md:w-[120px] w-[160px]">
          <div className="text-red-600">
            <i className="fa-sharp fa-regular fa-record-vinyl"></i>
          </div>
          <div>{elapsedTime}</div>
        </div>
        <div className="text-sm text-white bg-prontopsy-pink/60 px-4 py-2 rounded-full">
          {userDetails?.roleCode === "user"
            ? appointmentData?.callerData?.consultant?.name
            : appointmentData?.callerData?.user?.name}
        </div>
        <div className="text-sm capitalize text-white bg-prontopsy-pink/60 px-4 py-2 rounded-full">
          {appointmentData?.mode}
        </div>
      </div>
      <div className="flex justify-between md:w-[53%] w-full items-center md:mt-0 mt-5">
        <div className="text-slate-100 md:justify-center justify-end flex gap-5">
          {isInterviewStarted && (
            <div className="flex gap-5 text-xl">
              {appointmentData?.mode == "video" && (
                <button onClick={handleVideoToggle}>
                  <div className="w-10 aspect-square bg-prontopsy-lightblue/50 text-base rounded-full flex justify-center items-center">
                    <i
                      className={` ${
                        !isVideoEnabled
                          ? " fa-solid fa-video-slash"
                          : "fa-solid fa-video"
                      }`}
                    ></i>
                  </div>
                </button>
              )}
              <button onClick={handleAudioToggle}>
                <div className="w-10 aspect-square bg-prontopsy-lightblue/50 text-base rounded-full flex justify-center items-center">
                  <i
                    className={` ${
                      !isAudioEnabled
                        ? " fa-solid fa-microphone-slash"
                        : "fa-solid fa-microphone"
                    }`}
                  ></i>
                </div>
              </button>
            </div>
          )}
          <button
            onClick={toggleFullscreen}
            className={`bg-blue-500 w-9 aspect-square rounded text-base flex justify-center items-center ${
              isFullscreen ? "bg-blue-600" : "bg-blue-500"
            }`}
          >
            {isFullscreen ? (
              <i class="fa-solid fa-compress-wide"></i>
            ) : (
              <i class="fa-solid fa-expand"></i>
            )}
          </button>
        </div>
        <div className="flex items-center text-gray-100 space-x-10 md:justify-end justify-center">
          {isInterviewStarted && (
            <Button
              buttonLabel={"Leave"}
              buttonIcon={"fa-solid fa-square-phone-hangup"}
              buttonIconPosition={"left"}
              buttonClasses={
                "uppercase bg-red-700 text-sm hover:bg-red-600 text-white px-4 py-2 rounded"
              }
              buttonFunction={() => {
                isFullscreen && toggleFullscreen();
                setIsOpen(true);
              }}
            />
          )}
        </div>
      </div>
      <LeaveModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={onConfirm}
        loading={loading}
      />
    </div>
  );
};

export default UserChatsHeader;
