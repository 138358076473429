import { apiRequest } from "../util/async/apiUtils";

export const fetchCardList = async (params) => {
  try {
    const res = await apiRequest(
      `${"get"}`,
      `${"/payment-gateway/user-card-recover"}`
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteCardService = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/payment-gateway/user-delete-card"}`,
      {
        body: {
          ...params,
        },
      }
    );

    return res;
  } catch (err) {
    return err;
  }
};

export const addCardService = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/payment-gateway/user-add-card"}`,
      {
        body: {
          ...params,
        },
      }
    );

    return res;
  } catch (err) {
    return err;
  }
};

export const addStripeAccountService = async (params) => {
  try {
    const res = await apiRequest(
      `${"put"}`,
      `${"/user/update-stripe-credential"}`,
      {
        body: {
          ...params,
        },
      }
    );

    return res;
  } catch (err) {
    return err;
  }
};

export const deleteStripeAccountService = async () => {
  try {
    const res = await apiRequest(
      `${"put"}`,
      `${"/user/delete-stripe-credential"}`,
      {
        body: {
          stripeAccountId: true,
        },
      }
    );

    return res;
  } catch (err) {
    return err;
  }
};
