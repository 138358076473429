import React from "react";
import { classNames } from "../../helpers/classNames";

const Accordion = ({
  title,
  children,
  divClass,
  divHeaderClass,
  divContainerClass,
  accordianTitle,
  iconClass,
  isOpenProp,
  toggleAccordion,
}) => {
  return (
    <div
      className={classNames(
        "bg-slate-100 relative p-0 rounded-md overflow-hidden mb-4",
        isOpenProp ? "border-slate-100" : "border-transparent",
        divClass
      )}
    >
      <div
        className={classNames(
          "flex justify-between items-center px-4 py-4 bg-transparent cursor-pointer",
          isOpenProp ? "border-electric-violet" : "border-transparent",
          divHeaderClass
        )}
        onClick={toggleAccordion}
      >
        <div
          className={classNames(
            "capitalize text-base md:text-lg font-bold my-auto w-11/12",
            accordianTitle,
            isOpenProp ? "text-black" : "text-black"
          )}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <div
          className={classNames(
            "text-sm md:w-8 w-6 bg-prontopsy-blue aspect-square flex items-center justify-center rounded-full transition-all duration-200",
            isOpenProp ? "bg-electric-violet/10 text-white" : "text-white",
            iconClass
          )}
        >
          <i
            className={classNames(
              "fa-regular fa-fw",
              isOpenProp ? "fa-minus" : "fa-plus"
            )}
          ></i>
        </div>
      </div>
      <div
        className={classNames(
          "overflow-hidden transition-all duration-200",
          isOpenProp ? "max-h-60" : "max-h-0",
          divContainerClass
        )}
      >
        <div
          className="p-5 mb-0 border-t border-slate-200"
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </div>
    </div>
  );
};

export default Accordion;
