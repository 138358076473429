import React, { useEffect, useRef, useState } from "react";
import { register } from "swiper/element/bundle";
import TestimonialItem from "../../single/TestimonialItem";
import { getTestimonialList } from "../../../service/testimonialService";
import { useSelector } from "react-redux";
import Button from "../../form/Button";
register();

const Testimonial = () => {
  const swiperElRef = useRef(null);
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [testimonialData, setTestimonialData] = useState(null);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    getTestimonialList(setData, setLoaded, { limit: 500 }).then((res) => {});
  }, [language]);

  useEffect(() => {
    let information =
      data &&
      data.length > 0 &&
      data.map((elm) => ({
        _id: elm?._id,
        name: elm?.name,
        title: elm?.title,
        image: elm?.image?.url,
        description: elm?.description,
      }));
    setInfo(information);
  }, [data]);

  useEffect(() => {
    const Data = {
      title: {
        en: "What<br/><span class='text-prontopsy-pink'>Our Client Say</span>",
        es: "Lo Que<br/><span class='text-prontopsy-pink'>Dice Nuestro Cliente</span>",
        it: "Cosa<br/><span class='text-prontopsy-pink'>Dice Il Nostro Cliente</span>",
      },
      testimonial: info || [],
    };
    setTestimonialData(Data);
  }, [info]);

  // Use the swiperRef to initialize Swiper and set up navigation
  useEffect(() => {
    const swiperEl = swiperElRef.current;
    const swiperParams = {
      loop: true,
      navigation: {
        nextEl: ".testimonialSlider-next",
        prevEl: ".testimonialSlider-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: true,
          loop: true,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: false,
          loop: true,
        },
        1280: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: false,
          loop: false,
        },
      },
    };

    if (swiperEl) {
      Object.assign(swiperEl, swiperParams);
      swiperEl.initialize();
    }
  }, [info]);

  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 2xl:py-24 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full text-center">
            {testimonialData?.title && (
              <h2
                className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{
                  __html: testimonialData?.title[language],
                }}
              ></h2>
            )}
          </div>
          {info.length > 0 && (
            <div className="relative w-full max-w-7xl mx-auto mt-10">
              <swiper-container
                ref={swiperElRef}
                class="testimonialSlider md:-mx-10 -mx-0"
                init="false"
              >
                {info.map((item) => (
                  <swiper-slide key={item._id} className="">
                    <TestimonialItem data={item} />
                  </swiper-slide>
                ))}
              </swiper-container>
              <div className="relative flex justify-center !mt-4 lg:!mt-6 xl:!mt-8">
                <div className="flex justify-center space-x-2 px-4">
                  <Button
                    buttonClasses={
                      "testimonialSlider-prev !px-0 aspect-square !rounded-full !bg-transparent !text-neutral-700 border border-neutral-700 !h-10 lg:!h-12 xl:!h-14 !text-2xl"
                    }
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-chevron-left"}
                    buttonIconPosition={"left"}
                  />
                  <Button
                    buttonClasses={
                      "testimonialSlider-next !px-0 aspect-square !rounded-full !bg-transparent !text-neutral-700 border border-neutral-700 !h-10 lg:!h-12 xl:!h-14 !text-2xl"
                    }
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-chevron-right"}
                    buttonIconPosition={"left"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Testimonial;
