import React, { useEffect, useState } from "react";
import PageBanner from "../components/sections/PageBanner";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import PrivacyPolicy from "../components/sections/privacypolicy/PrivacyPolicy";
import { useSelector } from "react-redux";
import { setTitle } from "../helpers/MetaTag";

const PrivacyPolicyPage = () => {
  setTitle("Prontopsy |Privacy Policy");

  const [bannerInfo, setbannerInfo] = useState({});

  const data = useSelector((state) => state?.pageSlice?.data);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  useEffect(() => {
    let info =
      data.length > 0 &&
      data.filter((elm) => elm?.title?.en == "privacy_policy");
    setbannerInfo(info[0]);
  }, [data]);

  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.title?.[language],
  };
  const termsData = {
    description:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.description?.[language],
  };

  return (
    <>
      <PageBanner bannerData={bannerData} />
      <PrivacyPolicy termsData={termsData} />
      <SmartPortal />
      <Register />
    </>
  );
};

export default PrivacyPolicyPage;
