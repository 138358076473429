import React, { useEffect, useRef, useState } from "react";
import RatingStars from "../../elements/RatingStars";
import Image from "../../elements/Image";
import noImage from "../../../assets/images/noImageFound.webp";
import {
  fetchAvgStatReview,
  fetchReviewList,
} from "../../../service/reviewService";
import dayjs from "dayjs";
import NoDataFound from "../../elements/NoDataFound";
import { consultantDetails as consultantDetailsLang } from "../../elements/language";
import { useSelector } from "react-redux";

const Reviews = ({ consultantDetails }) => {
  const token = localStorage.getItem("auth_token");
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [statistics, setStatistics] = useState(null);
  // for pagination
  const [limit, setLimit] = useState(4);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [allList, setAllList] = useState([]);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [allList]);

  useEffect(() => {
    if (scrolledToBottom && hasNextPage && loaded) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage, loaded]);

  useEffect(() => {
    consultantDetails._id &&
      fetchReviewList(
        consultantDetails?._id,
        { limit: 5, page: page, sortQuery: "-createdAt" },
        token,
        setList,
        setLoaded,
        setHasNextPage
      ).then((res) => {
        if (res?.data?.docs?.status === 200) {
          let metaData = res?.data?.docs?.metadata;
          setTotalDocs(metaData?.totalDocs);
          page !== 1
            ? setAllList((prevList) => [...prevList, ...res?.data?.docs?.data])
            : setAllList(res?.data?.docs?.data);
        }
      });
  }, [consultantDetails._id, page]);

  useEffect(() => {
    consultantDetails._id &&
      fetchAvgStatReview(consultantDetails?._id, token, setStatistics);
  }, [consultantDetails._id]);

  const sections = [
    {
      title: {
        en: "Skill and Knowledge",
        es: "Habilidad y conocimiento",
        it: "Abilità e Conoscenza",
      },
      ratingData: statistics && statistics?.data?.avgKnowledgeSkill,
    },
    {
      title: {
        en: "Cordiality and Communication",
        es: "Cordialidad y Comunicación",
        it: "Cordialità e Comunicazione",
      },
      ratingData: statistics && statistics?.data?.avgCommunication,
    },
    {
      title: {
        en: "Internet Access",
        es: "Acceso a Internet",
        it: "Accesso a Internet",
      },
      ratingData: statistics && statistics?.data?.avgInternetAccess,
    },
  ];

  const reviewsData =
    list &&
    Array.isArray(list) &&
    list.length > 0 &&
    allList.map((elm, index) => ({
      id: elm?._id,
      image: elm?.clientData?.image || noImage,
      user:
        elm?.clientData?.firstName && elm?.clientData?.lastName
          ? `${elm.clientData.firstName} ${elm.clientData.lastName}`
          : "N/A",
      rating: elm?.review,
      date: dayjs(elm?.createdAt).format("MMM D, YYYY"),
      ratingData: elm?.rating,
    }));

  return (
    <div className="relative">
      <h5 className="text-lg text-prontopsy-blue font-UnisonPro font-bold pt-2 pb-4 italic">
        {consultantDetailsLang?.summery?.[language]}
      </h5>
      <div className="grid md:grid-cols-3 grid-cols-2 gap-5">
        {statistics &&
          sections.map((section, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center w-full py-10 space-y-2 border border-neutral-300 ${
                index % 2 === 1 ? " border-l border-r border-neutral-300" : ""
              }`}
            >
              <div className="max-w-44 w-full text-xs sm:text-base font-RobotoSlab font-bold text-center text-slate-700">
                {section?.title?.[language]}
              </div>
              <RatingStars data={section.ratingData} />
            </div>
          ))}
      </div>
      <div className="grid grid-cols-1 gap-0 pt-8">
        <h5 className="text-lg text-prontopsy-blue font-UnisonPro font-bold italic">
          {consultantDetailsLang?.clientFeedback?.[language]}
        </h5>
        {list && Array.isArray(list) && list.length > 0 ? (
          <div ref={scrollableRef} className="h-[40vh] overflow-auto">
            {list.length > 0 &&
              reviewsData.map((review, index) => (
                <div
                  key={index}
                  className="flex items-center md:gap-6 gap-4 py-4"
                >
                  <div className="w-20 h-20 rounded-full overflow-hidden border border-neutral-300 p-2 flex-shrink-0">
                    <div className="w-full h-full">
                      <Image
                        src={review.image}
                        alt={""}
                        effect={"blur"}
                        className={"object-cover w-full h-full rounded-full"}
                      />
                    </div>
                  </div>
                  <div className="w-[calc(100%-80px)] flex-shrink">
                    <div className="font-RobotoSlab text-xs sm:text-base lg:text-lg text-slate-700 capitalize font-bold">
                      {review.user}
                    </div>
                    <RatingStars data={review.ratingData} />
                    <div
                      className="text-neutral-500 text-xs sm:text-base lg:text-base font-RobotoSlab capitalize pt-2"
                      dangerouslySetInnerHTML={{ __html: review.rating }}
                    ></div>
                    <div className="text-neutral-400 text-xs sm:text-sm font-RobotoSlab pt-1">
                      {review.date}
                    </div>
                  </div>
                </div>
              ))}
            {!loaded && (
              <div className="flex justify-center mt-4">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-7">
            <NoDataFound />
          </div>
        )}
      </div>
    </div>
  );
};

export default Reviews;
