import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../shared/Sidebar";
import Image from "../components/elements/Image";
import User from "../assets/images/user-03.webp";
import Button from "../components/form/Button";
import { classNames } from "../helpers/classNames";
import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";
import { useDispatch, useSelector } from "react-redux";
import { profileSideBar } from "../components/elements/language";
import LogoutModal from "../components/elements/LogoutModal";
import { setAuthData } from "../store/slice/authSlice";
import { toast } from "react-toastify";
import { fetchProfileDetails } from "../service/profileService";
import { setProfileData } from "../store/slice/profileSlice";
import { addStripeAccountService } from "../service/stripeService";
import { useSocket } from "../components/socket/SocketProvider";
import { verifyToken } from "../service/authService";

const InnerLayout = ({ profileTitle }) => {
  const [profileMenuToggle, setprofileMenuToggle] = useState(false);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [profile, setProfile] = useState({});
  const sideBarLang = profileSideBar;
  const userDetails = localStorage.getItem("userDetails");
  const role = userDetails && JSON.parse(userDetails)?.roleCode;
  const [showModal, setShowModal] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.initialProfileSlice.data);
  const socket = useSocket();
  const profiledata = useSelector((state) => state?.initialProfileSlice?.data);

  useEffect(() => {
    auth && auth?._id && fetchProfileDetails(auth?._id, setProfile);
  }, [auth]);
  useEffect(() => {
    dispatch(setProfileData(profile));
  }, [profile]);

  useEffect(() => {
    localStorage.removeItem("pageName");
  }, []);

  const sidebarData = [
    {
      _id: 1,
      link: "/control-panel",
      label: sideBarLang?.controlPanel?.[language],
      icon: "fa-regular fa-display-chart-up-circle-currency",
    },
    {
      _id: 2,
      link: "/profile",
      label: sideBarLang?.myProfile?.[language],
      icon: "fa-solid fa-user",
    },
    {
      _id: 3,
      link: `/consultant-profile/${auth?._id}`,
      // link: "/view-online",
      label: sideBarLang?.viewOnline?.[language],
      icon: "fa-solid fa-camera-viewfinder",
    },
    {
      _id: 4,
      link: "/appointments",
      label: sideBarLang?.appointments?.[language],
      icon: "fa-regular fa-bars",
    },
    {
      _id: 5,
      link: "/clients",
      label: sideBarLang?.myClients?.[language],
      icon: '"fa-solid fa-users',
    },
    {
      _id: 6,
      link: "/email",
      label: sideBarLang?.email?.[language],
      icon: "fa-solid fa-envelope",
    },
    {
      _id: 7,
      link: "/reviews",
      label: sideBarLang?.myReviews?.[language],
      icon: "fa-solid fa-stars",
    },
    {
      _id: 8,
      // link: "/profile",
      label: sideBarLang?.logOut?.[language],
      icon: "fa-regular fa-arrow-right-from-bracket",
    },
  ];
  const userSidebarData = [
    {
      _id: 1,
      link: "/control-Panel",
      label: sideBarLang?.controlPanel?.[language],
      icon: "fa-regular fa-display-chart-up-circle-currency",
    },
    {
      _id: 2,
      link: "/profile",
      label: sideBarLang?.myProfile?.[language],
      icon: "fa-solid fa-user",
    },
    {
      _id: 3,
      link: "/appointments",
      label: sideBarLang?.appointments?.[language],
      icon: "fa-regular fa-bars",
    },
    {
      _id: 4,
      link: "/email",
      label: sideBarLang?.email?.[language],
      icon: "fa-solid fa-envelope",
    },
    {
      _id: 5,
      link: "/wish-list",
      label: sideBarLang?.wishList?.[language],
      icon: "fa-solid fa-heart",
    },
    {
      _id: 6,
      // link: "/logout",
      // onClick: openModal,
      label: sideBarLang?.logOut?.[language],
      icon: "fa-regular fa-arrow-right-from-bracket",
    },
  ];
  const profileDetails = useSelector((state) => state.profileSlice.data);
  const profileData = {
    image: profileDetails?.image?.url,
    userName:
      (profileDetails?.firstName || "") +
      " " +
      (profileDetails?.lastName || ""),

    userEmail: profileDetails?.email || "",
  };

  const isUserOnline = {
    status: "online",
    label1: "Online",
    label2: "Available",
  };

  const handleLogOut = () => {
    if (socket && userDetails) {
      profileDetails &&
        profileDetails?.onlineStatus == "online" &&
        socket.emit("userOffline", profiledata?._id);
      dispatch(setAuthData({}));
      // localStorage.removeItem("appointmentData");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userDetails");
      toast.success("You are logged out!");
      setLoaded(false);
      setTimeout(() => {
        // navigate("/");
        setShowModal(false);
        setLoaded(true);
      }, 500);
    } else if (!socket) {
      toast.error("Network error. Please reload your browser once!");
      window.location.reload();
    }
  };

  useEffect(() => {
    verifyToken().then((res) => {
      if (!res || res?.status === 401) {
        handleLogOut();
      }
    });
  }, []);

  return (
    <>
      <DefaultHeader
        headerClass={"!bg-white !shadow-md"}
        signinButton={false}
        headerProfileContent={true}
      />
      <div className="relative md:pt-32 pt-20">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto mt-10">
          <div className="py-5 md:px-10 px-6 relative bg-prontopsy-blue rounded-t-2xl overflow-hidden">
            <div className="flex items-center justify-between gap-10 ">
              <div className="text-lg text-white font-UnisonPro font-bold italic uppercase">
                {profileTitle}
              </div>
              <div className="relative xl:flex items-center gap-3 hidden">
                <div className="w-14 h-14 rounded-lg overflow-hidden flex-shrink-0 flex">
                  <Image
                    src={profileData?.image}
                    alt={""}
                    effect={"blur"}
                    className={"object-cover w-full h-full"}
                  />
                </div>
                <div className="space-y-1 flex-shrink">
                  <div className="text-base xl:text-lg text-white font-UnisonPro font-bold italic uppercase">
                    {profileData?.userName || ""}
                  </div>
                  <div className="text-sm xl:text-base text-white font-RobotoSlab font-normal uppercase">
                    {profileData?.userEmail || ""}
                  </div>
                </div>
              </div>
              <div className="relative block xl:hidden">
                <Button
                  buttonHasLink={false}
                  buttonIcon={"fa-regular fa-arrow-right"}
                  buttonIconPosition={"left"}
                  buttonClasses={
                    "!px-0 text-xl aspect-square !rounded-lg !bg-prontopsy-yellow !text-black hover:!bg-prontopsy-blue hover:!text-prontopsy-yellow"
                  }
                  buttonFunction={() =>
                    setprofileMenuToggle(!profileMenuToggle)
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-10 ">
            <div
              className={classNames(
                "fixed h-[calc(100vh+80px)] top-0 xl:relative w-72 bg-white left-0 z-[9] xl:w-1/4 transition-all duration-200",
                profileMenuToggle
                  ? "translate-x-0 opacity-100 visible"
                  : "-translate-x-full xl:translate-x-0 opacity-0  lg:opacity-100 invisible xl:visible"
              )}
            >
              <Sidebar
                data={role == "user" ? userSidebarData : sidebarData}
                isOnline={isUserOnline}
                handleDelete={openModal}
              />
            </div>
            <div
              className={classNames(
                "fixed top-0 left-0 w-full h-screen z-[8] bg-black/25 transition-all duration-200 cursor-pointer",
                profileMenuToggle ? "opacity-80 visible" : "opacity-0 invisible"
              )}
              onClick={() => setprofileMenuToggle(false)}
            ></div>
            <div className="w-full xl:w-3/4 py-2">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter footerMenuContent={true} />

      <LogoutModal
        show={showModal}
        onClose={closeModal}
        onConfirm={handleLogOut}
        loaded={loaded}
      />
    </>
  );
};

export default InnerLayout;
