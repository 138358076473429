import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "./util/async/apiUtils";
import { verifyToken } from "./service/authService";
const ProtectedRoute = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [auth, setAuth] = useState(false);
  let token_ = localStorage.getItem("auth_token");
  useEffect(() => {
    const checkData = async () => {
      verifyToken().then((res) => {
        if (!res || res?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/signin");
        } else {
          setAuth(true);
        }
      });
    };

    if (!token_) {
      setIsLoader(false);
      navigate("/signin");
    } else {
      checkData();
    }
  }, [token_, dispatch]);

  return <>{auth && props.children}</>;
};
export default ProtectedRoute;
