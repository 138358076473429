import React, { useEffect, useState } from "react";
import Button from "../components/form/Button";
import { useNavigate, useParams } from "react-router-dom";
import { reviewAddFront } from "../service/videoCallService";
import { toast } from "react-toastify";
import { useSocket } from "../components/socket/SocketProvider";
import { useSelector } from "react-redux";

const ReviewPage = () => {
  const appointmentData = JSON.parse(localStorage.getItem("appointmentData"));
  const { id } = useParams();
  const navigate = useNavigate();
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false);
  const [knowledgeSkill, setKnowledgeSkill] = useState(0);
  const [communication, setCommunication] = useState(0);
  const [internetAccess, setInternetAccess] = useState(0);
  const socket = useSocket();
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;

  const modalLang = {
    heading: {
      en: "Leave a Review",
      it: "Lascia una recensione",
      es: "Deja una reseña",
    },
    skill: {
      en: "Skill and Knowledge",
      es: "Habilidad y conocimiento",
      it: "Abilità e Conoscenza",
    },
    communication: {
      en: "Cordiality and Communication",
      es: "Cordialidad y Comunicación",
      it: "Cordialità e Comunicazione",
    },
    internet: {
      en: "Internet Access",
      it: "Accesso a Internet",
      es: "Acceso a Internet",
    },
    review: {
      en: "Review",
      it: "Recensione",
      es: "Reseña",
    },
    notNow: {
      en: "Not Now",
      it: "Non adesso",
      es: "No ahora",
    },
    submit: {
      en: "Submit",
      it: "Invia",
      es: "Entregar",
    },
  };

  useEffect(() => {
    if (appointmentData?.appointmentId !== id) {
      navigate("/appointments");
      socket &&
        socket.emit("consultantBusy", {
          consultantId: appointmentData?.consultantId || "",
          userId: appointmentData?.callerData?.user?.id,
          isBusy: false,
        });
    }
  }, [id]);

  const handleSubmit = () => {
    if (knowledgeSkill < 1) {
      return toast.error("Please rate for skill and knowledge.", {
        autoClose: 3000,
      });
    }
    if (communication < 1) {
      return toast.error("Please rate for cordiality and communication.", {
        autoClose: 3000,
      });
    }
    if (internetAccess < 1) {
      return toast.error("Please rate for internet access.", {
        autoClose: 3000,
      });
    }
    if (review == "") {
      return toast.error("Review cannot be empty !", { autoClose: 3000 });
    }
    setLoading(true);
    reviewAddFront({
      review,
      knowledgeSkill,
      communication,
      internetAccess,
      appointmentId: appointmentData?.appointmentId,
      consultantId: appointmentData?.consultantId,
    }).then((res) => {
      if (res?.status == 200 || res?.status == 201) {
        socket &&
          socket.emit("consultantBusy", {
            consultantId: appointmentData?.consultantId || "",
            userId: appointmentData?.callerData?.user?.id,
            isBusy: false,
          });
        navigate(`/appointments-details/${appointmentData?.appointmentId}`);
        setLoading(false);
        localStorage.removeItem("appointmentData");
        toast.success("Review added successfully !");
      } else {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    });
  };

  return (
    <div className="w-full h-screen flex justify-center items-center bg-prontopsy-blue">
      <div className="space-y-6 p-6  w-[450px] rounded-md bg-white shadow-md">
        <h2 className="text-2xl font-bold mb-4">
          {modalLang?.heading?.[language]}
        </h2>

        <div className="flex flex-col">
          <label
            htmlFor="knowledgeSkill"
            className="text-sm font-semibold mb-1"
          >
            {modalLang?.skill?.[language]}
          </label>
          <StarRating rating={knowledgeSkill} setRating={setKnowledgeSkill} />
        </div>

        <div className="flex flex-col">
          <label htmlFor="communication" className="text-sm font-semibold mb-1">
            {modalLang?.communication?.[language]}
          </label>
          <StarRating rating={communication} setRating={setCommunication} />
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="internetAccess"
            className="text-sm font-semibold mb-1"
          >
            {modalLang?.internet?.[language]}
          </label>
          <StarRating rating={internetAccess} setRating={setInternetAccess} />
        </div>

        <div className="flex flex-col">
          <label htmlFor="review" className="text-sm font-semibold mb-1">
            {modalLang?.review?.[language]}
          </label>
          <textarea
            id="review"
            value={review}
            onChange={(e) => setReview(e.target.value)}
            className="border rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 h-24 "
          ></textarea>
        </div>

        <div className="flex justify-end space-x-4">
          <Button
            buttonFunction={() => {
              socket &&
                socket.emit("consultantBusy", {
                  consultantId: appointmentData?.consultantId || "",
                  userId: appointmentData?.callerData?.user?.id,
                  isBusy: false,
                });
              navigate(
                `/appointments-details/${appointmentData?.appointmentId}`
              );
              localStorage.removeItem("appointmentData");
            }}
            buttonLabel={`${modalLang?.notNow?.[language]}`}
            buttonIcon={"fa-light fa-xmark"}
            buttonIconPosition={"left"}
            buttonClasses={
              "uppercase bg-red-500 text-sm hover:bg-red-600 text-white px-4 py-2 rounded"
            }
          />
          <Button
            buttonLabel={`${modalLang?.submit?.[language]}`}
            buttonIcon={"fa-solid fa-check"}
            buttonIconPosition={"left"}
            buttonClasses={
              "uppercase bg-blue-500 text-sm hover:bg-blue-600 text-white px-4 py-2 rounded"
            }
            loading={loading}
            buttonFunction={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewPage;

const StarRating = ({ rating, setRating }) => {
  return (
    <div className="flex space-x-1 ">
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1;
        return (
          <label key={index} className="cursor-pointer">
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => setRating(ratingValue)}
              className="hidden"
            />
            <i
              className={`fa fa-star ${
                ratingValue <= rating ? "text-yellow-500" : "text-gray-300"
              }`}
              style={{ fontSize: "24px" }}
            ></i>
          </label>
        );
      })}
    </div>
  );
};
