import axios from "axios";
import BASE_URL from "../config/host";

export const fetchProfileDetails = async (
  id,
  setList,
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    let data = await axios.get(`${BASE_URL}/user/details/${id}`);
    if (data.status === 200) {
      setList(data?.data?.docs);
      setLoaded(true);
    }

    return data.data;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchCountryList = async (setList, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/countries`, {
      params: {
        limit: 300,
      },
    });

    if (response.status === 200) {
      setList(response?.data?.docs?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const updateProfileService = async (params, setLoaded = () => {}) => {
  setLoaded(false);

  // Create a new FormData object
  const formData = new FormData();

  // Append each parameter to the FormData object
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });

  // Get the token from localStorage or wherever it's stored in your application
  const token = localStorage.getItem("auth_token"); // Replace 'token' with your actual token key

  try {
    let response = await axios.put(
      `${BASE_URL}/user/edit`,
      {
        ...params,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Set the Bearer token in the headers
        },
      }
    );

    if (response.status === 200) {
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const changePasswordService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  // Get the token from localStorage or wherever it's stored in your application
  const token = localStorage.getItem("auth_token"); // Replace 'token' with your actual token key

  try {
    let response = await axios.put(
      `${BASE_URL}/profile/change-password`,
      {
        ...params,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Bearer token in the headers
        },
      }
    );

    if (response.status === 200) {
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
