import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setProfileData } = profileSlice.actions;
export default profileSlice.reducer;
