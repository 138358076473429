import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import ImageBanner from "../assets/images/areabanner.webp";
import PageBanner from "../components/sections/PageBanner";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import AreaOfIntervention from "../components/sections/AreaOfIntervention";
import { useSelector } from "react-redux";
const AreaIntervention = () => {
  const [bannerInfo, setbannerInfo] = useState({});
  setTitle("Prontopsy | About Us");
  const data = useSelector((state) => state?.pageSlice?.data);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  useEffect(() => {
    let info =
      data.length > 0 && data.filter((elm) => elm?.title?.en == "Intervention");
    setbannerInfo(info[0]);
  }, [data]);

  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.title?.[language],
  };
  const interventionData = {
    intervention: [
      {
        _id: 1,
        styleType: 1,
        value: 1,
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 2,
        styleType: 2,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 3,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 4,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 5,
        styleType: 1,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 6,
        styleType: 2,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 7,
        styleType: 2,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 8,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 9,
        styleType: 1,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 10,
        styleType: 1,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 11,
        styleType: 2,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 12,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 13,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 14,
        styleType: 1,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 15,
        styleType: 2,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 16,
        styleType: 2,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 17,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 18,
        styleType: 1,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 19,
        styleType: 2,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 20,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 21,
        styleType: 1,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 22,
        styleType: 1,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 23,
        styleType: 2,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 24,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 25,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 26,
        styleType: 2,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 27,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 28,
        styleType: 2,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
      {
        _id: 29,
        styleType: 3,
        value: "0",
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details",
        },
      },
    ],
  };
  return (
    <>
      <PageBanner bannerData={bannerData} />
      <AreaOfIntervention interventionData={interventionData} />
      <SmartPortal />
      <Register />
    </>
  );
};

export default AreaIntervention;
