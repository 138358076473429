import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const faqTabSlice = createSlice({
  name: "faqTab",
  initialState,
  reducers: {
    setFaqTabData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setFaqTabData } = faqTabSlice.actions;
export default faqTabSlice.reducer;
