import React from "react";
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";

const FeatureSlideItem = ({ data }) => {
  return (
    <>
      <div className="relative w-full flex-grow flex pt-5 pb-10 px-5">
        <div className=" w-full flex items-center pt-16 pb-16 px-6 text-center shadow-xl border border-slate-50 bg-white relative before:content-[''] before:w-24 before:h-3 before:bg-[#39C412] before:absolute before:-bottom-[1px] before:left-1/2 before:transform before:-translate-x-1/2">
          <div className=" flex flex-col w-full h-full items-center border border-neutral-300 px-0 py-4 md:py-6 lg:py-8">
            {data?.icon && (
              <div
                className={classNames(
                  "w-16 md:w-20 lg:w-24 overflow-hidden rounded-full -mt-12 md:-mt-16 lg:-mt-20 mb-8 flex-shrink-0",
                  data?.styleType === 1
                    ? "bg-prontopsy-yellow"
                    : data?.styleType === 2
                    ? "bg-prontopsy-blue"
                    : "bg-prontopsy-yellow"
                )}
              >
                <Image
                  src={data?.icon}
                  alt={""}
                  effect={"blur"}
                  className={"object-contain"}
                />
              </div>
            )}
            {data.name && (
              <h4
                className="font-UnisonPro font-bold italic text-lg md:text-xl xl:text-[20px] text-prontopsy-blue !leading-[1.5rem]"
                dangerouslySetInnerHTML={{ __html: data.name }}
              ></h4>
            )}
            {data.description && (
              <div
                className="text-sm md:text-base lg:text-[17px] text-neutral-700 mt-6 w-full max-w-72 mx-auto !leading-[1.4rem] "
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureSlideItem;
