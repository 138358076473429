import React from 'react';

const RatingStars = ({ data }) => {
  let width = 0;
  if (data <= 5) {
    width = data / 5 * 100;
  }
  return (
    <>
      <div className="relative">
        <div className="relative w-[100px] flex items-center text-lg text-neutral-300">
          <i className="fa-solid fa-fw fa-star fa-sharp w-5"></i>
          <i className="fa-solid fa-fw fa-star fa-sharp w-5"></i>
          <i className="fa-solid fa-fw fa-star fa-sharp w-5"></i>
          <i className="fa-solid fa-fw fa-star fa-sharp w-5"></i>
          <i className="fa-solid fa-fw fa-star fa-sharp w-5"></i>
        </div>
        <div className="absolute top-0 left-0 max-w-[100px] flex items-center text-lg text-amber-500 overflow-hidden" style={{ width: width }}>
          <i className="fa-solid fa-fw fa-star fa-sharp w-5"></i>
          <i className="fa-solid fa-fw fa-star fa-sharp w-5"></i>
          <i className="fa-solid fa-fw fa-star fa-sharp w-5"></i>
          <i className="fa-solid fa-fw fa-star fa-sharp w-5"></i>
          <i className="fa-solid fa-fw fa-star fa-sharp w-5"></i>
        </div>
      </div>
    </>
  );
};

export default RatingStars;