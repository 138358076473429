import React, { useEffect, useRef, useState } from "react";
import { register } from "swiper/element/bundle";
import Ac from "../../../assets/images/paymentsicon/image19.png";
import Ac1 from "../../../assets/images/paymentsicon/image20.png";
import Ac2 from "../../../assets/images/paymentsicon/image21.png";
import Ac4 from "../../../assets/images/paymentsicon/image23.png";
import Ac5 from "../../../assets/images/paymentsicon/image24.png";
import Ac6 from "../../../assets/images/paymentsicon/image25.png";
import Ac7 from "../../../assets/images/paymentsicon/image26.png";
import Ac8 from "../../../assets/images/paymentsicon/image27.png";
import Ac9 from "../../../assets/images/paymentsicon/image28.png";
import "swiper/css/navigation";
import {
  fetchConsultantListPrivately,
  fetchConsultantStatusList,
} from "../../../service/consultantService";
import NoDataFound from "../../elements/NoDataFound";
import SpinLoader from "../../elements/table/SpinLoader";
import { useSelector } from "react-redux";
import { consultantDetails } from "../../elements/language";
import { useSocket } from "../../socket/SocketProvider";
import ConsultantItem from "../../single/ConsultantItem";
import Button from "../../form/Button";
register();

const ConsultantCard = ({
  params,
  isSearch = false,
  setIsSearch = () => {},
  setParams = () => {},
  loaded = true,
  setLoaded = () => {},
  selectedMode,
  selectedGender,
  selectedStatus,
  limit,
  isShowBtn = false,
}) => {
  const swiperElRef = useRef(null);
  const [data, setData] = useState([]);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const token = localStorage.getItem("auth_token");
  const [liveConsultants, setLiveConsultants] = useState([]);
  const [consultantData, setConsultantData] = useState(null);
  const socket = useSocket();
  const [busyData, setbusyData] = useState([]);

  const busyConsultants = useSelector(
    (state) => state?.consultantStatusSlice?.data
  );
  const [consultantStatusList, setConsultantStatusList] = useState([]);

  useEffect(() => {
    setbusyData(busyConsultants);
  }, [busyConsultants]);

  useEffect(() => {
    if (isSearch && Object.keys(params).length > 0) {
      fetchConsultantListPrivately(
        token,
        {
          ...params,
          mode: selectedMode,
          gender: selectedGender,
          onlineStatus: selectedStatus,
        },
        setData,
        setLoaded
      ).then((res) => {
        setIsSearch(false);
        setParams({});
      });
    }
  }, [isSearch]);

  useEffect(() => {
    fetchConsultantListPrivately(token, { ...params }, setData, setLoaded);
  }, []);

  useEffect(() => {
    const swiperEl = document.querySelector(
      "swiper-container.consultantSlider"
    );
    const swiperParams = {
      loop: true,
      navigation: {
        nextEl: ".consultantSlider-next",
        prevEl: ".consultantSlider-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1.5,
          spaceBetween: 0,
          centeredSlides: true,
          loop: true,
        },
        320: {
          slidesPerView: 1.2,
          spaceBetween: 0,
          centeredSlides: false,
          loop: true,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: true,
          loop: false,
        },
      },
      on: {
        init() {},
      },
    };
    swiperEl && swiperParams && Object.assign(swiperEl, swiperParams);
    swiperEl && swiperEl.initialize();
  }, [data, consultantData]);

  const titleLable = {
    en: "Available<br/><span class='text-prontopsy-pink'>Consultants</span>",
    es: "Consultores<br/><span class='text-prontopsy-pink'>Disponibles</span>",
    it: "Consulenti<br/><span class='text-prontopsy-pink'>Disponibili</span>",
  };

  const [initialData, setInitialData] = useState([]);

  // Hook to handle socket events
  useSocket("userStatusResponse", (newObjects) => {
    if (newObjects && newObjects?.userId) {
      let obj = {
        _id: newObjects?.userId,
        onlineStatus: newObjects?.online ? "online" : "offline",
      };
      consultantStatusList &&
        // Array.isArray(consultantStatusList) &&
        // consultantStatusList.length > 0 &&
        setConsultantStatusList((prevData) => {
          // Check if the userId already exists in prevData
          const existingIndex = prevData?.findIndex(
            (item) => item?._id === newObjects?.userId
          );

          if (existingIndex !== -1) {
            // If found, update the existing object
            const updatedData = [...prevData];
            updatedData[existingIndex] = {
              ...updatedData[existingIndex],
              ...obj,
            };
            return updatedData;
          } else {
            // If not found, add the new object
            return [...prevData, obj];
          }
        });
    }
  });

  useSocket("consultantBusyResponse", (data) => {
    if (data && data.consultantId) {
      const obj = {
        _id: data.consultantId, // Ensure this matches what you're comparing with
        onlineStatus: data.isBusy === true ? "busy" : "online",
      };

      setConsultantStatusList((prevData) => {
        // Check if the consultantId (or _id) already exists in prevData
        const existingIndex = prevData.findIndex(
          (item) => item._id === data.consultantId
        );

        if (existingIndex !== -1) {
          // If found, update the existing object
          const updatedData = [...prevData];
          updatedData[existingIndex] = {
            ...updatedData[existingIndex],
            ...obj,
          };
          return updatedData;
        } else {
          // If not found, add the new object
          return [...prevData, obj];
        }
      });
    }
  });

  // useSocket("consultantBusyResponse", (data) => {
  //   if (data && data.consultantId) {
  //     const obj = {
  //       _id: data?.consultantId,
  //       onlineStatus: data?.isBusy === true ? "busy" : "online",
  //     };

  //     consultantStatusList &&
  //       // Array.isArray(consultantStatusList) &&
  //       // consultantStatusList.length > 0 &&
  //       setConsultantStatusList((prevData) => {
  //         // Check if the userId already exists in prevData
  //         const existingIndex = prevData?.findIndex(
  //           (item) => item.consultantId === data.consultantId
  //         );

  //         if (existingIndex !== -1) {
  //           // If found, update the existing object
  //           const updatedData = [...prevData];
  //           updatedData[existingIndex] = {
  //             ...updatedData[existingIndex],
  //             ...obj,
  //           };
  //           return updatedData;
  //         } else {
  //           // If not found, add the new object
  //           return [...prevData, obj];
  //         }
  //       });
  //   }
  // });

  useEffect(() => {
    setLiveConsultants((prevObjects) => {
      // Create a map for quick lookup
      const objectsMap = new Map(prevObjects.map((obj) => [obj.userId, obj]));

      // Update existing objects or add new ones
      initialData.forEach((newObj) => {
        objectsMap.set(newObj?.userId, newObj);
      });

      // Convert the map back to an array
      return Array.from(objectsMap.values());
    });
  }, [initialData, socket]);

  useEffect(() => {
    fetchConsultantStatusList().then((res) => {
      setConsultantStatusList(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    if (
      consultantStatusList &&
      Array.isArray(consultantStatusList) &&
      consultantStatusList.length > 0
    ) {
      const formattedData = consultantStatusList.map((elm) => ({
        userId: elm._id, // Directly accessing _id
        online: elm.onlineStatus === "online" ? true : false, // Simplified conditional
      }));
      const formattedBusyData = consultantStatusList.map((elm) => ({
        consultantId: elm?._id,
        isBusy: elm?.onlineStatus === "busy" ? true : false,
      }));
      setbusyData(formattedBusyData);
      formattedData && setInitialData(formattedData); // Replace the old state with the new data
    }
  }, [consultantStatusList]);

  useEffect(() => {
    let info =
      data?.length > 0 &&
      data.map((elm) => {
        // Find the consultant's online status
        const consultantStatus = liveConsultants.find(
          (dat) => dat?.userId === elm?._id
        );
        const onlineStatus = consultantStatus?.online ? "online" : "offline";

        // Find the consultant's busy status
        const consultantBusyStatus = busyData.find(
          (data) => data?.consultantId === elm?._id
        );
        // const busyStatus = consultantBusyStatus?.isBusy ? "busy" : onlineStatus;
        const busyStatus =
          consultantStatusList &&
          Array.isArray(consultantStatusList) &&
          consultantStatusList.find((data) => elm._id === data?._id);

        return {
          _id: elm?._id,
          name: elm?.firstName
            ? elm?.firstName + " " + elm?.lastName
            : "Consultant",
          image: elm?.image?.url,
          specialization:
            elm?.specialization?.inventionArea?.title?.[language] || "N/A", // member qualification
          intervention:
            elm?.member?.personalQualification?.title?.[language] || "N/A",
          bio: elm?.biography?.longBioDescription,
          language: {
            _id: elm?.languagesData?._id,
            name: elm?.languagesData?.name,
            code: elm?.languagesData?.code,
          },
          nationality: {
            name: elm?.personal?.nationality?.nationality || "Unknown",
            code: elm?.personal?.nationality?.code?.toLowerCase() || "unknown",
          },
          rate: {
            currency: elm?.currencyData?.currency,
            value:
              elm?.service &&
              Array.isArray(elm?.service) &&
              elm.service.length > 0 &&
              elm?.service[0]?.price,
          },
          // isOnline: busyStatus === "busy" ? "busy" : onlineStatus,
          isOnline: busyStatus?.onlineStatus,
          // single value, not array
          isFavorite: elm?.isFavourite || false,
          rating: {
            value: elm?.ratingData?.rating || 0,
            reviews: elm?.ratingData?.reviewCount || 0,
          },
          actions: {
            call: {
              isLink: false,
              click: () => {},
              label: consultantDetails?.callNow?.[language] || "Call Now",
            },
            booking: {
              isLink: false,
              click: () => {},
              label: consultantDetails?.bookConsult?.[language] || "Book Now",
            },
            email: {
              isLink: true,
              link: `/email-details/${elm?.chatDetail?._id}` || "#",
              label: consultantDetails?.emailMe?.[language] || "Email Me",
            },
          },
          serviceDataTableMap: elm?.serviceDataTableMap || [],
        };
      });

    const sortedInfo =
      Array.isArray(info) &&
      [...info].sort((a, b) => {
        if (a.isOnline === "online" && b.isOnline !== "online") return -1;
        if (a.isOnline !== "online" && b.isOnline === "online") return 1;
        return 0; // If both are "online" or both are not "online", keep the original order
      });
    const consultantDataa = info && {
      title: titleLable?.[language],
      consultant: sortedInfo,
    };
    consultantDataa && setConsultantData(consultantDataa);
  }, [
    language,
    liveConsultants,
    socket,
    data,
    busyData,
    consultantStatusList,
    initialData,
  ]);

  const dataArray = [
    { src: Ac, alt: "Image 1" },
    { src: Ac1, alt: "Image 2" },
    { src: Ac2, alt: "Image 3" },
    { src: Ac4, alt: "Image 3" },
    { src: Ac5, alt: "Image 3" },
    { src: Ac6, alt: "Image 3" },
    { src: Ac7, alt: "Image 3" },
    { src: Ac8, alt: "Image 3" },
    { src: Ac9, alt: "Image 3" },
    // Add more items as needed
  ];

  return (
    <>
      {loaded ? (
        <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 2xl:py-24 flex items-center bg-neutral-100">
          {data && data.length > 0 && consultantData?.consultant?.length > 0 ? (
            <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
              <div className="w-full text-center">
                {consultantData?.title && (
                  <h2
                    className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                    dangerouslySetInnerHTML={{ __html: consultantData?.title }}
                  ></h2>
                )}
              </div>
              <div className="">
                {consultantData &&
                  consultantData?.consultant &&
                  Array.isArray(consultantData?.consultant) &&
                  consultantData?.consultant?.length > 0 && (
                    <div className="grid md:grid-cols-2 grid-cols-1 2xl:gap-10 xl:gap-8 gap-6 mt-20">
                      {consultantData?.consultant
                        ?.slice(0, limit)
                        .map((item) => (
                          <div
                            key={item._id}
                            // style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <ConsultantItem
                              data={item}
                              mainClasses={"!p-0 !h-full"}
                              divClasses={"!py-5 !gap-6 !rounded-xl"}
                              rateClass={"!mt-5"}
                              subheadingClass={"!pt-0"}
                              isShowDescription={false}
                              callUsBtn={true}
                            />
                          </div>
                        ))}
                    </div>
                  )}
                <div className="flex justify-center w-full mt-16">
                  {isShowBtn && (
                    <Button
                      buttonClasses={"!bg-prontopsy-blue !px-8 !py-2"}
                      buttonLabel={"View All"}
                      buttonLabelClasses={"!text-base"}
                      buttonHasLink={true}
                      buttonLink={"/find-psychologist"}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="m-auto">
                <NoDataFound />
              </div>
            </>
          )}
        </section>
      ) : (
        <SpinLoader />
      )}
    </>
  );
};

export default ConsultantCard;
