import React, { useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import ImageBanner from "../assets/images/signinbanner.webp";
import PageBanner from "../components/sections/PageBanner";
import Login from "../components/sections/signin/Login";

const Signin = () => {
  setTitle("Prontopsy | Signin");
  const bannerData = {
    image: ImageBanner,
    title: "Login",
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({
        top: 550,
        behavior: "smooth", // Smooth scrolling behavior
      });
    }, 100); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <>
      <PageBanner bannerData={bannerData} />
      <Login />
    </>
  );
};

export default Signin;
