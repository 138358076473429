import React from "react";
import Image from "../elements/Image";

const TestimonialItem = ({ data }) => {
  return (
    <>
      <div className="relative w-full flex-grow flex py-10 xl:px-60 md:px-20 px-8">
        <div className="sm:flex block items-center shadow-lg w-full border border-neutral-100 rounded-tl-9xl">
          {data?.image && (
            <div className="w-16 md:w-20 lg:w-24 xl:w-28 aspect-square overflow-hidden rounded-full flex-shrink-0 -ml-2 sm:-ml-4 md:-ml-8 lg:-ml-10 xl:-ml-14 shadow-lg shadow-prontopsy-blue/20">
              <Image
                src={data?.image}
                alt={""}
                effect={"blur"}
                className={"w-full h-full object-cover"}
              />
            </div>
          )}
          <div className="sm:py-10 py-5 md:px-10 px-5 flex-shrink w-full">
            {data.name && (
              <>
                <h2 className="font-RobotoSlab font-bold text-lg md:text-xl lg:text-2xl text-prontopsy-blue !leading-[1.1]">
                  {data?.title}
                </h2>
                <h4
                  className="font-UnisonPro font-bold italic text-base md:text-base lg:text-lg text-prontopsy-pink !leading-[1.1] pt-3"
                  dangerouslySetInnerHTML={{ __html: data.name }}
                ></h4>
              </>
            )}
            {data.description && (
              <div
                className="text-sm md:text-sm lg:text-base text-neutral-700 mt-4 space-y-3"
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialItem;
