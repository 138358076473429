import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Link,
} from "@react-pdf/renderer";
import Button from "../../../../form/Button";
import Logo from "../../../../../assets/images/logo.png";
import { documentLang } from "../../../language";

const DownloadButton = ({ details, language }) => {
  return (
    <>
      <PDFDownloadLink
        document={<MyDocument details={details} language={language} />}
        fileName="appointment_invoice.pdf"
        // style={{ textDecoration: "none" }}
      >
        {({ loading }) => (
          <Button
            buttonFunction={() => {}}
            buttonLabel={loading ? "Loading document..." : "Download Invoice"}
            buttonIcon={"fa-solid fa-download"}
            buttonIconPosition={"left"}
          />
        )}
      </PDFDownloadLink>
    </>
  );
};

export default DownloadButton;

// Create styles
const styles = StyleSheet.create({
  logo: {
    width: 150,
    height: 80,
    marginRight: 10,
    marginBottom: 15,
  },
  page: {
    padding: 20,
    fontSize: 10,
  },
  header: {
    textAlign: "center",
    marginVertical: 10,
    borderBottom: "1px solid #bdbdbd",
  },
  section: {
    marginBottom: 8,
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bdbdbd",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bdbdbd",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  bold: {
    fontWeight: "bold",
  },
  fullWidthCol: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bdbdbd",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
    padding: 10,
  },
});

// Create Document Component
const MyDocument = ({ details, language }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.logo} src={Logo} />
      <View style={styles.header}>
        <Text
          style={{
            color: "red",
            marginBottom: 5,
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          {`${documentLang.appointmentId[language]}: #${details?.appointmentId}`}
        </Text>
        <Text style={{ marginBottom: 10 }}>
          {documentLang.printKeep[language]}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.bold}>
          {documentLang.dateOfAppointment[language]}: {details?.appointmentDate}
        </Text>
        <Text style={styles.bold}>
          {documentLang.prontopsyId[language]}: #{details?.appointmentId}
        </Text>
        <Text style={styles.bold}>
          {documentLang.totalCapturedAmount[language]}: (
          {details?.currencySymbol}) {details?.capturedAmount}
        </Text>
      </View>

      <View style={[styles.table, styles.section]}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.bold]}>
              {documentLang.clientDetails[language]}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.bold]}>
              {documentLang.consultantDetails[language]}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text
              style={styles.tableCell}
            >{`${documentLang.clientName[language]}: ${details?.clientData?.name}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.address[language]}: ${details?.clientData?.address}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.city[language]}: ${details?.clientData?.city}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.zipCode[language]}: ${details?.clientData?.zipcode}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.country[language]}: ${details?.clientData?.country}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.clientCode[language]}: ${details?.clientData?.clientCode}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.email[language]}: ${details?.clientData?.email}`}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text
              style={styles.tableCell}
            >{`${documentLang.consultantName[language]}: ${details?.consultantData?.name}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.address[language]}: ${details?.consultantData?.address}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.city[language]}: ${details?.consultantData?.city}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.zipCode[language]}: ${details?.consultantData?.zipcode}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.country[language]}: ${details?.consultantData?.country}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.taxCode[language]}: ${details?.consultantData?.taxCode}`}</Text>
            <Text
              style={styles.tableCell}
            >{`${documentLang.email[language]}: ${details?.consultantData?.email}`}</Text>
          </View>
        </View>
      </View>

      <View style={[styles.table, styles.section]}>
        <View style={styles.tableRow}>
          <View style={styles.fullWidthCol}>
            <Text style={[styles.tableCell, styles.bold]}>
              {documentLang.paymentInformation[language]}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {documentLang.serviceProvided[language]}
            </Text>
            <Text style={styles.tableCell}>{documentLang.mode[language]}</Text>
            <Text style={styles.tableCell}>
              {documentLang.dateTime[language]}
            </Text>
            <Text style={styles.tableCell}>
              {documentLang.totalDuration[language]}
            </Text>
            <Text style={styles.tableCell}>
              {documentLang.consultantCallRate[language]}
            </Text>
            <Text style={styles.tableCell}>
              {documentLang.authorizedAmount[language]}
            </Text>
            <Text style={styles.tableCell}>
              {documentLang.capturedAmount[language]}
            </Text>
            <Text style={styles.tableCell}>
              {documentLang.paymentGateway[language]}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {details?.paymentInfo?.service}
            </Text>
            <Text style={styles.tableCell}>{details?.paymentInfo?.mode}</Text>
            <Text style={styles.tableCell}>
              {details?.paymentInfo?.date_time}
            </Text>
            <Text style={styles.tableCell}>
              {details?.paymentInfo?.duration}
            </Text>
            <Text style={styles.tableCell}>
              {details?.paymentInfo?.pricePerMin}
            </Text>
            <Text style={styles.tableCell}>
              {details?.paymentInfo?.authorizeAmount}
            </Text>
            <Text style={styles.tableCell}>
              {details?.paymentInfo?.capturedAmount}
            </Text>
            <Text style={styles.tableCell}>
              {details?.paymentInfo?.paymentGateway}
            </Text>
          </View>
        </View>
      </View>

      <View style={[styles.section, { display: "flex" }]}>
        <Text style={styles.bold}>{documentLang.disclaimer[language]}</Text>
        <Text>{documentLang.disclaimerText[language]}</Text>
        <Text style={{ marginTop: 10 }}>
          <Link
            src="https://dev.front.prontopsy.com/terms-of-services"
            style={{ textDecoration: "underline", color: "blue" }}
          >
            {documentLang.termsAndConditions[language]}
          </Link>
          {" | "}
          <Link
            src="https://dev.front.prontopsy.com/terms-of-services"
            style={{ textDecoration: "underline", color: "blue" }}
          >
            {documentLang.privacyPolicy[language]}
          </Link>
          {" | "}
          {documentLang.footer[language]}
        </Text>
      </View>
    </Page>
  </Document>
);
