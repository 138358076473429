import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/opacity.css";

const Image = ({ src, width, height, alt, effect, className }) => {
  return (
    <>
      <LazyLoadImage
        src={src}
        crossorigin="anonymous"
        width={width}
        height={height}
        alt={alt}
        effect={effect}
        className={"w-full h-full " + className}
      />
    </>
  );
};

export default Image;
