import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { apiRequest } from "../util/async/apiUtils";
import { useSocket } from "../components/socket/SocketProvider";
import { getProfileDetails } from "../service/authService";

const BlankLayout = () => {
  const navigate = useNavigate();
  const appointmentData = JSON.parse(localStorage.getItem("appointmentData"));
  const profileLocal = JSON.parse(localStorage.getItem("userDetails"));
  const [profile, setProfile] = useState(profileLocal);
  const [isLoader, setIsLoader] = useState(false);
  const [auth, setAuth] = useState(false);
  const socket = useSocket();
  const token = localStorage.getItem("auth_token");
  const [data, setData] = useState(appointmentData);

  // useEffect(() => {
  //   token &&
  //     getProfileDetails(token).then((response) => {
  //       if (response?.data?.status === 200) {
  //         if (response?.data?.docs?._id) {
  //           localStorage.setItem(
  //             "userDetails",
  //             JSON.stringify(response?.data?.docs)
  //           );
  //         }
  //         setProfile(response?.data?.docs);
  //         if (
  //           response?.data?.docs?.appointmentData?._id &&
  //           response?.data?.docs?.appointmentData?.status === "inProgress"
  //         ) {
  //           const appointment = {
  //             appointmentId: response?.data?.docs?.appointmentData?._id,
  //             appointmentStartTime:
  //               response?.data?.docs?.appointmentData?.details
  //                 ?.appointmentStartTime,
  //             callerData: {
  //               consultant: {
  //                 id: response?.data?.docs?.appointmentData?.consultantId,
  //                 image:
  //                   response?.data?.docs?.appointmentData?.consultantDetails
  //                     ?.image,
  //                 name:
  //                   response?.data?.docs?.appointmentData?.consultantDetails
  //                     ?.firstName +
  //                   " " +
  //                   response?.data?.docs?.appointmentData?.consultantDetails
  //                     ?.lastName,
  //               },
  //               user: {
  //                 id: response?.data?.docs?.appointmentData?.clientId,
  //                 image:
  //                   response?.data?.docs?.appointmentData?.clientDetails?.image,
  //                 name:
  //                   response?.data?.docs?.appointmentData?.clientDetails
  //                     ?.firstName +
  //                   " " +
  //                   response?.data?.docs?.appointmentData?.clientDetails
  //                     ?.lastName,
  //               },
  //             },
  //             consultantId: response?.data?.docs?.appointmentData?.consultantId,
  //             mode: response?.data?.docs?.appointmentData?.service?.mode?.slug,
  //             paymentMode: response?.data?.docs?.appointmentData?.paymentMode,
  //             sessionId: response?.data?.docs?.appointmentData?.sessionId,
  //             token: response?.data?.docs?.appointmentData?.sessionToken,
  //           };

  //           appointment.appointmentId && setData(appointment);
  //           appointment.appointmentId &&
  //             localStorage.setItem(
  //               "appointmentData",
  //               JSON.stringify(appointment)
  //             );
  //         } else {
  //           socket.emit("consultantBusy", {
  //             consultantId: appointmentData?.callerData?.consultant?.id || "",
  //             userId: appointmentData?.callerData?.user?.id,
  //             isBusy: false,
  //           });
  //           localStorage.removeItem("appointmentData");
  //           localStorage.removeItem("callPageLeaveFlag");
  //         }
  //       }
  //     });
  // }, [token, socket]);

  useEffect(() => {
    token &&
      getProfileDetails(token).then((response) => {
        if (response?.data?.status === 200) {
          if (response?.data?.docs?._id) {
            localStorage.setItem(
              "userDetails",
              JSON.stringify(response?.data?.docs)
            );
          }
          setProfile(response?.data?.docs);
          if (
            response?.data?.docs?.appointmentData?._id &&
            response?.data?.docs?.appointmentData?.status === "inProgress"
          ) {
            const appointment = {
              appointmentId: response?.data?.docs?.appointmentData?._id,
              appointmentStartTime:
                response?.data?.docs?.appointmentData?.details
                  ?.appointmentStartTime,
              callerData: {
                consultant: {
                  id: response?.data?.docs?.appointmentData?.consultantId,
                  image:
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.image,
                  name:
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.firstName +
                    " " +
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.lastName,
                },
                user: {
                  id: response?.data?.docs?.appointmentData?.clientId,
                  image:
                    response?.data?.docs?.appointmentData?.clientDetails?.image,
                  name:
                    response?.data?.docs?.appointmentData?.clientDetails
                      ?.firstName +
                    " " +
                    response?.data?.docs?.appointmentData?.clientDetails
                      ?.lastName,
                },
              },
              consultantId: response?.data?.docs?.appointmentData?.consultantId,
              mode: response?.data?.docs?.appointmentData?.service?.mode?.slug,
              paymentMode: response?.data?.docs?.appointmentData?.paymentMode,
              sessionId: response?.data?.docs?.appointmentData?.sessionId,
              token: response?.data?.docs?.appointmentData?.sessionToken,
            };

            appointment.appointmentId && setData(appointment);
            appointment.appointmentId &&
              localStorage.setItem(
                "appointmentData",
                JSON.stringify(appointment)
              );
          } else {
            socket.emit("consultantBusy", {
              consultantId: appointmentData?.callerData?.consultant?.id || "",
              userId: appointmentData?.callerData?.user?.id,
              isBusy: false,
            });
            localStorage.removeItem("appointmentData");
            localStorage.removeItem("callPageLeaveFlag");
          }
        }
      });

    const checkData = async () => {
      setIsLoader(true);
      try {
        const res = await apiRequest(
          "post",
          "appointment/authenticate-appointment",
          {
            body: { appointmentId: data?.appointmentId },
          }
        );
        console.log(res, "rtrtrtr");
        if (res?.status === 401) {
          localStorage.removeItem("appointmentData");
          navigate("/");
          setAuth(false);
        } else if (res?.status === 404) {
          localStorage.removeItem("appointmentData");
          navigate("/");
          setAuth(false);
        } else {
          if (res?.data?.data?.appointmentStatus === "close") {
            localStorage.removeItem("appointmentData");
            navigate("/");
            setAuth(false);
          } else {
            setIsLoader(false);
            setAuth(true);
          }
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        // Optionally handle different error statuses
        if (error?.response?.status === 401) {
          localStorage.removeItem("appointmentData");
          navigate("/");
          setAuth(false);
        } else {
          // Handle other types of errors (e.g., network issues)
          console.error("Unexpected error:", error);
          localStorage.removeItem("appointmentData");
          navigate("/");
          setAuth(false);
        }
      }
    };

    const timer = setTimeout(() => {
      if (!data) {
        setIsLoader(false);
        navigate("/");
        localStorage.removeItem("appointmentData");
      } else {
        checkData();
      }
    }, 2000);

    // Cleanup the timeout if the component unmounts

    setIsLoader(false);
    return () => clearTimeout(timer);
    // if (!data && profile?.roleCode === "user") {
    //   navigate("/"); // Redirect if there's no appointment data
    //   return;
    // }
  }, []);

  //-------------------Alraedy in call----------------------------
  // const handleVisibilityChange = useCallback(() => {
  //   if (socket && data) {
  //     socket.emit("userAlreadyInCall", {
  //       userId: profile?._id,
  //       appointmentId: data?.appointmentId,
  //       disconnect: false,
  //       roleCode: profile?.roleCode,
  //     });
  //   }
  // }, [socket, data, profile]);

  // useEffect(() => {
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, [handleVisibilityChange]);

  return <>{auth && <Outlet />}</>;
};

export default BlankLayout;
