import axios from "axios";
import BASE_URL from "../config/host";

export const fetchModeList = async (
  setList,
  setLoaded = () => {},
  limit = 200
) => {
  setLoaded(false);
  try {
    let data = await axios.get(`${BASE_URL}/category/list`, {
      params: { limit, type: "mode" },
    });

    if (data.status === 200) {
      setList(data?.data?.docs?.data);
      setLoaded(true);
    }

    return data;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
