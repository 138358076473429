import React, { useState } from "react";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";

function CalendarMonth({ value, onChange = () => {} }) {
  // const [value, onChange] = useState(new Date());
  const minDate = new Date();

  return (
    <div className="calendar-container !mt-10 !mb-6">
      <Calendar onChange={onChange} value={value} minDate={minDate} />
    </div>
  );
}

export default CalendarMonth;
