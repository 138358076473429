import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: [],
};

export const initialFaqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setInitialFaqData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setInitialFaqData } = initialFaqSlice.actions;
export default initialFaqSlice.reducer;
