import axios from "axios";
import BASE_URL from "../config/host";

export const fetchHowItWorksList = async (setList, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let data = await axios.get(`${BASE_URL}/how-it-work/list`);

    if (data.status === 200) {
      setList(data?.data?.docs?.data);
      setLoaded(true);
    }

    return data;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
