import React, { useState } from "react";
import InputGroup from "../../form/Input";
import Image from "../../elements/Image";
import ImageBanner from "../../../assets/images/ourteam-banner.webp";
import Textarea from "../../form/Textarea";
import Button from "../../form/Button";
import { contactUsRequest } from "../../../service/contactService";
import { toast } from "react-toastify";
import { submitBtn } from "../../elements/language";
const OurTeam = ({ bannerTitle, language }) => {
  const [formData, setformData] = useState({ title: "Hello" });
  const [loaded, setLoaded] = useState(true);
  const [clicked, setClicked] = useState(false);

  function isValidEmail(email) {
    // Define a regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Test the input string against the regular expression
    return emailRegex.test(email);
  }

  const handleInput = (e) => {
    setformData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setClicked(false);
  };
  let validate = isValidEmail(formData.email);

  const handleSubmit = () => {
    setClicked(true);
    if (
      formData.name &&
      formData.phone &&
      formData.message &&
      validate == true
    ) {
      contactUsRequest(formData, setLoaded).then((res) => {
        if (res.status === 201) {
          toast.success(res.message);
          setformData({ title: "Hello" });
          setClicked(false);
        }
      });
    }
  };
  const OurTeamData = {
    image: ImageBanner,
    title: bannerTitle,
  };

  const namePlaceholder = {
    en: "Name",
    es: "Nombre",
    it: "Nome",
  };
  const emailPlaceholder = {
    en: "Email",
    es: "Correo electrónico",
    it: "E-mail",
  };
  const phonePlaceholder = {
    en: "Phone",
    es: "Teléfono",
    it: "Telefono",
  };
  const messagePlaceholder = {
    en: "Feedback Message",
    es: "Mensaje de comentarios",
    it: "Messaggio di feedback",
  };

  const nameErrorText = {
    en: "Name is required!",
    es: "¡Se requiere el nombre!",
    it: "Il nome è obbligatorio!",
  };
  const emailErrorText = {
    en: "Enter a valid email id!",
    es: "¡Ingrese una identificación de correo electrónico válida!",
    it: "Inserisci un ID e-mail valido!",
  };
  const phoneErrorText = {
    en: "Phone is required!",
    es: "¡Se requiere teléfono!",
    it: "È necessario il telefono!",
  };
  const messageErrorText = {
    en: "Message is required!",
    es: "¡Se requiere mensaje!",
    it: "Il messaggio è obbligatorio!",
  };
  return (
    <div className="w-full relative bg-prontopsy-blue pt-20 pb-36 md:pb-72 lg:pb-96  xl:pb-[480px] 2xl:pb-[540px] 3xl:pb-[640px] z-[0]">
      {OurTeamData?.image && (
        <div className="absolute bottom-0 lg:top-0 left-0 h-full w-full z-[1] overflow-hidden flex">
          <Image
            src={OurTeamData?.image}
            alt=""
            effect="blur"
            className="object-contain object-bottom w-full h-full"
          />
        </div>
      )}

      <div className="relative z-[2] w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="w-full mx-auto max-w-5xl text-center px-0 md:px-20 space-y-5">
          {OurTeamData?.title && (
            <div className=" flex justify-center">
              <h2
                className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-white font-UnisonPro italic font-bold !leading-[1.1] max-w-lg"
                dangerouslySetInnerHTML={{
                  __html: OurTeamData?.title?.[language],
                }}
              ></h2>
            </div>
          )}
          <div className="grid grid-cols-12 gap-3 pt-2">
            <div className="md:col-span-4 col-span-12">
              <div className="reltive">
                <InputGroup
                  onChange={handleInput}
                  inputName={"name"}
                  inputPlaceholder={namePlaceholder?.[language]}
                  inputClasses={
                    "!bg-prontopsy-dodgerBlue !border-none !h-14 placeholder:text-white text-white"
                  }
                  errorType={!formData.name && clicked == true && "warning"}
                  errorText={nameErrorText?.[language]}
                  inputValue={formData.name}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="reltive">
                <InputGroup
                  onChange={handleInput}
                  inputName={"email"}
                  inputType={"email"}
                  inputPlaceholder={emailPlaceholder?.[language]}
                  inputClasses={
                    "!bg-prontopsy-dodgerBlue !border-none !h-14 placeholder:text-white text-white"
                  }
                  errorType={
                    clicked == true &&
                    (!formData.email
                      ? "warning"
                      : isValidEmail(formData.email)
                      ? ""
                      : "danger")
                  }
                  errorText={
                    formData.email && isValidEmail(formData.email)
                      ? ""
                      : emailErrorText?.[language]
                  }
                  inputValue={formData.email}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="reltive">
                <InputGroup
                  onChange={handleInput}
                  inputName={"phone"}
                  inputType={"number"}
                  inputPlaceholder={phonePlaceholder?.[language]}
                  inputClasses={
                    "!bg-prontopsy-dodgerBlue !border-none !h-14 placeholder:text-white text-white"
                  }
                  errorType={!formData.phone && clicked == true && "warning"}
                  errorText={phoneErrorText?.[language]}
                  inputValue={formData.phone}
                />
              </div>
            </div>
            <div className="col-span-12">
              <div className="reltive">
                <Textarea
                  onChange={(e) => handleInput(e)}
                  inputName={"message"}
                  inputType={"text"}
                  inputPlaceholder={messagePlaceholder?.[language]}
                  inputClasses={
                    "!h-44 !bg-prontopsy-dodgerBlue !border-none !h-14 placeholder:text-white text-white"
                  }
                  errorType={!formData.message && clicked == true && "warning"}
                  errorText={messageErrorText?.[language]}
                  inputValue={formData.message}
                />
              </div>
            </div>
            <div className="col-span-12">
              <div className="reltive flex justify-center mt-4">
                <Button
                  buttonLabel={submitBtn?.[language]}
                  buttonLabelClasses={"capitalize"}
                  buttonClasses={
                    "!px-12 !py-6 !bg-prontopsy-yellow !rounded-none !text-black"
                  }
                  buttonFunction={handleSubmit}
                  isDisable={!loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
