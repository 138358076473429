import React, { useEffect, useState } from "react";
import Search from "../../form/Search";
import Select from "../../form/Select";
import Button from "../../form/Button";
import { findPsychologistLang, submitBtn } from "../../elements/language";
import InputGroup from "../../form/Input";
import MultiSelect from "../../form/MultiSelect";
import { fetchConsultantList } from "../../../service/consultantService";
import { fetchCountryList } from "../../../service/profileService";
import Consultants from "../Consultants";
import { useSelector } from "react-redux";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { fetchLanguageList } from "../../../service/languageService";
import { fetchInterventionList } from "../../../service/interventionService";
import { fetchModeList } from "../../../service/modeService";
import { fetchTheraputicList } from "../../../service/theraputicService";
import { fetchMentalHealthList } from "../../../service/mentalHealthService";
import { fetchPsychosocialProblemList } from "../../../service/psychologicalProblemService";
import { fetchOtherTopicList } from "../../../service/otherTopicsService";
import Checkbox from "../../form/Checkbox";
import RadioButton from "../../form/RadioButton";
import Radio from "../../form/Radio";
import ConsultantCard from "../consultantDetails/ConsultantCard";

const PsychologistExpertSearch = ({ title, language }) => {
  const [params, setParams] = useState({ limit: 500 });
  const [searchInput, setSearchInput] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [lang, setLang] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [interventionList, setInterventionList] = useState([]);
  const [intervention, setIntervention] = useState(null);
  const [theraputicList, setTheraputicList] = useState([]);
  const [theraputic, setTheraputic] = useState(null);
  const [mentalHealthList, setMentalHealthList] = useState([]);
  const [mentalHealth, setMentalHealth] = useState(null);
  const [psychologicalList, setPsychologicalList] = useState([]);
  const [psychological, setPsychological] = useState(null);
  const [otherTopicList, setotherTopicList] = useState([]);
  const [otherTopic, setOtherTopic] = useState(null);
  const [currency, setCurrency] = useState(null);

  const [gender, setGender] = useState([]);
  const [modeList, setModeList] = useState([]);
  const [mode, setMode] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [selectedMode, setSelectedMode] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  //Fetching apis
  useEffect(() => {
    fetchCountryList(setCountryList);
    fetchLanguageList(setLanguageList);
    fetchInterventionList({ limit: 500 }, setInterventionList);
    fetchModeList(setModeList);
    fetchTheraputicList(setTheraputicList);
    fetchMentalHealthList(setMentalHealthList);
    fetchPsychosocialProblemList(setPsychologicalList);
    fetchOtherTopicList(setotherTopicList);
  }, []);

  useEffect(() => {
    let genderArray = gender && gender.map((elm) => elm?.value);
    let modeArray = mode && mode.map((elm) => elm?.value);

    setParams((pre) => ({
      ...pre,
      gender: genderArray,
      mode: modeArray,
    }));
  }, [gender, mode]);

  const nationalityData =
    countryList.length > 0 &&
    countryList.map((elm) => ({
      value: elm._id,
      name: elm?.name,
    }));
  //Language
  const languageData =
    languageList.length > 0 &&
    languageList.map((elm) => ({
      value: elm?._id,
      name: elm?.name,
    }));
  //Intervention area
  const areaOfIntervenTionData =
    interventionList.length > 0 &&
    interventionList.map((elm) => ({
      value: elm?._id,
      name: elm?.title?.[language],
    }));

  //Gender
  const genderData = [
    { value: "male", name: "Male" },
    { value: "female", name: "Female" },
    // { value: "other", name: "Other" },
  ];

  //Staus
  const statusData = [
    { value: "online", name: "Online" },
    { value: "offline", name: "Offline" },
    // { value: "busy", name: "Busy" },
  ];

  //Mode data
  const modeData =
    modeList.length > 0 &&
    modeList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));
  //Theraputic Data
  const theraputicData =
    theraputicList.length > 0 &&
    theraputicList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));
  //Mental and neurological data
  const mentalHealthData =
    mentalHealthList.length > 0 &&
    mentalHealthList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));
  // Psychological
  const psychologicalData =
    psychologicalList.length > 0 &&
    psychologicalList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));
  //Other Topics
  const otherTopicsData =
    otherTopicList.length > 0 &&
    otherTopicList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));
  // Currency data
  const currencyData =
    countryList.length > 0 &&
    countryList.map((elm) => ({
      value: elm?.currency,
      name: elm?.currency,
    }));

  const PsychologistExpertData = {
    title: title?.[language],
    label: "submit",
  };

  const handleParamsData = (e) => {
    setParams((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };

  const submitParams = () => {
    setIsSearch(true);
  };

  const handleModeChange = (data) => {
    setSelectedMode((prevSelected) => {
      if (prevSelected?.includes(data)) {
        return prevSelected?.filter((id) => id !== data);
      } else {
        return [...prevSelected, data];
      }
    });
  };

  const handleGenderChange = (data) => {
    setSelectedGender((prevSelected) => {
      if (prevSelected?.includes(data)) {
        return prevSelected?.filter((id) => id !== data);
      } else {
        return [...prevSelected, data];
      }
    });
  };
  const handleStatusChange = (data) => {
    setSelectedStatus((prevSelected) => {
      if (prevSelected?.includes(data)) {
        return prevSelected?.filter((id) => id !== data);
      } else {
        return [...prevSelected, data];
      }
    });
  };

  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 flex items-center z-[1]">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="w-full mx-auto max-w-6xl text-center">
            {PsychologistExpertData?.title && (
              <h2
                className="mb-10 text-2xl lg:text-3xl xl:text-4xl 2xl:text-[38px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{
                  __html: PsychologistExpertData?.title,
                }}
              ></h2>
            )}
            <div className="grid grid-cols-12 gap-5">
              {/* Search Psychologist by Name*/}
              <div className="col-span-12">
                <div className="relative">
                  <InputGroup
                    // label={`*${findPsychologistLang?.search?.[languag]}`}
                    iconClasses={"hidden"}
                    inputPlaceholder={
                      "Search Psychologist name, word or phrase.."
                    }
                    divClasses={
                      "h-12 bg-transparent !border-black rounded-none"
                    }
                    labelClasses={
                      "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab text-left"
                    }
                    inputClasses={
                      "!bg-transparent !h-16 !border !border-slate-600 !rounded-none !text-gray-800"
                    }
                    inputName={"keyword"}
                    onChange={(e) => handleParamsData(e)}
                    inputValue={params?.keyword}
                  />
                </div>
              </div>
              {/* Nationality */}
              <div className="col-span-12 md:col-span-4">
                <div className="relative flex">
                  <Select
                    // label={`*${findPsychologistLang?.nationality?.[languag]}`}
                    placeholder={`${findPsychologistLang?.select?.[language]} ${findPsychologistLang?.nationality?.[language]}`}
                    labelClasses={
                      "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab text-left !text-slate-600"
                    }
                    dropdownButtonClass={"!h-16 !border !border-slate-600"}
                    isSearch={true}
                    dropdownData={nationalityData}
                    dropdownClass={"w-full"}
                    selectedValue={nationality || ""}
                    handleSelect={(val) => {
                      setNationality(val);
                      handleParamsData({
                        target: {
                          value: val?.value,
                          name: "nationality",
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {/* Language */}
              <div className="col-span-12 md:col-span-4">
                <div className="relative flex">
                  <Select
                    // label={`*${findPsychologistLang?.language?.[languag]}`}
                    placeholder={`${findPsychologistLang?.select?.[language]} ${findPsychologistLang?.language?.[language]}`}
                    labelClasses={
                      "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab text-left"
                    }
                    // labelClasses={
                    //   "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                    // }
                    dropdownButtonClass={"!h-16 !border !border-slate-600"}
                    // dropdownButtonClass={
                    //   "!h-10 md:!h-12 lg:!h-14 xl:!h-16 !bg-transparent !border-black !rounded-none"
                    // }
                    selectedValue={lang || ""}
                    isSearch={true}
                    dropdownData={languageData}
                    dropdownClass={"w-full"}
                    handleSelect={(val) => {
                      setLang(val);
                      handleParamsData({
                        target: {
                          value: val?.value,
                          name: "language",
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {/* Area of Intervention */}
              <div className="col-span-12 md:col-span-4">
                <div className="relative flex">
                  <Select
                    // label={`*${findPsychologistLang?.areaOfIntervention?.[languag]}`}
                    placeholder={`${findPsychologistLang?.select?.[language]} ${findPsychologistLang?.areaOfIntervention?.[language]}`}
                    labelClasses={
                      "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab text-left"
                    }
                    // placeholder={"Select Area of Intervention"}
                    // dropdownButtonClass={
                    //   "!h-10 md:!h-12 lg:!h-14 xl:!h-16 !bg-transparent !border-black !rounded-none"
                    // }
                    isSearch={true}
                    dropdownData={areaOfIntervenTionData}
                    selectedValue={intervention}
                    dropdownClass={"w-full"}
                    dropdownButtonClass={"!h-16 !border !border-slate-600"}
                    handleSelect={(val) => {
                      setIntervention(val);
                      handleParamsData({
                        target: {
                          value: val?.value,
                          name: "inventionArea",
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-wrap col-span-12 lg:gap-16 md:gap-8 gap-4 my-5">
                <div className="text-start">
                  <div className="text-lg">Gender</div>
                  <div className="flex gap-4 mt-4">
                    {genderData &&
                      Array.isArray(genderData) &&
                      genderData?.length > 0 &&
                      genderData?.map((item, index) => (
                        <div key={index}>
                          <Checkbox
                            checkboxLabel={item?.name || ""}
                            checkboxLabelClass={"!text-slate-900 !capitalize"}
                            checkboxInputClass={"!border !border-slate-600"}
                            divClass={" !border-slate-800"}
                            value={selectedGender.includes(item?.value)}
                            onChange={(e) => handleGenderChange(item?.value)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <div className="text-start">
                  <div className="text-lg">Online Status</div>
                  <div className="flex gap-4 mt-4">
                    {statusData &&
                      Array.isArray(statusData) &&
                      statusData?.length > 0 &&
                      statusData?.map((item, index) => (
                        <div key={index}>
                          <Checkbox
                            checkboxLabel={item?.name || ""}
                            checkboxLabelClass={"!text-slate-900 !capitalize"}
                            checkboxInputClass={"!border !border-slate-600"}
                            divClass={" !border-slate-800"}
                            value={selectedStatus.includes(item?.value)}
                            onChange={(e) => handleStatusChange(item?.value)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <div className="text-start">
                  <div className="text-lg">Mode</div>
                  <div className="flex gap-4 mt-4 ">
                    {modeData &&
                      Array.isArray(modeData) &&
                      modeData?.length > 0 &&
                      modeData?.map((item, index) => (
                        <div key={index}>
                          <Checkbox
                            checkboxLabel={item?.name || ""}
                            checkboxLabelClass={"!text-slate-900 !capitalize"}
                            checkboxInputClass={"!border !border-slate-600"}
                            divClass={" !border-slate-800"}
                            value={selectedMode.includes(item?.value)}
                            onChange={(e) => handleModeChange(item?.value)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {/* Theraputic Approch */}
              <div className="col-span-12 md:col-span-4">
                <div className="relative flex">
                  <Select
                    placeholder={`${findPsychologistLang?.select?.[language]} ${findPsychologistLang?.therapeuticApproach?.[language]}`}
                    labelClasses={
                      "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab text-left"
                    }
                    dropdownButtonClass={"!h-16 !border !border-slate-600"}
                    isSearch={true}
                    dropdownData={theraputicData}
                    selectedValue={theraputic}
                    dropdownClass={"w-full"}
                    handleSelect={(val) => {
                      setTheraputic(val);
                      handleParamsData({
                        target: {
                          value: val?.value,
                          name: "theraputic",
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {/* Mental And Neurological Disorders */}
              <div className="col-span-12 md:col-span-4">
                <div className="relative flex">
                  <Select
                    placeholder={`${findPsychologistLang?.select?.[language]} ${findPsychologistLang?.mental?.[language]}`}
                    labelClasses={
                      "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab text-left"
                    }
                    dropdownButtonClass={"!h-16 !border !border-slate-600"}
                    isSearch={true}
                    dropdownData={mentalHealthData}
                    selectedValue={mentalHealth}
                    dropdownClass={"w-full"}
                    handleSelect={(val) => {
                      setMentalHealth(val);
                      handleParamsData({
                        target: {
                          value: val?.value,
                          name: "mental",
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {/* Psychological problems */}
              <div className="col-span-12 md:col-span-4">
                <div className="relative flex">
                  <Select
                    placeholder={`${findPsychologistLang?.select?.[language]} ${findPsychologistLang?.psychologicalProblems?.[language]}`}
                    labelClasses={
                      "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab text-left"
                    }
                    dropdownButtonClass={"!h-16 !border !border-slate-600"}
                    isSearch={true}
                    dropdownData={psychologicalData}
                    selectedValue={psychological}
                    dropdownClass={"w-full"}
                    handleSelect={(val) => {
                      setPsychological(val);
                      handleParamsData({
                        target: {
                          value: val?.value,
                          name: "psychological",
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {/* Other Topics */}
              <div className="col-span-12 md:col-span-4">
                <div className="relative flex">
                  <Select
                    placeholder={`${findPsychologistLang?.select?.[language]} ${findPsychologistLang?.otherTopics?.[language]}`}
                    labelClasses={
                      "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab text-left"
                    }
                    dropdownButtonClass={"!h-16 !border !border-slate-600"}
                    isSearch={true}
                    dropdownData={otherTopicsData}
                    selectedValue={otherTopic}
                    dropdownClass={"w-full"}
                    handleSelect={(val) => {
                      setOtherTopic(val);
                      handleParamsData({
                        target: {
                          value: val?.value,
                          name: "other",
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {/* Currency */}
              <div className="col-span-12 md:col-span-4">
                <div className="relative flex">
                  <Select
                    placeholder={`${findPsychologistLang?.select?.[language]} ${findPsychologistLang?.currency?.[language]}`}
                    labelClasses={
                      "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab text-left"
                    }
                    dropdownButtonClass={"!h-16 !border !border-slate-600"}
                    isSearch={true}
                    dropdownData={currencyData}
                    selectedValue={currency}
                    dropdownClass={"w-full"}
                    handleSelect={(val) => {
                      setCurrency(val);
                      handleParamsData({
                        target: {
                          value: val?.value,
                          name: "currency",
                        },
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-span-12">
                <div className="relative flex justify-center mt-5">
                  <Button
                    buttonHasLink={false}
                    buttonLabel={submitBtn?.[language]}
                    buttonClasses={
                      "!h-10 lg:!h-12 !bg-prontopsy-blue hover:!bg-prontopsy-pink !px-12 capitalize"
                    }
                    buttonLabelClasses={"!text-[13px]"}
                    buttonFunction={submitParams}
                    isDisable={!loaded}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ConsultantCard
        language={language}
        params={params}
        setparams={setParams}
        isSearch={isSearch}
        setIsSearch={setIsSearch}
        loaded={loaded}
        setLoaded={setLoaded}
        selectedMode={selectedMode}
        selectedGender={selectedGender}
        selectedStatus={selectedStatus}
      />
    </>
  );
};

export default PsychologistExpertSearch;
