import React, { useEffect, useRef, useState } from "react";
import { register } from "swiper/element/bundle";
import Button from "../../form/Button";
import FeatureSlideItem from "../../single/FeatureSlideItem";
import IconActiveListening from "../../../assets/images/icon-active-listening.webp";
import IconInformationOrientation from "../../../assets/images/icon-information-and-orientation.webp";
import IconPsychologicalCounseling from "../../../assets/images/icon-psychological-counseling.webp";
import { fetchServicesList } from "../../../service/servicesService";
import { useSelector } from "react-redux";
register();
const FeatureSlider = () => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const swiperElRef = useRef(null);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  useEffect(() => {
    fetchServicesList(setData, setLoaded);
  }, []);

  useEffect(() => {
    const swiperEl = document.querySelector("swiper-container.featureSlider");
    const swiperParams = {
      loop: true,
      navigation: {
        nextEl: ".featureSlider-next",
        prevEl: ".featureSlider-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: true,
          loop: true,
        },
        480: {
          slidesPerView: 1.5,
          spaceBetween: 0,
          centeredSlides: false,
          loop: true,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
          centeredSlides: false,
          loop: true,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 0,
          centeredSlides: false,
          loop: false,
        },
      },
      on: {
        init() {},
      },
    };
    swiperEl && Object.assign(swiperEl, swiperParams);
    swiperEl && swiperEl.initialize();
  }, [data]);

  let featuresData =
    data.length > 0 &&
    data.map((elm, index) => ({
      _id: elm?._id,
      name: elm?.title?.[language],
      icon: elm?.image?.url,
      styleType: index + 1,
      description: elm?.description?.[language],
    }));

  // const featuresData = [
  //   {
  //     _id: 1,
  //     name: "Active<br/>Listening",
  //     icon: IconActiveListening,
  //     styleType: 1,
  //     description:
  //       "A space where to speak about anything that is on your mind for without judgement and interruption.",
  //   },
  //   {
  //     _id: 2,
  //     name: "Information and<br/>Orientation",
  //     icon: IconInformationOrientation,
  //     styleType: 2,
  //     description:
  //       "First Reception, Information and Orientation Service addressing your request to the most suitable path.",
  //   },
  //   {
  //     _id: 3,
  //     name: "Psychological<br/>Counseling",
  //     icon: IconPsychologicalCounseling,
  //     styleType: 1,
  //     description:
  //       "Short-term and goal-oriented support to help individuals cope with specific challenges or problems.",
  //   },
  //   {
  //     _id: 4,
  //     name: "Active<br/>Listening",
  //     icon: IconActiveListening,
  //     styleType: 1,
  //     description:
  //       "A space where to speak about anything that is on your mind for without judgement and interruption.",
  //   },
  //   {
  //     _id: 5,
  //     name: "Information and<br/>Orientation",
  //     icon: IconInformationOrientation,
  //     styleType: 2,
  //     description:
  //       "First Reception, Information and Orientation Service addressing your request to the most suitable path.",
  //   },
  //   {
  //     _id: 6,
  //     name: "Psychological<br/>Counseling",
  //     icon: IconPsychologicalCounseling,
  //     styleType: 1,
  //     description:
  //       "Short-term and goal-oriented support to help individuals cope with specific challenges or problems.",
  //   },
  // ];

  return (
    <>
      <section className="relative pb-10 md:pb-12 lg:pt-14 xl:pb-16 2xl:pb-20  flex items-center z-[1] -mt-28">
        <div className="w-full px-4 sm:max-w-xl md:max-w-3xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          {featuresData?.length > 0 && (
            <div className="relative w-full">
              <swiper-container
                ref={swiperElRef}
                class="featureSlider"
                init="false"
              >
                {featuresData?.map((item) => (
                  <swiper-slide key={item._id} className="">
                    <FeatureSlideItem data={item} />
                  </swiper-slide>
                ))}
              </swiper-container>
              <div className="relative flex justify-center z-0 before:content-[''] before:absolute before:w-full before:top-7 before:left-0 before:border-t-2 before:border-neutral-200 before:z-[-1]">
                <div className="flex justify-center space-x-2 bg-white px-4">
                  <Button
                    buttonClasses={
                      "featureSlider-prev !px-0 aspect-square !rounded-full !bg-transparent !text-neutral-700 border border-neutral-700 !h-14 !text-2xl"
                    }
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-chevron-left"}
                    buttonIconPosition={"left"}
                  />
                  <Button
                    buttonClasses={
                      "featureSlider-next !px-0 aspect-square !rounded-full !bg-transparent !text-neutral-700 border border-neutral-700 !h-14 !text-2xl"
                    }
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-chevron-right"}
                    buttonIconPosition={"left"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default FeatureSlider;
