import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../socket/SocketProvider";
import Modal from "./Modal";
import { appointmentCancel } from "../../service/videoCallService";
import { toast } from "react-toastify";

const JoinCallConfirmationModal = ({ isOpen, setIsOpen, callerData }) => {
  const profileData = JSON.parse(localStorage.getItem("userDetails"));
  const [consultantJoined, setConsultantJoined] = useState(false);
  const navigate = useNavigate();
  const socket = useSocket();
  const appointmentData = JSON.parse(localStorage.getItem("appointmentData"));

  useSocket("consultantJoinResponse", async (data) => {
    if (
      profileData &&
      data?.joined === true &&
      data?.data?.user?.id === profileData?._id &&
      data?.appointmentId
    ) {
      setConsultantJoined(true);
      setIsOpen(false);
      navigate(`/chats/${data?.appointmentId}`);
    }
  });

  const handleJoinLater = () => {
    setIsOpen(false);

    appointmentCancel({ appointmentId: appointmentData?.appointmentId });
    socket &&
      socket.emit("vonageSessionReject", {
        disconnect: true,
        appointmentId: appointmentData?.appointmentId,
        role: profileData?.roleCode,
      });
    socket &&
      socket.emit("consultantBusy", {
        consultantId: appointmentData?.consultantId,
        userId: appointmentData?.callerData?.user?.id,
        isBusy: false,
      });
    localStorage.removeItem("appointmentData");
  };

  useSocket("vonageSessionRejectResponse", async (data) => {
    if (data?.disconnect === true) {
      setIsOpen(false);
      localStorage.removeItem("appointmentData");
    }
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     toast.info("Consultant is not available try to call later.");
  //   }, 1000 * 60);
  // }, []);

  return (
    <Modal
      bodyClass={"!bg-slate-800"}
      isOpen={isOpen}
      titleShow={false}
      size={"lg"}
    >
      <div className="flex flex-col justify-center items-center h-full p-6 ">
        <div className="flex items-center justify-center space-x-4 mb-6  px-10 py-5 rounded-lg">
          <div className="flex items-center justify-center space-x-8 ">
            <div className="flex flex-col items-center justify-center">
              <img
                crossOrigin="anonymous"
                src={profileData?.image?.url}
                alt={""}
                effect={"blur"}
                className={
                  "!w-24 !h-24 object-cover rounded-full mb-2 border p-1"
                }
              />
              <span className="text-lg font-semibold  text-white">{`${profileData?.firstName} ${profileData?.lastName}`}</span>
            </div>

            {consultantJoined == false && (
              <div className="flex flex-col items-center ">
                <span className="mt-2">
                  <i className="fa-duotone fa-solid fa-user fa-beat-fade text-white text-7xl"></i>
                </span>
                <span className="text-lg font-semibold text-white pt-6">
                  Joining...
                </span>
              </div>
            )}

            {consultantJoined == true && (
              <div className="flex flex-col items-center justify-center">
                <img
                  crossOrigin="anonymous"
                  src={callerData?.consultant?.image}
                  alt={""}
                  effect={"blur"}
                  className={
                    "!w-24 !h-24 object-cover rounded-full mb-2 border p-1"
                  }
                />
                <span className="text-lg font-semibold  text-white">{`${callerData?.consultant?.name}`}</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex space-x-6 text-xl">
          <button
            onClick={handleJoinLater}
            className="bg-red-500 flex gap-4 items-center  text-white px-8 py-4 rounded-lg shadow-md hover:bg-red-600 transition duration-300 ease-in-out"
          >
            <i class="fa-solid fa-xmark"></i>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default JoinCallConfirmationModal;
