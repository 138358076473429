import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setFaqData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setFaqData } = faqSlice.actions;
export default faqSlice.reducer;
