import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { setTitle } from "../../helpers/MetaTag";
import PageBanner from "../../components/sections/PageBanner";
import SignUp from "../SignUp";
import Register from "../../components/sections/signup/Register";

const ConsultantSignUp = () => {
  const data = useSelector((state) => state.pageSlice.data);
  const info =
    data &&
    Array.isArray(data) &&
    data.length > 0 &&
    data.filter((elm) => elm.title.en === "signup");

  setTitle("Prontopsy | Signup");
  const bannerData = {
    image:
      info &&
      info[0]?.banner &&
      info[0]?.banner.length > 0 &&
      info[0]?.banner[0]?.image?.url,
    title:
      info &&
      info[0]?.banner &&
      info[0]?.banner.length > 0 &&
      info[0]?.banner[0]?.title?.en,
  };
  const title = {
    en: "Consultant Signup",
    es: "Registro de consultor",
    it: "Iscrizione al consulente",
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({
        top: 550,
        behavior: "smooth", // Smooth scrolling behavior
      });
    }, 100); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <PageBanner bannerData={bannerData} />
      <Register
        image={info && info[0]?.image?.url}
        title={title}
        rolecode={"consultant"}
      />
    </>
  );
};

export default ConsultantSignUp;
