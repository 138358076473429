import React from "react";
import UserEmailList from "../components/items/emailchatBox/userEmailList";
import UserEmailDetails from "../components/items/emailchatBox/userEmailDetails";
const EmailChats = () => {
  return (
    <>
      <div className="relative">
        {/* <div className='w-2/5 bg-slate-100'>
                <div className='flex p-5'>
                    <div>Image</div>
                    <h2>Name </h2>
                </div>
                <div>
                        <img src={User} alt="User" className=''/>
                    </div>
            </div> */}
        <div className="relative flex">
          <UserEmailList />
          {/* <UserEmailDetails/> */}
        </div>
      </div>
    </>
  );
};

export default EmailChats;
