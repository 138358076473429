import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const callingSlice = createSlice({
  name: "caller",
  initialState,
  reducers: {
    setCallerData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setCallerData } = callingSlice.actions;
export default callingSlice.reducer;
