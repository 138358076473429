import { combineReducers } from "redux";
import siteSettings from "./slice/siteSettingSlice";
import pageSlice from "./slice/pageSlice";
import authSlice from "./slice/authSlice";
import roleSlice from "./slice/roleSlice";
import faqSlice from "./slice/faqSlice";
import faqTabSlice from "./slice/faqTabSlice";
import languageSlice from "./slice/languageSlice";
import profileSlice from "./slice/profileSlice";
import selectedTabSlice from "./slice/selectedTabSlice";
import initialProfileSlice from "./slice/initialProfileSlice";
import initialFaqSlice from "./slice/initialFaqSlice";
import vonageSlice from "./slice/vonageSlice";
import callingSlice from "./slice/callingSlice";
import consultantStatusSlice from "./slice/consultantStatusSlice";
import tokenSlice from "./slice/tokenSlice";
import selectedAppointmentSlice from "./slice/appointmentSlice";
import consultantSwitchSlice from "./slice/switchSlice";

const rootReducer = (asyncRedicer) => (state, action) => {
  const combinedReducers = combineReducers({
    siteSettings,
    pageSlice,
    authSlice,
    roleSlice,
    faqSlice,
    faqTabSlice,
    languageSlice,
    profileSlice,
    initialProfileSlice,
    selectedTabSlice,
    initialFaqSlice,
    vonageSlice,
    callingSlice,
    consultantStatusSlice,
    tokenSlice,
    selectedAppointmentSlice,
    consultantSwitchSlice,
    ...asyncRedicer,
  });
  return combinedReducers(state, action);
};

export default rootReducer;
