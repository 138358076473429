import { apiRequest } from "../util/async/apiUtils";

export const fetchEmailList = async (params) => {
  try {
    const res = await apiRequest(
      `${"get"}`,
      `${`/communicate/list-chat?chatType[0]=personal`}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};

export const emailChatInitialization = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/communicate/add"}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};

export const sendMessage = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/communicate/send-message"}`,
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};

export const fetchEmailDetailsList = async (id, params) => {
  try {
    const res = await apiRequest(
      `${"get"}`,
      `${`/communicate/chat-load-message`}`,
      {
        queries: {
          chatId: id,
          ...params,
        },
        // body: {
        //   ...params,
        // },
      },
      "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};
