import React, { useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { classNames } from "../../helpers/classNames";

const InputDate = ({
  label,
  labelClasses,
  isInputGroup = false,
  inputGroupIcon,
  inputGroupPosition,
  inputGroupIconClass,
  inputClasses,
  errorType,
  errorText,
  isDisabled,
  value = new Date(),
  onChange = () => { },
  ...props
}) => {
  const flatPicker = useRef(null);
  const [focused, setFocused] = useState(false);

  return (
    <>
      <div className="relative space-y-1 w-full">
        {label && (
          <div
            className={classNames(
              "text-sm font-semibold text-opplio-gray tracking-none",
              labelClasses
            )}
          >
            {label}
          </div>
        )}
        <div className={classNames("relative flex")}>
          {isInputGroup && inputGroupPosition === "left" && (
            <div
              className={classNames(
                "w-10 flex-shrink-0 flex items-center justify-center border rounded-md rounded-r-none bg-white text-denim-500 text-base transition-all duration-200",
                inputGroupIconClass,
                isDisabled ? "!bg-opplio-silver" : "",
                focused ? "border-opplio-graySky" : "border-opplio-border"
              )}
            >
              <i className={classNames("fa-fw", inputGroupIcon)}></i>
            </div>
          )}
          <div className={classNames("w-full overflow-hidden rounded-md border transition-all duration-200",
            isInputGroup && inputGroupPosition === "left" ? "!border-l-0 !rounded-l-none" : "",
            isInputGroup && inputGroupPosition === "right" ? "!border-r-0 !rounded-r-none" : "",
            focused ? "border-opplio-graySky" : "border-opplio-border")}>
            <Flatpickr
              ref={flatPicker}
              value={value}
              onChange={onChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              className={classNames(
                "w-full h-10 bg-white !rounded-none !border-none py-0 px-3 text-sm font-medium text-denim-800 !ring-0 !outline-0 transition-all duration-200",
                isDisabled ? "!bg-opplio-silver" : "",
                inputClasses,
              )}
              placeholder={props.placeholder}
            />
          </div>
          {isInputGroup && inputGroupPosition === "right" && (
            <div
              className={classNames(
                "w-10 flex-shrink-0 flex items-center justify-center border rounded-md rounded-l-none bg-white text-denim-500 text-base transition-all duration-200",
                inputGroupIconClass,
                isDisabled ? "!bg-opplio-silver" : "",
                focused ? "border-opplio-graySky" : "border-opplio-border"
              )}
            >
              <i className={classNames("fa-fw", inputGroupIcon)}></i>
            </div>
          )}
        </div>
        {errorType && errorType === "danger" && (
          <div className="text-xs font-medium text-red-600">
            <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "warning" && (
          <div className="text-xs font-medium text-amber-500">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "success" && (
          <div className="text-xs font-medium text-green-600">
            <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "info" && (
          <div className="text-xs font-medium text-sky-600">
            <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
            {errorText}
          </div>
        )}
      </div>
    </>
  );
};

export default InputDate;