import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AboutSection2 = () => {
  const [aboutSecData, setAboutSecData] = useState({});
  const data = useSelector((state) => state?.pageSlice?.data);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  useEffect(() => {
    let info =
      data.length > 0 &&
      data.filter((elm) => elm?.title?.en == "About_section2");
    setAboutSecData(info[0]);
  }, [data]);

  const aboutData = {
    title: aboutSecData && aboutSecData?.subTitle?.[language],
    description: aboutSecData && aboutSecData?.description?.[language],
  };

  return (
    <>
      <section className="relative pt-10 md:pt-14 lg:pt-16 xl:pt-20 pb-48 xl:pb-56 2xl:pb-60 flex items-center bg-prontopsy-blue">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-4xl text-center">
            {aboutData?.title && (
              <h2
                className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-white font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{ __html: aboutData?.title }}
              ></h2>
            )}
            {aboutData?.description && (
              <div
                className="text-sm md:text-base lg:text-lg text-white space-y-4 mt-4 md:mt-6 lg:mt-8 xl:mt-6 font-light"
                dangerouslySetInnerHTML={{ __html: aboutData?.description }}
              ></div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection2;
