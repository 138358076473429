import React, { useEffect } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../store/slice/vonageSlice";
import { useNavigate } from "react-router-dom";
import { setCallerData } from "../../store/slice/callingSlice";
import { useSocket } from "../socket/SocketProvider";
import { appointmentCancel } from "../../service/videoCallService";
// import ring_tone from "../../assets/music/ringtone.mp3";
// import music from "../assets/music/ringtone.mp3";

const headingLang = {
  en: "is want to start AUDIO conversation with you.",
  es: "te está llamando.",
  it: "ti sta chiamando.",
};
const subheadingLang = {
  en: "Informed Consent has been accepted and Payment has been authorized.",
  es: "Se ha aceptado el Consentimiento Informado y se ha autorizado el Pago.",
  it: "Il Consenso Informato è stato accettato e il Pagamento è stato autorizzato.",
};
const joinLang = {
  en: "Join Now",
  es: "Únete Ahora",
  it: "Iscriviti Ora",
};
const cancelLang = {
  en: "Cancel",
  es: "Cancelar",
  it: "Cancellare",
};

export const JoinCallModal = ({
  isOpen,
  setIsOpen,
  data,
  sessionId,
  sessionToken,
  callerData,
  audioRef,
}) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocket();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const appointment = JSON.parse(localStorage.getItem("appointmentData"));
  // const audioRef = useRef(new Audio(music));

  const callData = {
    user: {
      name: userDetails?.firstName + " " + userDetails?.lastName,
      email: userDetails?.email,
      image: userDetails?.image?.url,
      id: userDetails?._id,
    },
    consultant: {
      name: data?.name,
      image: data?.image?.url || data?.image,
      id: data?._id || data?.id,
    },
  };

  const handleJoinNow = () => {
    // localStorage.setItem("callerData", JSON.stringify(callData));

    dispatch(setCallerData(callData));
    dispatch(
      setToken({
        loading: true,
        sessionId: sessionId,
        token: sessionToken,
      })
    );
    if (audioRef) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    socket &&
      socket.emit("consultantBusy", {
        consultantId: data?._id,
        userId: callerData?.user?.id,
        isBusy: true,
      });
    socket &&
      socket.emit("consultantJoin", {
        joined: true,
        mode: callerData?.mode,
        data: callerData,
        appointmentId: callerData?.appointmentId,
        userId: userDetails?._id,
      });
    const appointmentData = {
      ...appointment,
      mode: callerData?.mode,
      appointmentId: callerData?.appointmentId,
      consultantId: callerData?.consultantId,
      appointmentStartTime: Date.now(),
      // appointmentStartTime: callerData?.appointmentStartTime,
      sessionId: sessionId,
      token: sessionToken,
    };
    if (!appointment || appointment?.appointmentId == null) {
      localStorage.setItem("appointmentData", JSON.stringify(appointmentData));
    }

    setIsOpen(false);
    appointment &&
      appointment?.appointmentId &&
      sessionToken &&
      navigate(`/chats/${appointment?.appointmentId}`);
  };

  useSocket("consultantJoinResponse", async (data) => {
    if (
      data?.joined === true &&
      userDetails &&
      data?.data?.consultant?.id === userDetails?._id
    ) {
      setIsOpen(false);
      appointment &&
        appointment?.appointmentId &&
        sessionToken &&
        navigate(`/chats/${appointment?.appointmentId}`);
      // handleJoinNow();
    }
  });

  const handleJoinLater = () => {
    setIsOpen(false);
    appointmentCancel({ appointmentId: callerData?.appointmentId });
    socket &&
      socket.emit("consultantBusy", {
        consultantId: callerData?.consultant?.id || "",
        userId: callerData?.user?.id,
        isBusy: false,
      });
    socket &&
      socket.emit("vonageSessionReject", {
        disconnect: true,
        appointmentId: callerData?.appointmentId,
        role: userDetails?.roleCode,
        consultantId: callerData?.consultant?.id || "",
        userId: callerData?.user?.id,
      });
    localStorage.removeItem("appointmentData");
  };

  useEffect(() => {
    let timer;

    if (isOpen) {
      timer = setTimeout(() => {
        socket.emit("autoDisconnectCall", {
          appointmentId: callerData?.appointmentId,
          callerData: callerData,
        });
        handleJoinLater();
      }, 1000 * 60); // 1 minute
    }

    // Cleanup the timeout if isOpen changes or component unmounts
    return () => clearTimeout(timer);
  }, [isOpen]);

  useSocket("vonageSessionRejectResponse", async (data) => {
    if (data?.disconnect === true) {
      setIsOpen(false);
      localStorage.removeItem("appointmentData");
    }
  });

  const headingLang = {
    en: `want to start a <span class="text-prontopsy-blue text-xl font-bold">${callerData?.mode}</span> consult with you.`,
    es: `Quiero iniciar una consultar de <span class="text-prontopsy-blue text-xl font-bold">${callerData?.mode}</span> contigo.`,
    it: `voglio iniziare una consultare <span class="text-prontopsy-blue text-xl font-bold">${callerData?.mode}</span> con te.`,
  };

  return (
    <Modal isOpen={isOpen} titleShow={false} size={"xl2"}>
      <div className="flex flex-col items-center justify-center md:p-8 p-2 bg-white  rounded-lg">
        <img
          crossOrigin="anonymous"
          src={data?.image?.url || data?.image}
          alt={`${data?.firstName} ${data?.lastName}`}
          effect={"blur"}
          className="!w-32 !h-32 object-cover rounded-full border-4 border-gray-200 mb-6 flex items-center justify-center"
        />
        <div className="text-lg font-medium text-gray-500 mb-6 capitalize text-center pb-4">
          <p className="text-prontopsy-pink text-2xl font-bold pb-0 uppercase">
            {" "}
            {`${data?.firstName || data?.name} ${data?.lastName || ""}`}
          </p>
          <div dangerouslySetInnerHTML={{ __html: headingLang?.[language] }} />
        </div>
        <span className="mt-[-25px] text-center text-prontopsy-dodgerBlue">
          {subheadingLang?.[language]}
        </span>
        <div className="flex md:space-x-6 space-x-4 mt-4 text-xl">
          <button
            onClick={handleJoinNow}
            className="bg-green-500  flex gap-4 items-center text-white md:px-8 px-4 py-4 rounded-lg shadow-md hover:bg-green-600 transition duration-300 ease-in-out md:text-lg text-base"
          >
            <i className="fa-solid fa-phone"></i>
            {joinLang?.[language]}
          </button>
          <button
            onClick={handleJoinLater}
            className="bg-red-500 flex md:gap-4 gap-2 items-center text-white md:px-8 px-4 py-4 rounded-lg shadow-md hover:bg-red-600 transition duration-300 ease-in-out md:text-lg text-base"
          >
            <i className="fa-solid fa-xmark"></i>
            {cancelLang?.[language]}
          </button>
        </div>
      </div>
    </Modal>
  );
};
