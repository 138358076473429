import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const selectedAppointmentSlice = createSlice({
  name: "selectedAppointment",
  initialState,
  reducers: {
    setSelectedAppointment: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setSelectedAppointment } = selectedAppointmentSlice.actions;
export default selectedAppointmentSlice.reducer;
