import axios from "axios";
import BASE_URL from "../config/host";

export const fetchFaqList = async (params, setList, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/faq/list`, {
      params: {
        limit: 200,
        ...params,
      },
    });

    if (response.status === 200) {
      setList(response.data?.docs?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchFaqCategory = async (
  params,
  setList,
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/category/list`, {
      params: {
        type: "faq",
        ...params,
      },
    });

    if (response.status === 200) {
      setList(response.data?.docs?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchRoleWiseCategory = async (
  params,
  setList,
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/faq/role-wise-category`, {
      params: {
        // type: "faq",
        ...params,
      },
    });

    if (response.data.docs.status === 200) {
      setList(response?.data?.docs?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
