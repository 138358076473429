import axios from "axios";
import BASE_URL from "../config/host";

export const getTestimonialList = async (
  setList = () => {},
  setLoaded = () => {},
  params
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/testimonial/list`, {
      params: { ...params },
    });

    setLoaded(true);
    setList(response?.data?.docs?.data);

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
