import React, { useState } from "react";
import { classNames } from "../../helpers/classNames";
import { useSelector } from "react-redux";
import { useSocket } from "../socket/SocketProvider";

const OnlineButton = ({ data }) => {
  const profile = useSelector((state) => state?.initialProfileSlice?.data);
  // const [data, setData] = useState("offline");
  // useSocket("userStatusResponse", async (data) => {
  //   if (profile && data?.userId === profile?._id) {
  //     setData(data?.online === true ? "online" : "offline");
  //   }
  // });
  const label = data;
  const textColor = {
    online: "text-prontopsy-green",
    busy: "text-prontopsy-yellow",
    offline: "text-prontopsy-red",
    "": "text-neutral-400",
  };
  const dotColor = {
    online: "text-prontopsy-green",
    busy: "text-prontopsy-yellow",
    offline: "text-prontopsy-red",
    "": "text-neutral-200",
  };

  return (
    <>
      <div
        className={classNames(
          "w-full h-6 lg:h-8 xl:h-10 rounded-md flex items-center justify-center space-x-2 px-4 border border-neutral-200 text-base lg:text-lg xl:text-xl",
          dotColor[data]
        )}
      >
        <i className="fa-solid fa-fw fa-circle"></i>
        <div
          className={classNames(
            "uppercase font-medium text-sm lg:text-base xl:text-lg",
            textColor[data]
          )}
        >
          {label}
        </div>
      </div>
    </>
  );
};

export default OnlineButton;
