import React from "react";
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";

const ConsultationItem = ({ data }) => {
  return (
    <>
      <div
        className={classNames(
          "relative overflow-hidden rounded-3xl p-4 flex",
          data?.styleType === 1
            ? "bg-prontopsy-blue"
            : data?.styleType === 2
            ? "bg-prontopsy-pink"
            : "bg-prontopsy-blue"
        )}
      >
        <div className="border border-white w-full rounded-2xl py-6 md:py-6 px-6 text-center">
          {data?.icon && (
            <div className="w-16 lg:w-20 overflow-hidden mb-3 flex-shrink-0 mx-auto">
              <Image
                src={data?.icon}
                alt={""}
                effect={"blur"}
                className={"object-contain"}
              />
            </div>
          )}
          {data.name && (
            <h4
              className="font-UnisonPro font-bold italic text-lg lg:text-[20px] text-white !leading-[1.11] mb-2"
              dangerouslySetInnerHTML={{ __html: data.name }}
            ></h4>
          )}
          {data.description && (
            <div
              className="text-base lg:text-[17px] leading-[1.4rem] text-white w-full max-w-md mx-auto font-light"
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default ConsultationItem;
