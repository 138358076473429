import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setAuthData } = authSlice.actions;
export default authSlice.reducer;
