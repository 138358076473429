import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { toast } from "react-toastify";
import Modal from "../components/elements/Modal";
import Button from "../components/form/Button";

const CheckProfile = ({ children }) => {
  const profile = useSelector((state) => state?.profileSlice?.data);
  const check = profile?.completeProfile;
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (
      user &&
      check &&
      user?.roleCode === "user" &&
      (!check?.isPersonalComplete ||
        !check?.isBillingComplete ||
        !check?.isLanguageComplete)
    ) {
      setIsOpen(true);
    } else if (
      user &&
      check &&
      user?.roleCode === "consultant" &&
      (!check?.isMemberComplete ||
        !check?.isPersonalComplete ||
        !check?.isBillingComplete ||
        // !check?.isInsuranceComplete ||
        !check?.isStripeCompleted ||
        !check?.isServiceComplete ||
        !check?.isDailyScheduleComplete ||
        !check?.isSpecializationComplete ||
        !check?.isLanguageComplete)
    ) {
      setIsOpen(true);
    }
  }, [user, check]);

  const handleOkClick = () => {
    setIsOpen(false); // Close the modal
    navigate("/profile"); // Redirect to /profile
  };

  if (isOpen) {
    return (
      <Modal
        isOpen={isOpen}
        titleShow={false}
        bodyClass="2xl:!w-3/12 lg:w-4/12 md:w-7/12 w-full"
      >
        <div className="relative flex flex-col items-center py-5">
          <div className="text-5xl mb-3 text-prontopsy-pink">
            <i class="fa-sharp fa-regular fa-circle-exclamation"></i>
          </div>
          <h2 className="text-2xl text-center font-bold text-prontopsy-blue mb-1">
            Confirmation Alert
          </h2>
          <p className="text-sm text-center mb-4">Complete Your Profile Info</p>
          <Button
            buttonLabel={"Ok"}
            buttonClasses={"mt-4 !px-12"}
            buttonFunction={handleOkClick} // Use the handleOkClick function
          />
          <Button
            buttonIcon={"fa-solid fa-xmark"}
            buttonIconPosition={"left"}
            buttonClasses={
              "!absolute !bg-transparent -top-3 -right-2 !text-black !rounded-full !bg-white !shadow-md !px-2 !text-xl"
            }
            buttonHasLink={false}
            buttonFunction={handleOkClick} // Use the handleOkClick function
          />
        </div>
      </Modal>
    );
  }

  return children;
};

export default CheckProfile;
