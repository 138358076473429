import React, { useEffect, useState } from "react";
import Image from "../../elements/Image";
import img from "../../../../src/assets/images/aboutgroup.webp";
import { useSelector } from "react-redux";

const AboutSectionAbout = () => {
  const [about, setAbout] = useState({});
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const data = useSelector((state) => state?.pageSlice?.data);

  useEffect(() => {
    let info =
      data.length > 0 &&
      data.filter((elm) => elm?.title?.en == "About_section3");
    setAbout(info[0]);
  }, [data]);

  const aboutDataThird = {
    image: about && about?.image?.url,
    title: about && about?.subTitle?.[language],
    description: about && about?.description?.[language],
  };
  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="block xl:grid grid-cols-12 gap-10 items-center">
            <div className="relative xl:col-span-5">
              {aboutDataThird?.title && (
                <h2
                  className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                  dangerouslySetInnerHTML={{ __html: aboutDataThird?.title }}
                ></h2>
              )}

              <div
                className="text-sm lg:text-[17px] text-neutral-700 space-y-4 mt-6 !leading-[1.4rem]"
                dangerouslySetInnerHTML={{
                  __html: aboutDataThird?.description,
                }}
              ></div>
            </div>
            <div className="relative xl:col-span-7">
              <div className="w-full flex relative aspect-[2/1]">
                <Image
                  src={aboutDataThird?.image}
                  alt={""}
                  effect={"blur"}
                  className={"object-cover w-full h-full"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSectionAbout;
