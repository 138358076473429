import React, { useEffect, useState } from "react";
import Tabs from "../elements/Tabs";
import ParentTabs from "../elements/ParentTab";
import { fetchRoleList } from "../../service/roleService";
import { useDispatch, useSelector } from "react-redux";
import { setRoleData } from "../../store/slice/roleSlice";
import { fetchRoleWiseCategory } from "../../service/faqService";
import { setTabSelection } from "../../store/slice/selectedTabSlice";
import CategoryTwo from "../elements/tabContent/CategoryTwo";
import { setInitialFaqData } from "../../store/slice/initialFaqSlice";

const HowCanWeHelp = () => {
  const [selectedParentTab, setSelectedParentTab] = useState({});
  const [selectedTab, setSelectedTab] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [category, setCategory] = useState([]);
  const [consultantCategory, setConsultantCategory] = useState([]);
  const [userCategory, setUserCategory] = useState([]);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const selected = useSelector((state) => state?.selectedTabSlice?.data);
  const [consultantData, setConsultantData] = useState([]);
  const [parentTabData, setParentTabData] = useState([]);
  const [userData, setUserData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchRoleList(setRoleList, setLoaded);
    fetchRoleWiseCategory({}, setCategory);
    dispatch(setTabSelection({}));
  }, [language]);
  useEffect(() => {
    dispatch(setRoleData(selectedParentTab));
  }, [selectedParentTab, language]);
  useEffect(() => {
    let data =
      category.length > 0 &&
      category.filter((elm) => elm?.roleData?.code == "consultant");
    setConsultantCategory(data);
    let user =
      category.length > 0 &&
      category.filter((elm) => elm?.roleData?.code == "user");
    setUserCategory(user);
  }, [selectedParentTab, category, language]);

  useEffect(() => {
    let consultantDataa =
      consultantCategory.length > 0 &&
      consultantCategory.map((elm, idx) => ({
        _id: elm.categoryData?._id,
        name: elm.categoryData?.title?.[language],
        code: elm?.roleData?.code,
        component: <CategoryTwo data={elm?.faqData} />,
      }));
    setConsultantData(consultantDataa);
  }, [language, consultantCategory]);

  useEffect(() => {
    let userDataa =
      userCategory.length > 0 &&
      userCategory.map((elm, idx) => ({
        _id: elm.categoryData?._id,
        name: elm.categoryData?.title?.[language],
        code: elm?.roleData?.code,
        component: <CategoryTwo data={elm?.faqData} />,
      }));
    setUserData(userDataa);
  }, [language, userCategory]);

  if (consultantData) {
    dispatch(setInitialFaqData(consultantData));
  }

  const howCanHelpData = {
    title: {
      en: "HOW<br/><span class='text-prontopsy-pink'>CAN WE HELP?</span>",
      es: "¿<br/><span class='text-prontopsy-pink'>¿CÓMO PODEMOS AYUDAR?</span>",
      it: "COME<br/><span class='text-prontopsy-pink'> POSSIAMO AIUTARE?</span>",
    },
  };

  useEffect(() => {
    const parentTabDataa = consultantData && [
      {
        _id: 11,
        name: "Consultant",
        code: "consultant",
        component: consultantData && (
          <Tabs
            tabs={consultantData}
            divClass={"w-full flex-row lg:flex-col pb-2"}
            activeClass={"!px-10"}
            mainClasses={
              "!block lg:!flex md:space-y-0 md:space-y-5 space-y-2 md:!pt-10 pt-5"
            }
            tabContentdivClasses={"!w-full !lg:w-4/5"}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            activeTabItem={"!px-10 !bg-prontopsy-pink"}
          />
        ),
      },
      {
        _id: 12,
        name: "Client",
        code: "user",
        component: consultantData && (
          <Tabs
            tabs={userData}
            divClass={"w-full flex-row lg:flex-col pb-2"}
            buttonClass={"!bg-prontopsy-pink"}
            activeClass={"!px-10"}
            mainClasses={
              "!block lg:!flex md:space-y-0 md:space-y-5 space-y-2 md:!pt-10 pt-5"
            }
            tabContentdivClasses={"!w-full !lg:w-4/5"}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            activeTabItem={"!px-10 !bg-prontopsy-pink"}
          />
        ),
      },
    ];
    setParentTabData(parentTabDataa);
  }, [language, consultantData, userData]);

  return (
    <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 flex items-center">
      <div className="space-y-10 w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        {howCanHelpData?.title && (
          <h2
            className="text-center text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
            dangerouslySetInnerHTML={{
              __html: howCanHelpData?.title?.[language],
            }}
          ></h2>
        )}
        <div className="w-full border border-black py-10 px-4 md:px-8 lg:px-10 !mt-20">
          <div className="relative !bg-white">
            <div className="">
              <ParentTabs
                tabs={parentTabData}
                divClass={
                  "w-full flex-row pb-2 justify-center -mt-16 relative z-10"
                }
                activeClass={"!px-10"}
                mainClasses={"flex-col"}
                tabContentdivClasses={"!w-full "}
                selectedTab={selectedParentTab}
                setSelectedTab={setSelectedParentTab}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowCanWeHelp;
