import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  data: {},
  appointmentData: {},
};

export const vonageSlice = createSlice({
  name: "vonage",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.loading = action.payload.loading;
      state.data = {
        sessionId: action.payload.sessionId,
        token: action.payload.token,
        mode: action.payload.mode,
      };
    },
    setAppointmemntData: (state, action) => {
      state.appointmentData = action.payload;
    },
  },
});

export const { setToken, setAppointmemntData } = vonageSlice.actions;
export default vonageSlice.reducer;
