import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRoleData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setRoleData } = roleSlice.actions;
export default roleSlice.reducer;
