import React from "react";

const ContactInfo = ({ siteSettings, language }) => {
  const phoneTitle = {
    en: "PHONE",
    es: "TELÉFONO",
    it: "TELEFONO",
  };
  const EmailTitle = {
    en: "Email",
    es: "Correo electrónico",
    it: "E-mail",
  };
  const locationTitle = {
    en: "LOCATION",
    es: "UBICACIÓN",
    it: "POSIZIONE",
  };
  const contactData = [
    {
      icon: "fa-solid fa-phone",
      title: phoneTitle?.[language],
      info: siteSettings?.phone,
    },
    {
      icon: "fa-solid fa-envelope",
      title: EmailTitle?.[language],
      info: siteSettings?.email,
    },
    {
      icon: "fa-solid fa-map-marker-alt",
      title: locationTitle?.[language],
      info:
        siteSettings?.address?.address1 +
        "" +
        siteSettings?.address?.city +
        "" +
        siteSettings?.address?.country +
        "" +
        siteSettings?.address?.state +
        "" +
        siteSettings?.address?.zipcode,
    },
  ];
  return (
    <div className="w-full relative bg-white  py-10 md:py-12 lg:py-16 xl:py-20  ">
      <div className="relative w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-10 gap-5">
          {contactData.map((item, index) => (
            <div
              key={index}
              className="border border-black py-10 px-10 flex flex-col items-center justify-center"
            >
              <div className="mb-5 text-2xl text-white bg-prontopsy-pink w-20 h-20 rounded-full flex items-center justify-center">
                <i className={`fa-solid ${item.icon}`}></i>
              </div>
              <div className="text-center text-[20px] leading-[28px] uppercase italic font-UnisonPro text-xl text-prontopsy-blue font-bold ">
                {item.title}
              </div>
              <div className="text-center text-[17px] leading-[1.4rem] font-normal text-primary-darkGray font-RobotoSlab mt-1">
                {item.info}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
