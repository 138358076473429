import React, { useEffect, useRef, useState } from "react";
import {
  fetchConsultantListPrivately,
  fetchWishlistedData,
  mankeFavoriteService,
} from "../../service/consultantService";
import { useSelector } from "react-redux";
import Modal from "../../components/elements/Modal";
import Button from "../../components/form/Button";
import CalendarMonth from "../../components/elements/CalendarMonth";
import Radio from "../../components/form/Radio";
import Image from "../../components/elements/Image";
import SpinLoader from "../../components/elements/table/SpinLoader";
import { deleteBtn, removeBtn } from "../../components/elements/language";
import { toast } from "react-toastify";
import NoDataFound from "../../components/elements/NoDataFound";
import { useNavigate } from "react-router-dom";
import Rating from "../../components/elements/Rating";
import RatingStars from "../../components/elements/RatingStars";
import { classNames } from "../../helpers/classNames";
import flags from "../../helpers/showFlag";

const WishList = () => {
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [removeLoaded, setRemoveLoaded] = useState(true);
  const token = localStorage.getItem("auth_token");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  //Pagination
  const [limit, setLimit] = useState(15);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [allList, setAllList] = useState([]);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [allList]);

  useEffect(() => {
    fetchWishlistedData(
      token,
      { userId: userDetails?._id, limit: limit, page: page },
      setList,
      setLoaded,
      setHasNextPage
    ).then((res) => {
      if (res?.data?.docs?.status === 200) {
        let metaData = res?.data?.docs?.metadata;
        setTotalDocs(metaData?.totalDocs);
        page !== 1
          ? setAllList((prevList) => [...prevList, res.data.docs.data])
          : setAllList(res.data.docs.data);
      }
    });
  }, [page]);
  useEffect(() => {
    if (scrolledToBottom && hasNextPage && loaded) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage, loaded]);

  const handleRemove = (id) => {
    mankeFavoriteService({ consultantId: id }, token, setRemoveLoaded).then(
      (res) => {
        if (res?.data?.status == 200) {
          toast.success("Item removed successfully!");
          let data = allList.filter((elm) => elm?._id !== id);
          setAllList(data);
        }
      }
    );
  };

  return (
    <div>
      {allList.length === 0 && !loaded ? (
        <SpinLoader />
      ) : (
        <div
          ref={scrollableRef}
          className="h-[80vh] xl:p-10 p-5 shadow-md overflow-auto"
        >
          {allList &&
            Array.isArray(allList) &&
            allList?.length > 0 &&
            allList?.map((elm, section, index) => (
              <div
                className="relative flex shadow-md px-5 py-5 mt-3"
                key={elm?._id}
              >
                <div className="md:flex block items-start w-full xl:space-x-5 md:space-x-3 md:space-y-0 space-y-2">
                  <div
                    className="xl:w-20 w-16 aspect-square rounded-full overflow-hidden"
                    onClick={() => navigate(`/consultant-profile/${elm?._id}`)}
                  >
                    <Image
                      src={elm?.consultantData?.image?.url}
                      alt=""
                      effect="blur"
                      className="object-cover !w-full !h-full cursor-pointer"
                    />
                  </div>
                  <div className="flex flex-wrap w-full md:justify-between items-center">
                    <div>
                      <div className="xl:text-xl text-lg whitespace-nowrap text-prontopsy-blue uppercase font-semibold">
                        {elm?.consultantData?.firstName +
                          " " +
                          elm?.consultantData?.lastName}
                      </div>

                      <div className="xl:text-sm text-xs text-slate-600">
                        {elm?.consultantData?.email}
                      </div>
                      <div>
                        <div className="flex items-center space-x-8 md:!mt-4 mt-2">
                          <div className="flex items-center space-x-2">
                            <div className="w-5 aspect-[4/3] overflow-hidden flex-shrink-0 flex">
                              <img
                                // src={getFlag(data?.nationality?.code)}
                                // alt={""}
                                src={
                                  flags[
                                    elm?.consultantData?.nationalityDetail?.iso2
                                  ]
                                }
                                alt={"selected?.image"}
                                effect={"blur"}
                                className={"object-contain"}
                              />
                            </div>
                            <div className="text-sm !leading-none text-neutral-700">
                              {elm?.consultantData?.nationalityDetail?.iso3 ||
                                ""}
                            </div>
                          </div>
                          <div className="flex items-center space-x-2 text-xl !leading-none text-prontopsy-blue">
                            <i className="fa-regular fa-fw fa-language"></i>
                            <div className="text-sm !leading-none text-neutral-700">
                              <span>
                                {elm?.consultantData?.languageDetail?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="xl:text-base text-sm text-prontopsy-green font-medium">
                      EUR 25/min
                    </div> */}
                    {/* </div> */}
                    {/* <div className="md:text-end text-start md:mt-7 mt-2"> */}
                    {/* <div className="xl:text-base text-sm">Reviews</div> */}
                    {/* <div className="flex justify-end"> */}
                    {/* {elm?.consultantData?.review > 0 && (
                        <RatingStars data={elm?.consultantData?.review} />
                      )} */}
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>

                <div className="ml-auto text-sm flex justify-center items-center absolute top-0 right-0">
                  <Button
                    // buttonLabel={removeBtn?.[language]}
                    buttonIcon={"fa-solid fa-heart"}
                    buttonClasses={"!text-red-600 !bg-transparent"}
                    buttonIconPosition={"left"}
                    buttonFunction={() => handleRemove(elm?._id)}
                    isDisable={!removeLoaded}
                  />
                </div>
              </div>
            ))}
          {allList.length == 0 && <NoDataFound />}
          {!loaded && (
            <div className="flex justify-center mt-4">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WishList;
