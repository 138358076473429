import React, { useState, useEffect, useRef, useCallback } from "react";
import OT from "@opentok/client";
import UserVideo from "../components/items/chatBox/UserVideo";
import UserChatsHeader from "../components/items/chatBox/UserChatsHeader";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { appointmentVerification } from "../service/appointmentService";
import { toast } from "react-toastify";
import useMediaStream from "../components/elements/hooks/useMediaStream";
import { useSocket } from "../components/socket/SocketProvider";
import useDetectPageLeave from "../components/elements/hooks/useDetectPageLeave";
import { getProfileDetails } from "../service/authService";

const Chats = () => {
  const [isInterviewStarted, setIsInterviewStarted] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [subscriber, setSubscriber] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [session, setSession] = useState(null);
  const [messages, setMessages] = useState([]);
  const { closeMediaDevices } = useMediaStream();
  const { id } = useParams();
  const appointmentData = JSON.parse(localStorage.getItem("appointmentData"));
  const profile = JSON.parse(localStorage.getItem("userDetails"));
  const roleCode = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const callerData = useSelector((state) => state?.callingSlice?.data);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const apiKey = 47879001;
  const sessionId = appointmentData?.sessionId;
  const token = appointmentData?.token;
  const socket = useSocket();

  //--------------Alraedy in call----------------------------

  socket &&
    appointmentData &&
    socket.emit("userAlreadyInCall", {
      userId: profile?._id,
      appointmentId: appointmentData?.appointmentId,
      disconnect: false,
      roleCode: roleCode,
    });

  //--------------------accidental call disconnect------------------

  useEffect(() => {
    if (appointmentData && socket) {
      const userId =
        roleCode === "user"
          ? appointmentData.callerData?.user?.id
          : appointmentData.callerData?.consultant?.id;

      // Check if userId is defined before emitting the event
      if (userId) {
        // setTimeout(() => {
        socket.emit("userDisconnectFromCallNotify", {
          disconnected: false,
          sessionId: appointmentData.sessionId,
          userId: userId,
          appointmentId: appointmentData?.appointmentId,
        });
        // }, 1000);
        localStorage.removeItem("accidentalCut");
        localStorage.removeItem("callPageLeaveFlag");
      } else {
        console.error("User  ID is not defined.");
      }
    } else {
      console.error("Either appointmentData or socket is not available.");
    }
  }, [socket]);

  useSocket("userDisconnectFromCallNotifyResponse", (data) => {
    if (data?.disconnected === true && data?.sessionId === sessionId) {
      if (data?.userId === appointmentData?.callerData?.user?.id) {
        toast.error("User left the consult ( Please wait he will rejoin).", {
          autoClose: 5000,
        });
      } else if (data?.userId === appointmentData?.callerData?.consultant?.id) {
        toast.error(
          "Consultant left the consult ( Please wait he will rejoin).",
          {
            autoClose: 5000,
          }
        );
      }
    }
  });

  //----------------- Page leave ------------------

  // Function to emit the socket event when the user leaves the page without cutting the call
  const handlePageLeave = useCallback(() => {
    if (socket && appointmentData) {
      socket.emit("userAlreadyInCall", {
        userId: profile?._id,
        appointmentId: appointmentData?.appointmentId,
        disconnect: true,
        roleCode: profile?.roleCode,
      });
    }
  }, [socket, appointmentData, profile]);

  useEffect(() => {
    // Listener for when the user navigates away from the page
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        handlePageLeave();
      }
    };

    // Listener for the "beforeunload" event
    const handleBeforeUnload = (event) => {
      handlePageLeave();
      // If you want to show a confirmation dialog, you can uncomment the line below:
      // event.returnValue = "Are you sure you want to leave this page?";
    };

    // Attach event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handlePageLeave]);
  //---------------------------------------------------------------

  //------------Appointment close after browser closing--------------

  const intervalRef = useRef(null);
  useEffect(() => {
    // Set up the interval to call the callback every 10 minutes
    intervalRef.current = setInterval(() => {
      socket &&
        socket.emit("appointmentPing", {
          appointmentId: appointmentData?.appointmentId,
          onCall: true,
          id: profile?._id,
        });
    }, 60 * 1000); // 5 minutes = 300000ms

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalRef.current);
  }, [socket]); // Ensure callback is up-to-date

  //-----------------------------------------------------------------

  useEffect(() => {
    if (id && appointmentData && appointmentData.appointmentId) {
      appointmentVerification({ appointmentId: id }).then((res) => {
        if (res?.data?.status !== 200) {
          toast.error("Appointment not found!");
          navigate("/");
          localStorage.removeItem("appointmentData");
        }
      });
    } else {
      navigate("/");
      localStorage.removeItem("appointmentData");
    }
  }, [id]);

  const stopStreaming = async () => {
    if (session) {
      if (publisher) {
        session.unpublish(publisher);
        setPublisher(null);
      }

      if (subscriber) {
        session.unsubscribe(subscriber);
        setSubscriber(null);
      }

      if (publisher && typeof publisher.destroy === "function") {
        publisher.destroy();
      }
      if (subscriber && typeof subscriber.destroy === "function") {
        subscriber.destroy();
      }

      session.disconnect();
      setSession(null);
      setIsInterviewStarted(false);
    }

    //--------------------------
    // navigator.mediaDevices
    //   .getUserMedia({ video: true, audio: true })
    //   .then((mediaStream) => {
    //     // Get all the tracks (both audio and video)
    //     const tracks = mediaStream.getTracks();

    //     // Stop each track to turn off the camera and microphone
    //     tracks.forEach((track) => track.stop());

    //     console.log("Camera and microphone stopped");
    //   })
    //   .catch((error) => {
    //     console.error("Error accessing media devices:", error);
    //   });
    //-----------------------------

    // stopAllMediaTracks();
    // stopCameraAndMic();
    closeMediaDevices();
  };

  useEffect(() => {
    if (appointmentData?.sessionId) {
      setIsInterviewStarted(true);
    }
  }, [appointmentData?.sessionId]);

  useEffect(() => {
    if (isInterviewStarted) {
      initializeSession(apiKey, sessionId, token, appointmentData?.mode);
    } else {
      stopStreaming();
    }

    // Cleanup function
    return () => {
      stopStreaming();
    };
  }, [isInterviewStarted, apiKey, sessionId, token, appointmentData?.mode]);

  const initializeSession = (apiKey, sessionId, token, mode) => {
    const newSession = OT.initSession(apiKey, sessionId);

    const publisherOptions = {
      insertMode: "append",
      style: { buttonDisplayMode: "off" },
      width: "100%",
      height: "100%",
      publishAudio: true, // Always enable audio
    };

    if (mode === "voice") {
      publisherOptions.videoSource = null; // Disable video for voice-only mode
    }

    const newPublisher = OT.initPublisher(
      "publisher",
      publisherOptions,
      handleError
    );

    newSession.on("streamCreated", (event) => {
      const newSubscriber = newSession.subscribe(
        event.stream,
        "subscriber",
        {
          insertMode: "append",
          // style: { buttonDisplayMode: "off" },
          width: "100%",
          height: "100%",
          videoSource: mode === "voice" ? null : undefined,
          audio: true,
        },
        handleError
      );
      setSubscriber(newSubscriber);
      setIsSubscribed(true);
    });

    newSession.on("streamDestroyed", () => {
      setIsSubscribed(false);
    });

    newSession.connect(token, (error) => {
      if (error) {
        handleError(error);
      } else {
        newSession.publish(newPublisher, handleError);

        newSession.on("signal:chat", (event) => {
          handleMessageReceived(event.data);
        });
      }
    });

    setSession(newSession);
    setPublisher(newPublisher);
  };

  const handleError = (error) => {
    if (error) {
      alert(error.message);
    }
  };

  const handleMessageReceived = (message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "me", text: message },
    ]);
    setInputMessage("");
  };

  const toggleVideo = (state) => {
    if (publisher && subscriber) {
      publisher.publishVideo(state);
    }
  };

  const toggleAudio = (state) => {
    if (publisher && subscriber) {
      publisher.publishAudio(state);
    }
  };

  const toggleFullscreen = () => {
    if (containerRef.current) {
      if (isFullscreen) {
        document.exitFullscreen();
      } else {
        containerRef.current.requestFullscreen();
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  useEffect(() => {
    if (appointmentData?.mode == "voice" && isInterviewStarted) {
      toggleVideo(false);
      setIsVideoEnabled(false);
    }
  }, [appointmentData, isInterviewStarted]);

  return (
    <div
      className="bg-zinc-800 h-screen flex flex-col overflow-hidden"
      ref={containerRef}
    >
      <div>
        <div className="flex-1 flex">
          <UserVideo
            isAudioEnabled={isAudioEnabled}
            data={callerData?.consultant?.name}
          />
        </div>
        <UserChatsHeader
          toggleVideo={toggleVideo}
          toggleAudio={toggleAudio}
          isFullscreen={isFullscreen}
          isChatVisible={isChatVisible}
          stopStreaming={stopStreaming}
          isAudioEnabled={isAudioEnabled}
          isVideoEnabled={isVideoEnabled}
          toggleFullscreen={toggleFullscreen}
          setIsAudioEnabled={setIsAudioEnabled}
          setIsVideoEnabled={setIsVideoEnabled}
          isInterviewStarted={isInterviewStarted}
          setIsInterviewStarted={setIsInterviewStarted}
          toggleChatVisibility={() => setIsChatVisible(!isChatVisible)}
          subscriber={subscriber?.element}
        />
      </div>
    </div>
  );
};

export default Chats;
