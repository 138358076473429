import axios from "axios";
import BASE_URL from "../config/host";

export const fetchReviewList = async (
  id,
  params,
  token,
  setList = () => {},
  setLoaded = () => {},
  setHasNextPage = () => {}
) => {
  setLoaded(false);
  try {
    const response = await axios.get(`${BASE_URL}/review/list-front/${id}`, {
      params: { ...params },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      setList(response.data.docs.data);
      setHasNextPage(response.data.docs.metadata.hasNextPage);
    }
    setLoaded(true);
    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchAvgStatReview = async (
  id,
  token,
  setList = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(
      `${BASE_URL}/review/stat-avg-review-front/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.status === 200) {
      setList(response?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
