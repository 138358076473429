import { apiRequest } from "../util/async/apiUtils";

export const resendOtp = async (params) => {
    try {
      const res = await apiRequest(
        `${"post"}`,
        `${"/send-otp"}`,
        {
          body: {
            ...params,
          },
        }
        // "multipart/form-data"
      );
      const data = res.data;
      return res;
    } catch (err) {
      return err;
    }
  };

  export const verifyOtp = async (params) => {
    try {
      const res = await apiRequest(
        `${"post"}`,
        `${"/verify-otp"}`,
        {
          body: {
            ...params,
          },
        }
        // "multipart/form-data"
      );
      const data = res.data;
      return res;
    } catch (err) {
      return err;
    }
  };
  