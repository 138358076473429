import React from 'react';
import { Outlet } from 'react-router-dom';
import DefaultHeader from '../shared/DefaultHeader';
import DefaultFooter from "../shared/DefaultFooter";
const AuthLayout = () => {
  return (
    <>
      <DefaultHeader />
        <Outlet />
      <DefaultFooter />
    </>
  );
};

export default AuthLayout;