import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OtpField from "./OtpField";
import InputGroup from "../../form/Input";
import Button from "../../form/Button";

const EmailModal = ({
  isOpen,
  onClose,
  onSubmit,
  email,
  setEmail = () => {},
  loaded = true,
  isClicked,
  setIsClicked = () => {},
  isValidEmail,
}) => {
  const siteData = useSelector((state) => state.siteSettings.data);

  // function isValidEmail(email) {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // }

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className=" flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="pb-[70px] pt-[50px]">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <div className="w-full">
                  <img
                    className="m-auto"
                    src={siteData?.logo?.url}
                    height="40px"
                    width="100px"
                    alt="Your Company"
                    crossorigin="anonymous"
                  />
                </div>
                <div className="text-center font-bold mt-5">
                  <h2 className="text-blue-600 text-2xl">Forget Password ?</h2>
                  <h3 className="mt-4 text-gray-600 text-xl">
                    Enter Your Email To Reset <br /> Password
                  </h3>
                </div>
                <div className="mt-2">
                  <p>Email :</p>
                  <InputGroup
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setIsClicked(false);
                    }}
                    errorType={
                      isClicked
                        ? !email
                          ? "warning"
                          : isValidEmail(email)
                          ? ""
                          : "danger"
                        : ""
                    }
                    errorText={
                      email && isValidEmail(email)
                        ? ""
                        : "Enter a valid email id!"
                    }
                    inputValue={email}
                    inputName="email"
                    labelClasses="font-RobotoSlab"
                    inputPlaceholder="Email"
                    inputType="email"
                    inputClasses="!text-black border-none placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-black/20"
                  />

                  <Button
                    buttonFunction={onSubmit}
                    buttonLabel={"Submit"}
                    isDisable={!loaded}
                    buttonClasses="w-full mt-4 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                  >
                    Submit
                  </Button>
                  <br />
                  <button
                    type="button"
                    className="mt-1 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500    "
                    onClick={() => {
                      onClose();
                      setIsClicked(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailModal;
