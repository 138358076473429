import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import PageBanner from "../components/sections/PageBanner";
import ImageBanner from "../assets/images/community-psychology.webp";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import Consultants from "../components/sections/Consultants";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

const InterventionDetails = () => {
  const [bannerInfo, setbannerInfo] = useState({});
  setTitle("Prontopsy | Community Psychology");
  const data = useSelector((state) => state?.pageSlice?.data);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const { id } = useParams();
  let location = useLocation();
  let title = new URLSearchParams(location.search).get("title");

  useEffect(() => {
    let info =
      data.length > 0 &&
      data.filter((elm) => elm?.title?.en == "Community_psychology");
    setbannerInfo(info[0]);
  }, [data]);

  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title: title,
    // title:
    //   bannerInfo &&
    //   bannerInfo?.banner &&
    //   bannerInfo?.banner.length > 0 &&
    //   bannerInfo?.banner[0]?.title?.[language],
  };
  // const bannerData = {
  //   image: ImageBanner,
  //   title: "Community<br/><span class='text-prontopsy-pink'>Psychology</span>",
  // }

  return (
    <>
      <PageBanner bannerData={bannerData} />
      <Consultants params={{ inventionArea: id }} />
      <Register />
    </>
  );
};

export default InterventionDetails;
