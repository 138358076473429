import Button from "../form/Button";
import Modal from "./Modal";

const LeaveModal = ({ isOpen, setIsOpen, onConfirm, loading }) => {
  return (
    <Modal isOpen={isOpen} titleShow={false} size={"sm"}>
      <div className="text-center my-6 space-y-8">
        <span className="my-2 text-6xl text-red-500 bg-slate-300 px-4 p-4 rounded-full">
          <i class="fa-solid fa-phone-xmark"></i>
        </span>
        <p className="mb-4">Are you sure you want to leave the call?</p>
      </div>
      <div className="flex justify-center gap-3">
        <Button
          buttonHasLink={true}
          buttonFunction={() => setIsOpen(false)}
          buttonLabel={"Cancel"}
          buttonIcon={"fa-light fa-xmark"}
          buttonIconPosition={"left"}
          buttonClasses={
            "uppercase !bg-red-500 text-sm hover:!bg-prontopsy-blue"
          }
        />
        <Button
          buttonLabel={loading ? "Leaving..." : "Leave"}
          buttonIcon={"fa-solid fa-right-from-bracket"}
          buttonIconPosition={"left"}
          buttonClasses={"uppercase text-sm hover:!bg-prontopsy-blue"}
          buttonFunction={onConfirm}
          loading={loading}
        />
      </div>
    </Modal>
  );
};

export default LeaveModal;
