// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable global-require, no-param-reassign, no-void, no-shadow */
/* eslint-disable func-names */
import assign from 'lodash/assign';
import eventing from '../../helpers/eventing';
import DefaultExceptionCodes from '../exception_codes';
import OTErrorClassDefault from '../ot_error_class';
import shouldForceTurn from '../../helpers/shouldForceTurn';
import setICEConfigWithForcedTurn from '../../helpers/setIceConfigWithForcedTurn';
import getOTHelpers from '../../common-js-helpers/OTHelpers';
import getPeerConnection from './peer_connection';
import getSPCAdapter from './singlePeerConnectionAdapter';
import setCertificatesFactory from './set_certificates';
import getErrors from '../Errors';
import getWatchSubscriberAudio from './watchSubscriberAudio';

export default function SubscriberPeerConnectionFactory(deps = {}) {
  const OTHelpers = deps.OTHelpers || getOTHelpers;
  const PeerConnection = deps.PeerConnection || getPeerConnection();
  const SinglePeerConnectionAdapter = deps.SinglePeerConnectionAdapter || getSPCAdapter();
  const setCertificates = deps.setCertificates || setCertificatesFactory();
  const Errors = deps.Errors || getErrors;
  const ExceptionCodes = deps.ExceptionCodes || DefaultExceptionCodes;
  const OTErrorClass = deps.OTErrorClass || OTErrorClassDefault;
  const watchSubscriberAudio = deps.watchSubscriberAudio || getWatchSubscriberAudio;

  /*
   * Abstracts PeerConnection related stuff away from Subscriber.
   *
   * Responsible for:
   * * setting up the underlying PeerConnection (delegates to PeerConnections)
   * * triggering a connected event when the Peer connection is opened
   * * triggering a disconnected event when the Peer connection is closed
   * * creating a video element when a stream is added
   * * responding to stream removed intelligently
   * * providing a destroy method
   * * providing a processMessage method
   *
   * Once the PeerConnection is connected and the video element playing it
   * triggers the connected event
   *
   * Triggers the following events
   * * connected
   * * disconnected
   * * remoteTrackAdded
   * * error
   *
   */

  return function SubscriberPeerConnection({
    clientCandidates,
    iceConfig,
    send,
    logAnalyticsEvent,
    p2p,
    codecFlags,
    sourceStreamId,
    remoteConnectionId,
    _singlePeerConnectionController,
    keyStore,
    sFrameClientStore,
    isE2ee,
    sessionId,
  }) {
    const _subscriberPeerConnection = this;
    let _peerConnection;
    let _destroyed = false;
    let _awaitingIceRestart = false;
    let _subscriberAudioWatcher = null;
    // _singlePeerConnectionController is also used as a flag.
    const _isSpcEnabled = !!_singlePeerConnectionController;

    const _videoTracks = [];
    const _audioTracks = [];

    // Private
    const _onPeerClosed = function () {
      this.destroy();
      if (_awaitingIceRestart) {
        this.trigger('iceRestartFailure', this);
      }
      this.trigger('disconnected', this);
    };

    const _addTrack = (track) => {
      const tracks = track.kind === 'video' ? _videoTracks : _audioTracks;
      // Don't add the same track twice
      if (!tracks.includes(track)) {
        tracks.push(track);
      }
    };

    const _onRemoteTrackAdded = ({ track, transceiver, stream }) => {
      // Add track to the tracks list.
      _addTrack(track);
      this.trigger('remoteTrackAdded', stream, this);
      if (isE2ee) {
        _peerConnection.startDecryption(remoteConnectionId, transceiver);
      }
    };

    const _onRemoteVideoSupported = (supported) => {
      this.trigger('remoteVideoSupported', supported);
    };

    const _onDecryptFailed = () => {
      this.trigger('decryptFailed');
    };

    const _onDecryptRestored = () => {
      this.trigger('decryptRestored');
    };

    // Note: All Peer errors are fatal right now.
    const _onPeerError = function ({ reason, prefix }) {
      this.trigger('error', null, reason, this, prefix);
    };

    const _onIceConnectionStateChange = function (state) {
      if (_awaitingIceRestart && (state === 'connected' || state === 'completed')) {
        _awaitingIceRestart = false;
        this.trigger('iceRestartSuccess');
      }

      if (state === 'connected') {
        this.trigger('connected');
      }

      this.trigger('iceConnectionStateChange', state);
    };

    const _onsignalingStateChange = function (state) {
      this.trigger('signalingStateChange', state);
    };

    const _onsignalingStateStable = function (state) {
      this.trigger('signalingStateStable', state);
    };

    const _relayMessageToPeer = (type, content, completion) => {
      if (type === 'answer' || type === 'pranswer') {
        this.trigger('connected');
      }
      send({ type, content }, completion);
    };

    const _getAudioStats = (callback) => {
      const [track] = _audioTracks;
      _peerConnection?.getStats(track, callback);
    };

    eventing(this);

    // Public

    this._hasAudioTracks = () => _audioTracks.length > 0;
    this._hasVideoTracks = () => _videoTracks.length > 0;

    this._getVideoTracks = () => _videoTracks;
    this._getAudioTracks = () => _audioTracks;
    this._getTracks = () => [..._audioTracks, ..._videoTracks];

    this.close = function () {
      if (_destroyed) { return; }

      _destroyed = true;

      if (_peerConnection) {
        _peerConnection.disconnect();
        _peerConnection = null;
      }

      this.off();
    };

    this.destroy = function () {
      this.stopAudioStatsWatcher();
      if (_destroyed) { return; }

      this.close();
    };

    this.getDataChannel = function (label, options, completion) {
      _peerConnection.getDataChannel(label, options, completion);
    };

    this.getSourceStreamId = () => _peerConnection.getSourceStreamId();

    this.processMessage = function (type, message) {
      _peerConnection.processMessage(type, message);
    };

    this.remoteDescription = function () {
      return _peerConnection.remoteDescription();
    };

    this.getStats = function (callback) {
      if (_peerConnection) {
        _peerConnection.getStats(null, callback);
      } else {
        const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
        callback(new OTHelpers.Error(OTErrorClass.getTitleByCode(errorCode),
          Errors.PEER_CONNECTION_NOT_CONNECTED, {
            code: errorCode,
          }));
      }
    };

    this.getAudioStats = function (callback) {
      _getAudioStats(callback);
    };

    this.getSynchronizationSources = function (callback) {
      if (!_peerConnection) {
        const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
        callback(new OTHelpers.Error(OTErrorClass.getTitleByCode(errorCode),
          Errors.PEER_CONNECTION_NOT_CONNECTED, {
            code: errorCode,
          }));

        return;
      }

      _peerConnection.getSynchronizationSources(callback);
    };

    this.getRtcStatsReport = function (callback) {
      if (_peerConnection) {
        _peerConnection.getRtcStatsReport(null, callback);
      } else {
        const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
        callback(new OTHelpers.Error(OTErrorClass.getTitleByCode(errorCode),
          Errors.PEER_CONNECTION_NOT_CONNECTED, {
            code: errorCode,
          }));
      }
    };

    this.setIceConfig = (iceConfig) => {
      _peerConnection.setIceConfig(iceConfig);
    };

    this.generateOffer = () => {
      _peerConnection.generateOfferAndSend();
    };

    this.startAudioStatsWatcher = function (disableAudioLevelStuckAt0) {
      if (!_subscriberAudioWatcher) {
        _subscriberAudioWatcher = watchSubscriberAudio(
          _getAudioStats,
          (reason) => {
            this.stopAudioStatsWatcher();
            this.trigger('audioLevelStuckWarning', reason);
          },
          disableAudioLevelStuckAt0
        );
      }
    };

    this.stopAudioStatsWatcher = function () {
      if (_subscriberAudioWatcher) {
        _subscriberAudioWatcher.stop();
      }
      _subscriberAudioWatcher = null;
    };

    // Helper method used by subscribeToAudio/subscribeToVideo
    const _createSetEnabledForTracks = function (kind) {
      return function (enabled) {
        const tracks = kind === 'video' ? _videoTracks : _audioTracks;
        if (!_peerConnection) {
          // We haven't created the peer connection yet, so there are no remote streams right now.
          // Subscriber will try again after onRemoteStreamAdded so this works out ok.
          return;
        }
        _peerConnection.remoteTracks().forEach((track) => {
          if (track.kind === kind && track.enabled !== enabled && tracks.includes(track)) {
            track.enabled = enabled;
          }
        });
      };
    };

    this.subscribeToAudio = _createSetEnabledForTracks('audio');
    this.subscribeToVideo = _createSetEnabledForTracks('video');

    this.hasRelayCandidates = function () {
      return _peerConnection.hasRelayCandidates();
    };

    this.iceRestart = function () {
      _awaitingIceRestart = true;
      return _peerConnection.iceRestart();
    };

    this.iceConnectionStateIsConnected = function () {
      return _peerConnection.iceConnectionStateIsConnected();
    };

    // Init
    this.init = function (completion) {
      if (shouldForceTurn(sourceStreamId)) {
        setICEConfigWithForcedTurn(iceConfig);
      }
      const pcConfig = { iceConfig };

      setCertificates(pcConfig, (err, pcConfigWithCerts) => {
        if (err) {
          completion(err);
          return;
        }

        const peerConnectionConfig = assign(
          {
            logAnalyticsEvent,
            clientCandidates,
            codecFlags,
            sourceStreamId,
            keyStore,
            sFrameClientStore,
            isE2ee,
            sessionId,
          },
          pcConfigWithCerts
        );

        const options = assign({ sendMessage: _relayMessageToPeer, p2p },
          peerConnectionConfig, { remoteConnectionId });

        if (_isSpcEnabled) {
          // SinglePeerConnection is the interface of PeerConnection to use when SPC.
          _peerConnection =
           new SinglePeerConnectionAdapter(options, _singlePeerConnectionController);
        } else {
          _peerConnection = new PeerConnection(options);
        }

        _peerConnection.on({
          iceConnected: () => _subscriberPeerConnection.emit('iceConnected'),
          close: _onPeerClosed,
          trackAdded: _onRemoteTrackAdded,
          signalingStateChange: _onsignalingStateChange,
          signalingStateStable: _onsignalingStateStable,
          error: _onPeerError,
          qos: qos => this.trigger('qos', qos),
          iceConnectionStateChange: _onIceConnectionStateChange,
          remoteVideoSupported: _onRemoteVideoSupported,
          decryptFailed: _onDecryptFailed,
          decryptRestored: _onDecryptRestored,
        }, _subscriberPeerConnection);

        // If there are already remoteStreams, add them immediately
        // (Using .remoteTracks to avoid deprecated .remoteStreams where possible.
        // FIXME: Is this even possible anyway? How could we already have remote streams in the same
        // tick the peer connection was created?)
        if (!_isSpcEnabled && _peerConnection.remoteTracks().length > 0) {
          // @todo i really don't think this branch is ever entered, it might be an artifact of the
          // unit tests
          // @todo ahh maybe reconnections?
          _peerConnection.remoteStreams().forEach((stream) => {
            stream.getTracks().forEach(track => _onRemoteTrackAdded({ stream, track }));
          });
        } else {
          completion(undefined, _subscriberPeerConnection);
        }
      });
    };
  };
}
