import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { classNames } from "../helpers/classNames";
import ProfileOnlineButton from "../components/elements/ProfileOnlineButton";
import { useDispatch, useSelector } from "react-redux";
import LogoutModal from "../components/elements/LogoutModal";
import { toast } from "react-toastify";
import { setAuthData } from "../store/slice/authSlice";
import { setTabSelection } from "../store/slice/selectedTabSlice";
import { useSocket } from "../components/socket/SocketProvider";

const Sidebar = ({ data, handleDelete = () => {} }) => {
  const [showModal, setShowModal] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const dispatch = useDispatch();
  const socket = useSocket();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const profile = useSelector((state) => state?.initialProfileSlice?.data);

  // dispatch(setTabSelection({}));

  const handleLogOut = () => {
    if (socket && user) {
      profile &&
        profile?.onlineStatus == "online" &&
        socket.emit("userOffline", profile?._id);
      // localStorage.removeItem("appointmentData");
      dispatch(setAuthData({}));
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userDetails");
      toast.success("You are logged out!");
      setLoaded(false);
      setTimeout(() => {
        // navigate("/");
        setShowModal(false);
        setLoaded(true);
      }, 500);
    } else if (!socket) {
      toast.error("Network error. Please reload your browser once!");
      window.location.reload();
    }
  };

  return (
    <>
      <div className="relative border border-slate-200 w-full h-screen space-y-5 pt-32 xl:pt-0 overflow-auto">
        {data.length > 0 && (
          <div className="relative w-full z-90">
            <div className="relative  divide-y divide-neutral-300 max-h-[500px] h-full">
              {data.map((item) => (
                <Fragment key={item._id}>
                  {item.separator ? (
                    <div className="border-t border-slate-200"></div>
                  ) : item.link ? (
                    <NavLink
                      to={item?.link}
                      className={({ isActive }) =>
                        "group flex items-center py-5 px-4 gap-3 text-slate-900 font-medium transition-all duration-200 hover:text-white hover:bg-prontopsy-pink " +
                        (isActive
                          ? "bg-prontopsy-pink text-white"
                          : "text-slate-800 bg-white")
                      }
                    >
                      {({ isActive }) => (
                        <>
                          <div
                            className={
                              "text-prontopsy-blue hover:text-white  w-5 h-5 flex items-center justify-center text-lg group-hover:text-white transition-all duration-200 " +
                              (isActive ? "text-white" : "text-prontopsy-500")
                            }
                          >
                            <i className={"fa-regular fa-fw " + item.icon}></i>
                          </div>
                          <div
                            className={
                              "text-xs uppercase font-UnisonPro italic font-bold group-hover:text-white " +
                              (isActive ? "text-white" : "text-prontopsy-blue")
                            }
                          >
                            {item.label}
                          </div>
                        </>
                      )}
                    </NavLink>
                  ) : (
                    <button
                      type="button"
                      className={classNames(
                        "group w-full flex items-center py-2 px-4 gap-3 text-prontopsy-blue font-medium transition-all duration-200 !border-b !rounded-none !pb-5 !pt-5"
                      )}
                      onClick={handleDelete}
                    >
                      <div className=" w-5 h-5 flex items-center justify-center text-lg text-prontopsy-500 group-hover:text-prontopsy-100 transition-all duration-200">
                        <i className={"fa-regular fa-fw " + item.icon}></i>
                      </div>
                      <div className="text-prontopsy-blue text-xs uppercase font-UnisonPro italic font-bold">
                        {item.label}
                      </div>
                    </button>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {user?.roleCode == "consultant" && (
          <div className="!mt-8">
            <ProfileOnlineButton
              data={profile && profile?.onlineStatus}
              id={profile?._id}
              socket={socket}
            />
          </div>
        )}
      </div>

      <LogoutModal
        show={showModal}
        onClose={closeModal}
        onConfirm={handleLogOut}
        loaded={loaded}
      />
    </>
  );
};

export default Sidebar;
