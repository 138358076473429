import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const tokenSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokenData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setTokenData } = tokenSlice.actions;
export default tokenSlice.reducer;
