import React, { useState } from "react";
import { classNames } from "../../helpers/classNames";

const UploadImage = ({ setImage = () => {} }) => {
  return (
    <div className="form-group !w-12 !h-12 !rounded-full !bg-prontopsy-pink overflow-hidden  !text-lg">
      <label
        className="form_label_certifications cursor-pointer"
        style={styles.formLabelCertifications}
      >
        {/* <img src="" alt="" /> */}
        {/* <i className={classNames("fa-fw", "fa-light fa-camera")}></i> */}
        <i
          className="fa-fw fa-light fa-camera"
          style={{
            color: "white",
          }}
        ></i>
        <input
          type="file"
          className="form-control"
          id="uploadFile"
          accept="image/*"
          style={styles.uploadFile}
          onChange={(e) => setImage(e.target.files[0])}
        />
      </label>
    </div>
  );
};

const styles = {
  formLabelCertifications: {
    alignItems: "center",
    background: "rgba(19, 139, 251, 0.05)",
    border: "1px dashed #138bfb",
    borderRadius: "12px",
    color: "#138bfb",
    display: "inline-flex",
    fontSize: "18px",
    fontWeight: 500,
    justifyContent: "center",
    lineHeight: "23px",
    minHeight: "48px",
    minWidth: "48px",
    position: "relative", // moved from HTML to here
  },
  uploadFile: {
    height: "1px",
    left: "-999px",
    opacity: 0,
    position: "absolute",
    visibility: "hidden",
    width: "1px",
  },
};

export default UploadImage;
