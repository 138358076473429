const RadioButton = ({ label, icon, isSelected, onChange }) => {
  return (
    <label
      className={`font-UnisonPro font-semibold italic relative px-4 py-2 bg-slate-100 !text-prontopsy-lightblue hover:!bg-prontopsy-lightblue text-xl hover:!text-white text-center uppercase rounded-md ${
        isSelected
          ? "border-2 border-prontopsy-lightblue !bg-prontopsy-lightblue !text-white"
          : " border border-prontopsy-lightblue"
      }`}
    >
      <input
        type="radio"
        className="hidden"
        checked={isSelected}
        onChange={onChange}
      />
      <i className={`mr-2 ${icon}`}></i>
      {label}
    </label>
  );
};
export default RadioButton;
