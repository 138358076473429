import React, { useEffect, useState } from "react";
import Image from "../../elements/Image";
import ImageBanner from "../../../assets/images/banner.webp";
import Button from "../../form/Button";
import { useSelector } from "react-redux";

const MainBanner = () => {
  const [bannerInfo, setbannerInfo] = useState({});
  const [bannerData, setBannerData] = useState({});
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const data = useSelector((state) => state?.pageSlice?.data);
  const token = localStorage.getItem("auth_token");
  useEffect(() => {
    let info =
      data.length > 0 && data.filter((elm) => elm?.title?.en == "Home_banner");
    setbannerInfo(info[0]);
  }, [data]);

  useEffect(() => {
    const modifiedData = {
      image:
        bannerInfo &&
        bannerInfo?.banner &&
        bannerInfo?.banner.length > 0 &&
        bannerInfo?.banner[0]?.image?.url,
      title:
        bannerInfo &&
        bannerInfo?.banner &&
        bannerInfo?.banner.length > 0 &&
        bannerInfo?.banner[0]?.title?.[language],
      subtitle:
        bannerInfo &&
        bannerInfo?.banner &&
        bannerInfo?.banner.length > 0 &&
        bannerInfo?.banner[0]?.subTitle?.[language],
      description: bannerInfo && bannerInfo?.description?.[language],
      action: {
        label:
          bannerInfo &&
          bannerInfo?.banner &&
          bannerInfo?.banner.length > 0 &&
          bannerInfo?.banner[0]?.buttonText?.[language],
        link: "/find-psychologist",
      },
    };
    setBannerData(modifiedData);
  }, [language, bannerInfo]);

  return (
    <>
      <section className="relative h-screen overflow-hidden z-0 flex md:items-center items-start">
        <div className="absolute top-0 left-0 w-full h-full z-[-1] flex">
          <Image
            src={bannerData?.image}
            alt={""}
            effect={"blur"}
            className={"object-cover"}
          />
        </div>
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="relative w-full 3xl:max-w-3xl max-w-2xl space-y-5 md:pt-0 pt-48">
            {bannerData?.subtitle && (
              <h4 className="text-sm lg:text-base xl:text-lg text-prontopsy-blue prontopsy-span-color tracking-widest">
                {bannerData?.subtitle}
              </h4>
            )}
            {bannerData?.title && (
              <h1
                className="text-2xl lg:text-3xl xl:text-[45px] 2xl:text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{ __html: bannerData?.title }}
              ></h1>
            )}
            {bannerData?.description && (
              <div
                className="text-xs lg:text-sm xl:text-[17px] leading-[1.4rem] text-black max-w-sm"
                dangerouslySetInnerHTML={{ __html: bannerData?.description }}
              />
            )}
            {bannerData?.action && (
              <div className="flex items-center !mt-10">
                <Button
                  buttonHasLink={true}
                  buttonLink={`${token ? bannerData?.action?.link : "/signin"}`}
                  buttonLabel={bannerData?.action?.label}
                  buttonClasses={
                    "!h-10 lg:!h-12 !px-8 hover:!bg-prontopsy-blue"
                  }
                  buttonLabelClasses={"!text-base"}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default MainBanner;
