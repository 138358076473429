import React, { useState } from "react";
import { classNames } from "../../helpers/classNames";
import { mankeFavoriteService } from "../../service/consultantService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Favorite = ({
  id,
  name,
  inputClass,
  checkClass,
  isChecked,
  ...props
}) => {
  const [loaded, setLoaded] = useState(true);
  const token = localStorage.getItem("auth_token");
  const roleCode = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  // const id = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const navigate = useNavigate();

  const handleChange = (value) => {
    roleCode === "user"
      ? mankeFavoriteService({ consultantId: id }, token, setLoaded).then(
          (res) => {
            if (res?.data?.status == 200) {
              toast.success(res?.data?.message);
            }
          }
        )
      : roleCode === "consultant"
      ? toast.info("Only a client can perform this action!")
      : navigate("/signin");
  };
  return (
    <>
      <div className="relative overflow-hidden flex items-center">
        <input
          type="checkbox"
          name={name}
          className={classNames(
            "peer absolute top-0 left-0 w-full h-full opacity-0 z-10 cursor-pointer border-none",
            inputClass
          )}
          defaultChecked={isChecked}
          disabled={roleCode == "consultant" ? true : false}
          onChange={(e) => {
            handleChange(e.target.checked);
          }}
        />
        <div
          className={classNames(
            "transition-all duration-200 fa-light w-8 aspect-square flex items-center justify-center text-2xl text-neutral-500 peer-checked:font-bold peer-checked:text-prontopsy-red",
            checkClass
          )}
        >
          <i className="not-italic fa-fw fa-heart"></i>
        </div>
      </div>
    </>
  );
};

export default Favorite;
