import React, { useEffect, useState } from "react";
import Image from "../../../Image";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Select from "../../../../form/Select";
import Checkbox from "../../../../form/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { commonFormLabels, profile } from "../../../language";
import { fetchInterventionList } from "../../../../../service/interventionService";
import MultiSelect from "../../../../form/MultiSelect";
import { fetchTheraputicList } from "../../../../../service/theraputicService";
import { fetchMentalHealthList } from "../../../../../service/mentalHealthService";
import { fetchPsychosocialProblemList } from "../../../../../service/psychologicalProblemService";
import { fetchOtherTopicList } from "../../../../../service/otherTopicsService";
import { toast } from "react-toastify";
import { setProfileData } from "../../../../../store/slice/profileSlice";
import { updateProfileService } from "../../../../../service/profileService";
const InterventionSpecializationInfo = ({ data, dataLoaded }) => {
  const dispatch = useDispatch();
  const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const id = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const profileData = useSelector((state) => state?.profileSlice?.data);
  const [formData, setFormData] = useState({});
  const [loaded, setLoaded] = useState(true);

  const [interventionArea, setInterventionArea] = useState([]);
  const [interventionTemp, setInterventionTemp] = useState({});

  const [theraputicList, settheraputicList] = useState([]);
  const [theraputics, setTheraputics] = useState([]);

  const [mentalHealthList, setMentalHealthList] = useState([]);
  const [mentaHealth, setMentaHealth] = useState([]);

  const [psychologicalProblemList, setPsychologicalProblemList] = useState([]);
  const [psychologicalProblem, setPsychologicalProblem] = useState([]);

  const [otherTopicList, setOtherTopicList] = useState([]);
  const [otherTopic, setOtherTopic] = useState([]);

  const InterventionSpecializationInfo = {
    title: profile?.specialiazion?.[language],
    titleAlt: "Change Password",
    image: User,
  };

  useEffect(() => {
    setFormData((pre) => ({
      ...pre,
      "specialization[inventionArea]":
        profileData && profileData?.specialization?.inventionArea?._id,
    }));

    setInterventionTemp({
      name:
        profileData &&
        profileData?.specialization?.inventionArea?.title?.[language],
      value: profileData && profileData?.specialization?.inventionArea?._id,
    });
    let therapy =
      profileData &&
      profileData?.specialization &&
      Array.isArray(profileData?.specialization?.theraputic) &&
      profileData?.specialization?.theraputic.map((elm) => ({
        name: elm?.title?.[language],
        value: elm?._id,
      }));
    let mental =
      profileData &&
      profileData?.specialization &&
      Array.isArray(profileData?.specialization?.mental) &&
      profileData?.specialization?.mental.map((elm) => ({
        name: elm?.title?.[language],
        value: elm?._id,
      }));
    let psychological =
      profileData &&
      profileData?.specialization &&
      Array.isArray(profileData?.specialization?.psycological) &&
      profileData?.specialization?.psycological.map((elm) => ({
        name: elm?.title?.[language],
        value: elm?._id,
      }));
    let others =
      profileData &&
      profileData?.specialization &&
      Array.isArray(profileData?.specialization?.other) &&
      profileData?.specialization?.other.map((elm) => ({
        name: elm?.title?.[language],
        value: elm?._id,
      }));

    setTheraputics(therapy);
    setMentaHealth(mental);
    setPsychologicalProblem(psychological);
    setOtherTopic(others);
  }, [profileData]);

  useEffect(() => {
    fetchInterventionList({}, setInterventionArea);
    fetchTheraputicList(settheraputicList);
    fetchMentalHealthList(setMentalHealthList);
    fetchPsychosocialProblemList(setPsychologicalProblemList);
    fetchOtherTopicList(setOtherTopicList);
  }, []);

  const interventionAreaData =
    interventionArea.length > 0 &&
    interventionArea.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));

  const theraputicData =
    theraputicList.length > 0 &&
    theraputicList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));
  const mentalHealthData =
    mentalHealthList.length > 0 &&
    mentalHealthList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));
  const psychologicalData =
    psychologicalProblemList.length > 0 &&
    psychologicalProblemList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));
  const otherTopicData =
    otherTopicList.length > 0 &&
    otherTopicList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));

  const handleTheraputicSelect = (item) => {
    setTheraputics(item);
  };
  const handleTheraputicRemove = (item, index) => {
    theraputics.length > 0 &&
      theraputics.map((elm, idx) => {
        delete formData[`specialization[theraputic][${idx}]`];
      });

    let object = {};
    item &&
      item.length > 0 &&
      item.forEach((elm, idx) => {
        object[`specialization[theraputic][${idx}]`] = elm.value;
      });
    setTheraputics(item);
    setFormData((pre) => ({ ...pre, ...object }));
  };

  const handleMentalHealthSelect = (item) => {
    setMentaHealth(item);
  };
  const handleMentalHealthRemove = (item, index) => {
    mentaHealth.length > 0 &&
      mentaHealth.map((elm, idx) => {
        delete formData[`specialization[mental][${idx}]`];
      });
    let object = {};
    item &&
      item.length > 0 &&
      item.forEach((elm, idx) => {
        object[`specialization[mental][${idx}]`] = elm.value;
      });
    setMentaHealth(item);
    setFormData((pre) => ({ ...pre, ...object }));
  };
  const handlePsychologicalProblemSelect = (item) => {
    setPsychologicalProblem(item);
  };
  const handlePsychologicalProblemRemove = (item, index) => {
    psychologicalProblem.length > 0 &&
      psychologicalProblem.map((elm, idx) => {
        delete formData[`specialization[psycological][${idx}]`];
      });
    let object = {};
    item &&
      item.length > 0 &&
      item.forEach((elm, idx) => {
        object[`specialization[psycological][${idx}]`] = elm.value;
      });
    setPsychologicalProblem(item);
    setFormData((pre) => ({ ...pre, ...object }));
  };
  const otherTopicSelect = (item) => {
    setOtherTopic(item);
  };
  const otherTopicRemove = (item, index) => {
    otherTopic.length > 0 &&
      otherTopic.map((elm, idx) => {
        delete formData[`specialization[other][${idx}]`];
      });
    let object = {};
    item &&
      item.length > 0 &&
      item.forEach((elm, idx) => {
        object[`specialization[other][${idx}]`] = elm.value;
      });
    setOtherTopic(item);
    setFormData((pre) => ({ ...pre, ...object }));
  };

  const handleFormData = (e) => {
    setFormData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };

  //Formatting final data
  useEffect(() => {
    let object = {};
    theraputics &&
      Array.isArray(theraputics) &&
      theraputics.forEach((elm, idx) => {
        object[`specialization[theraputic][${idx}]`] = elm.value;
      });
    mentaHealth &&
      Array.isArray(mentaHealth) &&
      mentaHealth.forEach((elm, idx) => {
        object[`specialization[mental][${idx}]`] = elm.value;
      });
    psychologicalProblem &&
      Array.isArray(psychologicalProblem) &&
      psychologicalProblem.forEach((elm, idx) => {
        object[`specialization[psycological][${idx}]`] = elm.value;
      });
    otherTopic &&
      Array.isArray(otherTopic) &&
      otherTopic.forEach((elm, idx) => {
        object[`specialization[other][${idx}]`] = elm.value;
      });
    // object[`specialization[inventionArea]`] = interventionArea?.value;

    setFormData((prevData) => ({
      ...prevData,
      ...object,
    }));
  }, [
    theraputics,
    mentaHealth,
    psychologicalProblem,
    otherTopic,
    interventionArea,
  ]);

  const handleSubmit = () => {
    if (!formData?.["specialization[inventionArea]"]) {
      toast.info("Intervention area is required!");
      return;
    }
    if (!theraputics || theraputics.length == 0) {
      toast.info("Therapautic approch is required!");
      return;
    }
    if (!mentaHealth || mentaHealth.length == 0) {
      toast.info("Mental and neurological disorder is required!");
      return;
    }
    if (!psychologicalProblem || psychologicalProblem.length == 0) {
      toast.info("Psychological problem is required!");
      return;
    }
    if (!otherTopic || otherTopic.length == 0) {
      toast.info("Other topic is required!");
      return;
    }

    //api call to update data
    setLoaded(false);
    updateProfileService({
      ...formData,
      roleCode: role,
      id: id,
    }).then((res) => {
      setLoaded(true);
      if (res.data.status == 200) {
        toast.success(res?.data?.message);
        dispatch(setProfileData(res.data.data));
      } else {
        toast.error(res?.data?.message);
      }
    });
  };

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {InterventionSpecializationInfo?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{
              __html: InterventionSpecializationInfo?.title,
            }}
          ></h2>
        )}
      </div>
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="grid grid-cols-12 gap-3 mb-4">
            <div className="w-full md:col-span-6 col-span-12">
              <Select
                label={`*${commonFormLabels?.interventionArea?.[language]} :`}
                selectedValue={interventionTemp && { ...interventionTemp }}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                }
                dropdownButtonClass={"!h-12 !bg-transparent"}
                isSearch={true}
                dropdownData={interventionAreaData}
                dropdownClass={"w-full"}
                handleSelect={(val) => {
                  setInterventionTemp(val);
                  handleFormData({
                    target: {
                      value: val?.value,
                      name: "specialization[inventionArea]",
                    },
                  });
                }}
              />
            </div>
            <div className="w-full md:col-span-6 col-span-12">
              <MultiSelect
                label={"*THERAPUTIC APPROACH"}
                placeholder="Select Modes.."
                dropdownData={theraputicData}
                selectedValues={theraputics}
                handleSelect={(items) => handleTheraputicSelect(items)}
                handleRemove={(items, index) =>
                  handleTheraputicRemove(items, index)
                }
                isSearch={true}
                loaded={true}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                }
                dropdownButtonClass={"!bg-transparent"}
                dropdownClass={"w-full"}
              />
            </div>
            <div className="w-full md:col-span-6 col-span-12">
              <MultiSelect
                label={"*MENTAL AND NEUROPSYCHOLOGICAL DISORDERS"}
                placeholder="Select Modes.."
                dropdownData={mentalHealthData}
                selectedValues={mentaHealth}
                handleSelect={(items) => handleMentalHealthSelect(items)}
                handleRemove={(items, index) =>
                  handleMentalHealthRemove(items, index)
                }
                isSearch={true}
                loaded={true}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                }
                dropdownButtonClass={"!bg-transparent"}
                dropdownClass={"w-full"}
              />
            </div>
            <div className="w-full md:col-span-6 col-span-12">
              <MultiSelect
                label={"*PSYCHOLOGICAL PROBLEMS"}
                placeholder="Select Modes.."
                dropdownData={psychologicalData}
                selectedValues={psychologicalProblem}
                handleSelect={(items) =>
                  handlePsychologicalProblemSelect(items)
                }
                handleRemove={(items, index) =>
                  handlePsychologicalProblemRemove(items, index)
                }
                isSearch={true}
                loaded={true}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                }
                dropdownButtonClass={"!bg-transparent"}
                dropdownClass={"w-full"}
              />
            </div>
            <div className="w-full col-span-12">
              <MultiSelect
                label={"*OTHER TOPICS"}
                placeholder="Select Topics.."
                dropdownData={otherTopicData}
                selectedValues={otherTopic}
                handleSelect={(items) => otherTopicSelect(items)}
                handleRemove={(items, index) => otherTopicRemove(items, index)}
                isSearch={true}
                loaded={true}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                }
                dropdownButtonClass={"!bg-transparent"}
                dropdownClass={"w-full"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex items-center gap-2">
        <Button
          buttonLabel={"Save Changes"}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default InterventionSpecializationInfo;
