import React from "react";
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";

const GetStartedItem = ({ data }) => {
  return (
    <>
      <div
        className={classNames(
          "relative overflow-hidden flex pt-12 px-6 even:translate-y-0 xl:even:translate-y-20"
        )}
      >
        <div
          className={classNames(
            "w-full text-center md:px-10 px-5 pt-12 pb-16",
            data?.styleType === 1
              ? "bg-prontopsy-pink"
              : data?.styleType === 2
              ? "bg-prontopsy-yellow"
              : "bg-prontopsy-pink"
          )}
        >
          {data?.icon && (
            <div
              className={classNames(
                "w-24 rounded-full overflow-hidden mb-8 flex-shrink-0 mx-auto p-5 -mt-24",
                data?.styleType === 1
                  ? "bg-prontopsy-yellow"
                  : data?.styleType === 2
                  ? "bg-prontopsy-blue"
                  : "bg-prontopsy-yellow"
              )}
            >
              <Image
                src={data?.icon}
                alt={""}
                effect={"blur"}
                className={"object-contain"}
              />
            </div>
          )}
          {data.name && (
            <h4
              className={classNames(
                "font-UnisonPro font-bold italic text-[20px] !leading-[1.1]",
                data?.styleType === 1
                  ? "text-white"
                  : data?.styleType === 2
                  ? "text-prontopsy-pink"
                  : "text-white"
              )}
              dangerouslySetInnerHTML={{ __html: data.name }}
            ></h4>
          )}
          {data.description && (
            <div
              className={classNames(
                "text-[17px] mt-6 w-full max-w-md mx-auto",
                data?.styleType === 1
                  ? "text-white"
                  : data?.styleType === 2
                  ? "text-black"
                  : "text-white"
              )}
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default GetStartedItem;
