import React, { useEffect, useState } from "react";
import Image from "../../../Image";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Select from "../../../../form/Select";
import Checkbox from "../../../../form/Checkbox";
import { commonFormLabels, profile } from "../../../language";
import { useDispatch, useSelector } from "react-redux";
import { fetchPersonalQualificationList } from "../../../../../service/personalQualificationService";
import {
  fetchCountryList,
  updateProfileService,
} from "../../../../../service/profileService";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { setProfileData } from "../../../../../store/slice/profileSlice";
import { isValidDate } from "../../../../../util/CheckValidDate";
import InputDate from "../../../../form/InputDate";
const MembershipInformation = ({ data, dataLoaded }) => {
  const [personalQualificationList, setPersonalQualificationList] = useState(
    []
  );
  const [loaded, setLoaded] = useState(true);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [formData, setFormData] = useState({});
  const [qualificationTemp, setQualificationTemp] = useState({});
  const [countryList, setCountryList] = useState([]);
  const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const id = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.profileSlice?.data);

  const checkBoxTitle = {
    en: "User is Authorized To Exercise The Above Professions Which Is Selected",
    es: "El usuario está autorizado a ejercer las profesiones anteriores que se seleccionan.",
    it: "The user is authorized to practice the previous professions that are selected.",
  };
  const MembershipInformation = {
    title: profile?.membershipInformation?.[language],
    titleAlt: "Change Password",
    image: User,
  };
  useEffect(() => {
    fetchCountryList(setCountryList);
  }, []);

  useEffect(() => {
    fetchPersonalQualificationList(setPersonalQualificationList, setLoaded);
  }, []);

  useEffect(() => {
    setFormData((pre) => ({
      ...pre,
      hasMember: profileData && profileData?.member?.hasMember,
      "member[personalQualification]":
        profileData && profileData?.member?.personalQualification?._id,
      "member[name]": profileData && profileData?.member?.name,
      "member[address][country]":
        profileData && profileData?.member?.address?.country,
      "member[address][state]":
        profileData && profileData?.member?.address?.state,
      "member[address][city]":
        profileData && profileData?.member?.address?.city,
      "member[registrationNo]":
        profileData && profileData?.member?.registrationNo,
      "member[registrationDate]":
        profileData &&
        profileData?.member?.registrationDate &&
        dayjs(profileData?.member?.registrationDate).format("YYYY-MM-DD"),
    }));
    setQualificationTemp({
      name:
        profileData &&
        profileData?.member?.personalQualification?.title?.[language],
      value: profileData && profileData?.member?.personalQualification?._id,
    });
  }, [profileData]);

  const personalQualificationData =
    personalQualificationList.length > 0 &&
    personalQualificationList.map((elm) => ({
      value: elm?._id,
      name: elm?.title?.[language],
    }));

  const countryAllData =
    countryList.length > 0 &&
    countryList.map((elm) => ({
      value: elm.name,
      name: elm.name,
    }));
  const handleFormData = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = () => {
    if (!formData?.["member[personalQualification]"]) {
      toast.info("Personal qualification is required!");
      return;
    }
    if (!formData?.["member[name]"]) {
      toast.info("Name is required!");
      return;
    }
    if (!formData?.["member[address][country]"]) {
      toast.info("Country is required!");
      return;
    }
    if (!formData?.["member[address][state]"]) {
      toast.info("State is required!");
      return;
    }
    if (!formData?.["member[address][city]"]) {
      toast.info("City is required!");
      return;
    }

    // if (!isValidDate(formData?.["member[registrationDate]"])) {
    //   toast.warning("Enter a valid registration date!");
    //   return;
    // }
    if (!formData?.["member[registrationNo]"]) {
      toast.info("Registration number is required!");
      return;
    }
    if (
      !formData?.["member[registrationDate]"] ||
      Array.isArray(formData?.["member[registrationDate]"])
    ) {
      toast.info("Registration date is required!");
      return;
    }

    setLoaded(false);
    updateProfileService({
      ...formData,
      roleCode: role,
      id: id,
    }).then((res) => {
      setLoaded(true);
      if (res.data.status == 200) {
        toast.success(res?.data?.message);
        dispatch(setProfileData(res.data.data));
      } else {
        toast.error(res?.data?.message + " " + "is required!");
      }
    });
  };

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {MembershipInformation?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{ __html: MembershipInformation?.title }}
          ></h2>
        )}
      </div>
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="grid grid-cols-12 gap-3 mb-6">
            <div className="col-span-12">
              <Select
                label={`*${commonFormLabels?.personalQualification?.[language]} :`}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                }
                selectedValue={qualificationTemp && { ...qualificationTemp }}
                dropdownButtonClass={"!h-12 !bg-transparent"}
                isSearch={true}
                dropdownData={personalQualificationData}
                dropdownClass={"w-full"}
                handleSelect={(val) => {
                  setQualificationTemp(val);
                  handleFormData({
                    target: {
                      value: val?.value,
                      name: "member[personalQualification]",
                    },
                  });
                }}
              />
              <div className="relative mt-2">
                <Checkbox
                  checkboxName={"hasMember"}
                  checkboxLabel={checkBoxTitle?.[language]}
                  onChange={(e) => handleFormData(e)}
                  isChecked={formData?.hasMember}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-3 mb-4">
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.membershipName?.[language]} :`}
                  inputName={"member[name]"}
                  inputValue={formData?.["member[name]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  inputPlaceholder={"Name"}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <Select
                  label={`*${commonFormLabels?.country?.[language]} :`}
                  selectedValue={{
                    name: formData?.["member[address][country]"],
                    value: formData?.["member[address][country]"],
                  }}
                  placeholder={"Select Country"}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  dropdownButtonClass={"!h-12 !bg-transparent"}
                  isSearch={true}
                  dropdownData={countryAllData}
                  dropdownClass={"w-full"}
                  handleSelect={(val) =>
                    handleFormData({
                      target: {
                        value: val?.value,
                        name: "member[address][country]",
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.state?.[language]} :`}
                  inputName={"member[address][state]"}
                  inputValue={formData?.["member[address][state]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  inputPlaceholder={"State"}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.city?.[language]} :`}
                  inputName={"member[address][city]"}
                  inputValue={formData?.["member[address][city]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  inputPlaceholder={"City"}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.membershipRegistrationNumber?.[language]} :`}
                  inputName={"member[registrationNo]"}
                  inputValue={formData?.["member[registrationNo]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  inputPlaceholder={"Registration Number"}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <div className="relative">
                {/* <InputGroup
                  label={`*${commonFormLabels?.membershipRegistrationDate?.[language]} :`}
                  inputName={"member[registrationDate]"}
                  inputValue={formData?.["member[registrationDate]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  inputPlaceholder={"YYYY-MM-DD"}
                  errorType={
                    !isValidDate(formData?.["member[registrationDate]"]) &&
                    "warning"
                  }
                  errorText={"The date value should be in YYYY-MM-DD format!"}
                /> */}
                <InputDate
                  label={`*${commonFormLabels?.membershipRegistrationDate?.[language]}} :`}
                  inputPlaceholder={"MM/DD/YYYY"}
                  inputName={"member[registrationDate]"}
                  loaded={dataLoaded}
                  onChange={(value) => {
                    setFormData((pre) => ({
                      ...pre,
                      "member[registrationDate]": value,
                    }));
                  }}
                  value={formData?.["member[registrationDate]"]}
                  labelClasses={
                    "!uppercase !text-[#002C6BC9] !font-bold !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex items-center gap-2">
        <Button
          buttonLabel={"Save Changes"}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default MembershipInformation;
