import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const initialProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setInitialProfileData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setInitialProfileData } = initialProfileSlice.actions;
export default initialProfileSlice.reducer;
