import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const selectedTabSlice = createSlice({
  name: "selectedTab",
  initialState,
  reducers: {
    setTabSelection: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setTabSelection } = selectedTabSlice.actions;
export default selectedTabSlice.reducer;
