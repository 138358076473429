import React, { useEffect, useState, useRef } from "react";
import HowItWorkItem from "../../items/HowItWorkItem";
import Photo1 from "../../../assets/images/work-01.svg";
import Photo2 from "../../../assets/images/work-02.svg";
import Photo3 from "../../../assets/images/work-03.svg";
import Photo4 from "../../../assets/images/work-04.svg";
import Photo5 from "../../../assets/images/work-05.svg";
import Photo7 from "../../../assets/images/work-07.svg";
import Photo8 from "../../../assets/images/work-08.svg";
import Texture01 from "../../../assets/images/texture-01.webp";
import Texture02 from "../../../assets/images/texture-02.webp";
import Texture03 from "../../../assets/images/texture-03.webp";
import Texture04 from "../../../assets/images/texture-04.webp";
import { fetchHowItWorksList } from "../../../service/howItWorksService";
const Work = ({ role, props, ref }) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  console.log(data, "data");
  useEffect(() => {
    fetchHowItWorksList(setData, setLoaded);
  }, []);

  useEffect(() => {
    let info =
      role &&
      data.length > 0 &&
      data.filter((elm) => elm?.role.includes(role?._id));
    setFilteredData(info);
  }, [data, role]);

  return (
    <>
      {filteredData &&
        filteredData.length > 0 &&
        filteredData.map((elm, index) => (
          <HowItWorkItem key={elm._id} data={elm} index={index} ref={ref} />
        ))}
    </>
  );
};

export default Work;
