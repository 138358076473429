import { useEffect, useRef, useState } from "react";
import { useSocket } from "../components/socket/SocketProvider";
import { appointmentClose } from "../service/videoCallService";

function usePageVisibility(token, socket, userId) {
  const offlineTimeoutRef = useRef(null);
  const inactivityTimeoutRef = useRef(null);
  const [isOnline, setIsOnline] = useState(false);
  const localAppointmentData = localStorage.getItem("appointmentData");
  const appointment = localAppointmentData && JSON.parse(localAppointmentData);

  useSocket("userStatusResponse", (data) => {
    if (userId === data?.userId) {
      setIsOnline(data.online);
    }
  });

  useEffect(() => {
    const emitUserOffline = () => {
      if (socket && userId) {
        !appointment && socket.emit("userOffline", userId);
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        offlineTimeoutRef.current = setTimeout(() => {
          appointment &&
            appointment?.appointmentId &&
            appointmentClose({ appointmentId: appointment?.appointmentId });

          appointment?.mode == "chat" &&
            socket &&
            socket.emit("chatDisconnect", {
              disconnect: true,
              appointmentId: appointment?.appointmentId,
            });
          appointment?.mode !== "chat" &&
            socket &&
            socket.emit("videoSessionDisconnect", {
              disconnect: true,
              appointmentId: appointment?.appointmentId,
            });
          socket &&
            socket.emit("consultantBusy", {
              consultantId: appointment?.consultantId,
              userId: appointment?.callerData?.user?.id,
              isBusy: false,
            });
          // emitUserOffline();
          localStorage.removeItem("appointmentData");
        }, 1 * 60 * 1000); // 1 minute
      } else {
        clearTimeout(offlineTimeoutRef.current);
        offlineTimeoutRef.current = null;
      }
    };

    const resetInactivityTimeout = () => {
      // token && socket && socket.emit("userOnline", userId);
      clearTimeout(inactivityTimeoutRef.current);

      inactivityTimeoutRef.current = setTimeout(() => {
        emitUserOffline();
      }, 10 * 60 * 1000); // 30 minutes of inactivity
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("mousemove", resetInactivityTimeout);
    document.addEventListener("keydown", resetInactivityTimeout);

    // Start the inactivity timer initially
    resetInactivityTimeout();

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("mousemove", resetInactivityTimeout);
      document.removeEventListener("keydown", resetInactivityTimeout);

      if (offlineTimeoutRef.current) {
        clearTimeout(offlineTimeoutRef.current);
      }
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
    };
  }, [token, socket, userId, appointment?.consultantId]);

  return null;
}

export default usePageVisibility;
