/* eslint-disable no-underscore-dangle */
const finalTaskDelay = 100;

class SubscriberPeerConnectionQueue {
  constructor() {
    this._queue = [];
    this._isProcessing = false;
    this._timeoutId = undefined;
  }

  _processFinalTaskWithDelay = () => {
    this._timeoutId = setTimeout(() => {
      this._timeoutId = undefined;
      this.processing = true;
      this.finalTask();
      this.finalTask = undefined;
    }, finalTaskDelay);
  }

  enqueue(task) {
    if (this._isProcessing) {
      this._queue.push(task);
    } else {
      if (this._timeoutId) {
        clearTimeout(this._timeoutId);
      }
      this._isProcessing = true;
      task();
    }
  }

  finally(task) {
    this.finalTask = task;
    if (this._isProcessing) {
      return;
    }
    if (this._timeoutId) {
      // do nothing, we have updated the finalTask but we need not extend the time
      return;
    }
    this._processFinalTaskWithDelay();
  }

  dequeueAndProcessNext() {
    const nextProcess = this._queue.shift();
    if (nextProcess) {
      nextProcess();
    } else {
      if (this.finalTask) {
        this._processFinalTaskWithDelay();
      }
      this._isProcessing = false;
    }
  }
}

export default SubscriberPeerConnectionQueue;
