import axios from "axios";
import BASE_URL from "../config/host";

export const fetchInterventionList = async (
  params,
  setList,
  setLoaded = () => {},
  setHasNextPage = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/category/list`, {
      params: {
        type: "intervention",
        ...params,
      },
    });

    if (response.status === 200) {
      setList(response.data?.docs?.data);
      setLoaded(true);
      setHasNextPage(response?.data?.docs?.metadata?.hasNextPage);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
