import React, { useCallback, useEffect, useState } from "react";
import Blog1 from "../../../assets/images/blog-01.webp";
import Blog2 from "../../../assets/images/blog-02.webp";
import Blog3 from "../../../assets/images/blog-03.webp";
import Blog4 from "../../../assets/images/blog-04.webp";
import Blog5 from "../../../assets/images/blog-05.webp";
import Blog6 from "../../../assets/images/blog-06.webp";
import BlogItem from "../../single/BlogItem";
import { fetchBlogList } from "../../../service/blogService";
import { useSelector } from "react-redux";

const BlogList = () => {
  const [blogList, setBlogList] = useState([]);
  const [loaded, setloaded] = useState(true);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;

  useEffect(() => {
    fetchBlogList(setBlogList, setloaded);
  }, []);

  const blogListData = {
    blogList: [
      {
        _id: 1,
        image: Blog1,
        action: {
          link: "/",
          label: "read more",
        },
        isTime: "08 Sep 2021",
        blogTitle: "Aenean pulvinar, luctus convallis ",
        blogDescription:
          "<p>est est condimentum neque, eget finibus lorem urna ac orci. Ut sit amet sollicitudin lorem. Fusce tristique felis vel orci feugiat, in dignissim erat facilisis.</p>",
      },
      {
        _id: 2,
        image: Blog2,
        action: {
          link: "/",
          label: "read more",
        },
        isTime: "08 Sep 2021",
        blogTitle: "Aenean pulvinar, luctus convallis ",
        blogDescription:
          "<p>est est condimentum neque, eget finibus lorem urna ac orci. Ut sit amet sollicitudin lorem. Fusce tristique felis vel orci feugiat, in dignissim erat facilisis.</p>",
      },
      {
        _id: 3,
        image: Blog3,
        action: {
          link: "/",
          label: "read more",
        },
        isTime: "08 Sep 2021",
        blogTitle: "Aenean pulvinar, luctus convallis ",
        blogDescription:
          "<p>est est condimentum neque, eget finibus lorem urna ac orci. Ut sit amet sollicitudin lorem. Fusce tristique felis vel orci feugiat, in dignissim erat facilisis.</p>",
      },
      {
        _id: 4,
        image: Blog4,
        action: {
          link: "/",
          label: "read more",
        },
        isTime: "08 Sep 2021",
        blogTitle: "Aenean pulvinar, luctus convallis ",
        blogDescription:
          "<p>est est condimentum neque, eget finibus lorem urna ac orci. Ut sit amet sollicitudin lorem. Fusce tristique felis vel orci feugiat, in dignissim erat facilisis.</p>",
      },
      {
        _id: 5,
        image: Blog5,
        action: {
          link: "/",
          label: "read more",
        },
        isTime: "08 Sep 2021",
        blogTitle: "Aenean pulvinar, luctus convallis ",
        blogDescription:
          "<p>est est condimentum neque, eget finibus lorem urna ac orci. Ut sit amet sollicitudin lorem. Fusce tristique felis vel orci feugiat, in dignissim erat facilisis.</p>",
      },
      {
        _id: 6,
        image: Blog6,
        action: {
          link: "/",
          label: "read more",
        },
        isTime: "08 Sep 2021",
        blogTitle: "Aenean pulvinar, luctus convallis ",
        blogDescription:
          "<p>est est condimentum neque, eget finibus lorem urna ac orci. Ut sit amet sollicitudin lorem. Fusce tristique felis vel orci feugiat, in dignissim erat facilisis.</p>",
      },
    ],
  };
  return (
    <section className="relative py-10 md:py-12 lg:py-16 xl:py-20">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-5 gap-y-10">
          <>
            {/* {blogListData?.blogList?.map((item, index) => (
                <BlogItem key={index} data={item} />
              ))} */}
            {blogList.length > 0 &&
              blogList.map((elm) => (
                <BlogItem key={elm?._id} data={elm} language={language} />
              ))}
          </>
        </div>
      </div>
    </section>
  );
};

export default BlogList;
