import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import ImageBanner from "../assets/images/feedback.webp";
import PageBanner from "../components/sections/PageBanner";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import FeedbackSection from "../components/sections/feedback/feedback";
import { useSelector } from "react-redux";

const Feedback = () => {
  const [bannerInfo, setbannerInfo] = useState({});
  setTitle("Prontopsy | Feedback");
  const data = useSelector((state) => state?.pageSlice?.data);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  useEffect(() => {
    let info =
      data.length > 0 && data.filter((elm) => elm?.title?.en == "Feedback");
    setbannerInfo(info[0]);
  }, [data]);
  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.title?.[language],
  };
  return (
    <>
      <PageBanner bannerData={bannerData} bannerClass={"!max-w-2xl"} />
      <FeedbackSection bannerTitle={bannerData?.title} language={language} />
      <SmartPortal />
      <Register />
    </>
  );
};

export default Feedback;
