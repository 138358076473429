import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import NavigationPanel from "./NavigationPanel";
import Logo from "../assets/images/logoCube.png";
import { classNames } from "../helpers/classNames";
import { toast } from "react-toastify";
import LogoutModal from "../components/elements/LogoutModal";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData } from "../store/slice/authSlice";
import { fetchLanguageList } from "../service/languageService";
import { setLanguages } from "../store/slice/languageSlice";
import { appointmentLang, sideBar } from "../components/elements/language";
import ukFlag from "../assets/images/flag/uk.png";
import spainFlag from "../assets/images/flag/spain.png";
import italyFlag from "../assets/images/flag/italy.png";
import { getProfileDetails } from "../service/authService";
import { setInitialProfileData } from "../store/slice/initialProfileSlice";
import { JoinCallModal } from "../components/elements/JoinCallModal";
import { JoinChatModal } from "../components/elements/JoinChatModal";
import { setCallerData } from "../store/slice/callingSlice";
import { useSocket } from "../components/socket/SocketProvider";
import music from "../assets/music/ringtone.mp3";
import { setConsultantStatus } from "../store/slice/consultantStatusSlice";
import LanguageDropdown from "../components/elements/LanguageDropdown";
import usePageVisibility from "../util/PageVisibility";
import useOnlineStatus from "../util/OnlineStatus";

const langConfig = {
  joinCall: {
    en: "Join The Call",
    es: "Únase a la llamada",
    it: "Partecipa alla chiamata",
  },
};

const DefaultHeader = ({ headerClass }) => {
  const [loaded, setLoaded] = useState(true);
  const token = localStorage.getItem("auth_token");
  const localProfileData = JSON.parse(localStorage.getItem("userDetails"));
  const [scrolled, setScrolled] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const profile = useSelector((state) => state?.initialProfileSlice?.data);
  const [isOpen, setIsOpen] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const callerData = useSelector((state) => state?.callingSlice?.data);
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const audioRef = useRef(new Audio(music));
  const socket = useSocket();
  const [busyConsultants, setBusyConsultants] = useState([]);
  const [appointment, setAppointment] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isJoinCall, setIsJoinCall] = useState(false);
  const localAppointmentData = localStorage.getItem("appointmentData");
  const appointmenDataLocal =
    localAppointmentData && JSON.parse(localStorage.getItem("appointmentData"));
  const [appointmenData, setAppointmenData] = useState(appointmenDataLocal);
  const roleCode = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const [toastShown, setToastShown] = useState(false);
  const [appointmentProfile, setAppointmentProfile] = useState(null);
  const [onCall, setOnCall] = useState(false);

  //--------------Check DOM interection--------------------------
  // const isUserInteracted = useRef(false);

  // useEffect(() => {
  //   function handleUserInteraction() {
  //     if (!isUserInteracted.current) {
  //       // Show the alert to inform the user about interaction
  //       alert(
  //         "Welcome! To ensure the best experience on this site, please click 'OK' to confirm your interaction. This step is necessary to enable certain features, like audio playback. Thank you!"
  //       );

  //       // Set the flag to true when the user interacts
  //       isUserInteracted.current = true;

  //       // Remove the event listeners after interaction
  //       document.removeEventListener("click", handleUserInteraction);
  //       document.removeEventListener("keydown", handleUserInteraction);
  //       document.removeEventListener("touchstart", handleUserInteraction);

  //       console.log("User interaction confirmed");
  //     }
  //   }

  //   // Attach event listeners to detect the first user interaction
  //   document.addEventListener("click", handleUserInteraction);
  //   document.addEventListener("keydown", handleUserInteraction);
  //   document.addEventListener("touchstart", handleUserInteraction);

  //   // Cleanup function to remove listeners if the component unmounts
  //   return () => {
  //     document.removeEventListener("click", handleUserInteraction);
  //     document.removeEventListener("keydown", handleUserInteraction);
  //     document.removeEventListener("touchstart", handleUserInteraction);
  //   };
  // }, [isUserInteracted.current == false]);

  //--------------Already in call------------------------
  // console.log(localProfileData, "local profile data");
  useSocket("userAlreadyInCallResponse", (data) => {
    // console.log(data, "userAlreadyInCallResponse");
    if (
      data?.appointmentId === appointmenData?.appointmentId &&
      data?.userId === localProfileData?._id &&
      data?.roleCode === roleCode
    ) {
      // data?.disconnect === true ? setOnCall(false) : setOnCall(true);
      if (data?.disconnect === true) {
        setOnCall(false);
      } else {
        setOnCall(true);
      }
    }
  });

  useEffect(() => {
    if (appointmenData && socket) {
      socket.emit("userAlreadyInCall", {
        userId: profile?._id,
        appointmentId: appointmenData?.appointmentId,
        disconnect: true,
        roleCode: roleCode,
      });
    }
  }, []);
  //-------------------*******--------------------------

  //--------------------accidental call disconnect------------------
  const [disconnectedProfile, setDisconnectedProfile] = useState(null);

  useEffect(() => {
    if (appointmenData && localProfileData && socket) {
      const userId =
        roleCode === "user"
          ? appointmenData?.callerData?.user?.id
          : appointmenData?.callerData?.consultant?.id;

      if (
        userId &&
        appointmenData?.sessionId &&
        appointmenData?.mode !== "chat"
      ) {
        socket.emit("userDisconnectFromCallNotify", {
          disconnected: disconnectedProfile?.disconnected || true,
          sessionId: appointmenData.sessionId,
          userId: userId,
        });
        localStorage.setItem("callPageLeaveFlag", true);
      } else if (userId && appointmenData?.mode === "chat") {
        socket.emit("userDisconnectFromCallNotify", {
          disconnected: disconnectedProfile?.disconnected || true,
          userId: userId,
          appointmentId: appointmenData?.appointmentId,
        });
        localStorage.setItem("callPageLeaveFlag", true);
      } else {
        console.error("User  ID is not defined.");
        localStorage.removeItem("callPageLeaveFlag");
      }
    } else {
      console.error("Either appointmentData or socket is not available.");
    }
  }, []);

  useSocket(
    "userDisconnectFromCallNotifyResponse",
    (data) => {
      data &&
        data?.userId === localProfileData?._id &&
        setDisconnectedProfile(data);
      if (
        data?.disconnected &&
        !appointmenData &&
        localProfileData &&
        data?.userId == localProfileData?._id &&
        data?.sessionId === appointmenData?.sessionId
      ) {
        localStorage.setItem(
          "appointmentData",
          JSON.stringify(appointmentProfile)
        );
        setIsJoinCall(true);
      } else if (
        !data?.disconnected &&
        appointmenData &&
        localProfileData &&
        data?.userId === localProfileData._id
      ) {
        // localStorage.removeItem("appointmentData");
        setIsJoinCall(false);
      }
      //  else {
      //   localStorage.removeItem("appointmentData");
      //   setIsJoinCall(false);
      // }
    },
    [socket]
  );

  //---------------------------------------------------------------
  useEffect(() => {
    token &&
      getProfileDetails(token).then((response) => {
        // console.log(
        //   response?.data?.docs?.appointmentData,
        //   "appointmentData in profile"
        // );
        if (response?.data?.status !== 200) {
          handleLogOut();
        }
        if (response?.data?.status === 200) {
          if (response?.data?.docs?._id) {
            localStorage.setItem(
              "userDetails",
              JSON.stringify(response?.data?.docs)
            );
          }
          if (
            response?.data?.docs?.appointmentData?._id &&
            response?.data?.docs?.appointmentData?.status === "inProgress"
          ) {
            const appointment = {
              appointmentId: response?.data?.docs?.appointmentData?._id,
              appointmentStartTime:
                response?.data?.docs?.appointmentData?.details
                  ?.appointmentStartTime,
              callerData: {
                consultant: {
                  id: response?.data?.docs?.appointmentData?.consultantId,
                  image:
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.image,
                  name:
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.firstName +
                    " " +
                    response?.data?.docs?.appointmentData?.consultantDetails
                      ?.lastName,
                },
                user: {
                  id: response?.data?.docs?.appointmentData?.clientId,
                  image:
                    response?.data?.docs?.appointmentData?.clientDetails?.image,
                  name:
                    response?.data?.docs?.appointmentData?.clientDetails
                      ?.firstName +
                    " " +
                    response?.data?.docs?.appointmentData?.clientDetails
                      ?.lastName,
                },
              },
              consultantId: response?.data?.docs?.appointmentData?.consultantId,
              mode: response?.data?.docs?.appointmentData?.service?.mode?.slug,
              paymentMode: response?.data?.docs?.appointmentData?.paymentMode,
              sessionId: response?.data?.docs?.appointmentData?.sessionId,
              token: response?.data?.docs?.appointmentData?.sessionToken,
            };
            appointment.appointmentId && setAppointmentProfile(appointment);
            appointment.appointmentId && setAppointmenData(appointment);
            appointment.appointmentId &&
              localStorage.setItem(
                "appointmentData",
                JSON.stringify(appointment)
              );
          } else {
            socket &&
              socket.emit("consultantBusy", {
                consultantId: appointmenData?.callerData?.consultant?.id || "",
                userId: appointmenData?.callerData?.user?.id,
                isBusy: false,
              });
            localStorage.removeItem("appointmentData");
            localStorage.removeItem("callPageLeaveFlag");
          }
        }
      });
    dispatch(setInitialProfileData(localProfileData));
  }, [token, onCall, socket]);

  useEffect(() => {
    if (appointmenData?.appointmentId) {
      setIsJoinCall(true);
    } else {
      setIsJoinCall(false);
    }
  }, [appointmenData, localAppointmentData]);

  const handleLogOut = () => {
    if (socket) {
      profile &&
        profile?.onlineStatus == "online" &&
        socket.emit("userOffline", profile?._id);
    } else if (!socket) {
      toast.error("Network error. Please reload your browser once!");
      window.location.reload();
    }
    dispatch(setAuthData({}));
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userDetails");
    toast.success("You are logged out!");
    setLoaded(false);
    setTimeout(() => {
      setShowModal(false);
      setLoaded(true);
    }, 500);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const profileData = {
    image: profile?.image?.url,
    userName: (profile?.firstName || "") + " " + (profile?.lastName || ""),
    userEmail: profile?.email,
  };

  const menuData = [
    {
      _id: 1,
      name: sideBar?.Home_Label?.[language],
      link: "/",
      click: () => setMenuToggle(false),
    },
    {
      _id: 2,
      name: sideBar?.Find_a_Psychologist_Label?.[language],
      link: `${token ? "/find-psychologist" : "/signin"}`,
      click: () => setMenuToggle(false),
    },
    // {
    //   _id: 3,
    //   name: sideBar?.How_It_Works_Label?.[language],
    //   link: "/howitworks",
    //   click: () => setMenuToggle(false),
    // },
    // {
    //   _id: 4,
    //   name: sideBar?.Community_Psychology_Label?.[language],
    //   link: "/community-psychology",
    //   click: () => setMenuToggle(false),
    // },
    {
      _id: 5,
      name: sideBar?.Area_of_Intervention_Label?.[language],
      link: "/area-of-intervention",
      click: () => setMenuToggle(false),
    },
    // {
    //   _id: 6,
    //   name: sideBar?.About_us_Label?.[language],
    //   link: "/about",
    //   click: () => setMenuToggle(false),
    // },
    // {
    //   _id: 7,
    //   name: sideBar?.Frequently_Asked_Questions_Label?.[language],
    //   link: "/frequently-asked-questions",
    //   click: () => setMenuToggle(false),
    // },
    {
      _id: 8,
      name: sideBar?.Blog_Label?.[language],
      link: "/blog",
      click: () => setMenuToggle(false),
    },
    // {
    //   _id: 9,
    //   name: sideBar?.Leave_a_Feedback_Label?.[language],
    //   link: "/feedback",
    //   click: () => setMenuToggle(false),
    // },
    // {
    //   _id: 10,
    //   name: sideBar?.Book_an_Interview_dial_Label?.[language],
    //   link: "/book-an-interview",
    //   click: () => setMenuToggle(false),
    // },
    // {
    //   _id: 11,
    //   name: sideBar?.Public_Front_Consultant_profile_Label?.[language],
    //   link: "/consultant-profile",
    //   click: () => setMenuToggle(false),
    // },
    {
      _id: 12,
      name: sideBar?.Contact_us_Label?.[language],
      link: "/contact-us",
      click: () => setMenuToggle(false),
    },
    {
      _id: 13,
      name: token ? "Log Out" : "Sign In",
      link: !token && "/signin",
      click: () => {
        setMenuToggle(false);
        if (token) {
          setShowModal(true);
        }
      },
    },
  ];
  const navigate = useNavigate();
  const handleScroll = () => {
    if (menuToggle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  useEffect(() => {
    function handleScroll() {
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchLanguageList(setLanguageList, setLoaded);
  }, []);

  useEffect(() => {
    handleScroll();
  }, [menuToggle]);

  if (!token) {
    dispatch(setAuthData({}));
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userDetails");
  }

  const closeModal = () => setShowModal(false);

  let startTime;
  let loopTimeout;

  useEffect(() => {}, []);
  // console.log(audioRef.current);
  useEffect(() => {
    const startPlaying = () => {
      if (!audioRef.current) return;
      startTime = Date.now();

      audioRef.current.play().catch((err) => {
        console.error("Error playing audio:", err);
      });

      audioRef.current.onended = () => {
        const currentTime = Date.now();
        const elapsedTime = (currentTime - startTime) / 1000;

        if (elapsedTime < 60) {
          audioRef.current.currentTime = 0;
          audioRef.current.play();
        } else {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      };
    };

    if (isOpen || isChatModalOpen) {
      audioRef.current.addEventListener("canplaythrough", startPlaying);

      loopTimeout = setTimeout(() => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }, 60000);
    } else {
      clearTimeout(loopTimeout);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }

    return () => {
      clearTimeout(loopTimeout);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current.removeEventListener("canplaythrough", startPlaying);
        audioRef.current.onended = null;
      }
    };
  }, [isOpen, isChatModalOpen]);

  useEffect(() => {
    dispatch(setConsultantStatus(busyConsultants));
  }, [busyConsultants]);

  useSocket("consultantCallResponse", async (data) => {
    // console.log(data);
    dispatch(setCallerData(data));
    if (localProfileData && localProfileData._id === data.consultant.id) {
      setIsOpen(true);
    }
  });

  useSocket("chatSessionResponse", async (data) => {
    dispatch(setCallerData(data));
    if (localProfileData && localProfileData?._id === data?.consultantId) {
      localStorage.setItem("appointmentData", JSON.stringify(data));
      setAppointment(data);
      setIsChatModalOpen(true);
    }
  });

  useSocket(
    "chatJoinConfirmResponse",
    async (data) => {
      if (data?.joined === true) {
        setIsChatModalOpen(false);
        navigate(`/text-chat/${data?.appointmentId}`);
      }
    },
    []
  );
  useSocket("chatSessionRejectResponse", async (data) => {
    if (data?.disconnect === true && data?.role !== "user") {
      setIsChatModalOpen(false);
      localStorage.removeItem("appointmentData");
      setIsJoinCall(false);
      roleCode == "user" &&
        toast.info("Consultant is not available try to call again later.", {
          autoClose: 10000,
        });
    }
  });

  useSocket("vonageTokBoxSessionResponse", (data) => {
    if (data?.callerData?.consultant?.id === localProfileData?._id) {
      setSessionId(data?.sessionId);
      setSessionToken(data?.token);
      setAppointment(data);
      localStorage.setItem("appointmentData", JSON.stringify(data));
    }
  });

  useSocket("consultantBusyResponse", (data) => {
    setBusyConsultants((prevConsultants) => {
      // Create a map from the existing consultants
      const consultantMap = new Map(
        prevConsultants.map((consultant) => [
          consultant.consultantId,
          consultant,
        ])
      );
      // Update or add the new data
      consultantMap.set(data.consultantId, data);
      // Convert the map back to an array
      return Array.from(consultantMap.values());
    });
  });
  useSocket("videoSessionDisconnectResponse", async (data) => {
    if (data?.disconnect === true) {
      localStorage.removeItem("appointmentData");
      setIsJoinCall(false);
    }
  });
  useSocket("vonageSessionRejectResponse", async (data) => {
    if (data?.disconnect === true && data?.userId == localProfileData?._id) {
      setIsJoinCall(false);
      localStorage.removeItem("appointmentData");
      if (!toastShown && data?.role !== "user") {
        setToastShown(true); // Set the flag to true
        roleCode === "user" &&
          toast.info("Consultant is not available, try to call again later.", {
            autoClose: 10000,
          });
      }
    }
  });

  usePageVisibility(token, socket, profile?._id);

  //---------------online Status--------------------------
  const isOnline = useOnlineStatus();
  const timeoutRef = useRef(null);
  useEffect(() => {
    const emitUserOffline = () => {
      if (document.visibilityState === "hidden" && socket && profile) {
        // Clear any existing timeouts to prevent duplicate emits
        clearTimeout(timeoutRef.current);

        // Set a timeout to emit "userOffline" after 5 minutes
        timeoutRef.current = setTimeout(() => {
          if (!appointment) {
            socket.emit("userOffline", profile?._id);
          }
        }, 5 * 60 * 1000); // 5 minutes = 300000ms
      } else {
        // If visibility changes back to visible, clear the timeout
        clearTimeout(timeoutRef.current);
      }
    };

    document.addEventListener("visibilitychange", emitUserOffline);

    return () => {
      // Cleanup: Remove event listener and clear timeout on unmount
      document.removeEventListener("visibilitychange", emitUserOffline);
      clearTimeout(timeoutRef.current);
    };
  }, [profile, socket, appointment]);

  useEffect(() => {
    // Call the function every 3 minutes (180000 ms)
    const intervalId = setInterval(() => {
      socket && profile && socket.emit("userPing", { userId: profile._id });
    }, 3 * 60 * 1000);
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [socket, profile]);

  // Navigate only when offline
  // if (!isOnline) {
  //   window.location.href = "/no-connection";
  // }
  //=========================================================

  useSocket("appointmentPingResponse", (dataa) => {
    if (
      appointmenData &&
      appointmenData?.appointmentId === dataa?.appointmentId &&
      localProfileData?._id === dataa?.id
    ) {
      setOnCall(true);
    }
  });
  // console.log(onCall, "on call");

  // useEffect(() => {
  //   !appointmenData && setOnCall(false);
  // }, [appointmenData]);

  const flag = localStorage.getItem("callPageLeaveFlag");

  return (
    <>
      <header
        className={classNames(
          "fixed top-0 left-0 w-full z-20 bg-transparent flex flex-col items-center transition-all duration-200",
          isJoinCall
            ? scrolled
              ? "md:h-32 h-28 bg-white shadow-md"
              : "md:h-40 h-32"
            : scrolled
            ? "md:h-24 h-20 bg-white shadow-md"
            : "md:h-32 h-20",
          headerClass
        )}
      >
        {!isOnline && (
          <div className="relative w-full bg-red-600 md:px-8 pl-1 pr-4 flex items-center h-10 text-center">
            You are offline!
          </div>
        )}
        {/* {isJoinCall && */}
        {
          // token &&
          //   disconnectedProfile?.disconnected &&
          //   disconnectedProfile?.userId === profile?._id &&
          //   (appointmenData?.callerData?.user?.id == profile?._id ||
          //     appointmenData?.callerData?.consultant?.id == profile?._id)
          flag && token && appointmenData && (
            <div className="relative w-full bg-prontopsy-blue md:px-8 pl-1 pr-4 flex items-center h-10">
              <div className="w-full md:px-4 px-2 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center justify-between">
                <div className="flex items-center w-full">
                  <div className="flex gap-5 w-full items-center">
                    <div className="text-prontopsy-blue lg:text-xl text-base lg:w-8 w-7 aspect-square md:flex justify-center items-center bg-white rounded-full hidden">
                      <i class="fa-sharp fa-solid fa-bell"></i>
                    </div>

                    <div className="w-full text-white lg:text-sm md:text-xs text-sm animate-pulse">
                      {/* Do You Want to Join The Call? */}
                      {` ${appointmentLang?.inProgressAppointment?.[language]}
                          ${
                            roleCode === "consultant"
                              ? appointmenData?.callerData?.user?.name
                              : appointmenData?.callerData?.consultant?.name
                          }`}
                    </div>
                  </div>
                  <div className="lg:w-full w-6/12 flex justify-end">
                    <Button
                      buttonClasses="md:!px-10 md:px-6 !px-2 !bg-prontopsy-pink !h-8"
                      buttonLabel={`${langConfig?.joinCall?.[language]}`}
                      buttonLabelClasses=""
                      buttonHasLink={false}
                      buttonType="button"
                      buttonFunction={() => {
                        if (
                          // disconnectedProfile?.disconnected &&
                          appointmenData?.mode === "chat"
                        ) {
                          window.location.href = `/text-chat/${appointmenData?.appointmentId}`;
                        } else {
                          window.location.href = `/chats/${appointmenData?.appointmentId}`;
                        }
                      }}
                    />
                  </div>
                  {/* <div className="pl-[5px]">
                  <Button
                    buttonClasses={
                      "!bg-transparent !absolute !top-0 !right-0 md:!text-xl text-base !h-10 !md:px-auto !px-1"
                    }
                    buttonIcon={"fa-regular fa-xmark"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    buttonType={"button"}
                    buttonFunction={() => navigate(`/chats/${appointmenData?.appointmentId}`)}
                  />
                </div> */}
                </div>
              </div>
            </div>
          )
        }
        <div className=" flex items-center w-full my-auto">
          <div className="w-full px-4 sm:max-w-xl md:max-w-3xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
            <div
              className={classNames(
                "relative transition-all duration-200",
                scrolled ? "h-10 md:h-12 lg:h-16" : "h-10 md:h-16 lg:h-20"
              )}
            >
              <Link to={"/"} className="flex w-full h-full">
                <Image
                  src={Logo}
                  alt={"Prontopsy Logo"}
                  effect={"blur"}
                  className={"object-contain"}
                />
              </Link>
            </div>
            <div className="flex ml-auto space-x-3">
              <div className="relative">
                <LanguageDropdown
                  lang={language}
                  setLanguages={setLanguages}
                  languageList={languageList}
                  ukFlag={ukFlag}
                  spainFlag={spainFlag}
                  italyFlag={italyFlag}
                  dropdownClasses={screenWidth < 480 ? "min-w-40" : "min-w-32"}
                />
              </div>
              {!token && (
                <Button
                  buttonHasLink={true}
                  buttonLink={"/signin"}
                  buttonLabel={"Signin"}
                  buttonLabelClasses={"md:block hidden"}
                  buttonIcon={"fa-light fa-user-plus"}
                  buttonIconPosition={"left"}
                  buttonClasses={"uppercase text-sm hover:!bg-prontopsy-blue"}
                />
              )}

              <Button
                buttonHasLink={false}
                buttonIcon={"fa-solid fa-bars-staggered"}
                buttonIconPosition={"left"}
                buttonClasses={
                  "!px-0 text-xl aspect-square !rounded-full !bg-prontopsy-yellow !text-black hover:!bg-prontopsy-blue hover:!text-prontopsy-yellow"
                }
                buttonFunction={() => setMenuToggle(true)}
              />
            </div>
            {token && (
              <div className="flex items-center space-x-2 ml-2">
                <div
                  className="md:w-16 md:h-16 h-12 w-12 rounded-full overflow-hidden flex"
                  onClick={() => navigate("/profile")}
                >
                  <Image
                    src={profileData && profileData?.image}
                    alt={""}
                    effect={"blur"}
                    className={"!object-cover w-full h-full cursor-pointer"}
                  />
                </div>
                <div
                  className="text-sm  font-semibold text-prontopsy-blue hidden md:block cursor-pointer"
                  onClick={() => navigate("/profile")}
                >
                  {profileData.userName ? profileData?.userName : ""}
                </div>

                <div className="relative !ml-0">
                  <Button
                    buttonIcon={"fa-solid fa-bell"}
                    buttonIconPosition={"left"}
                    buttonClasses={
                      "!px-0 !text-2xl aspect-square !text-prontopsy-blue !bg-transparent"
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </header>

      <NavigationPanel
        data={menuData}
        toggle={menuToggle}
        setToggle={setMenuToggle}
      />
      <LogoutModal
        show={showModal}
        onClose={closeModal}
        onConfirm={handleLogOut}
        loaded={loaded}
      />

      <JoinCallModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={callerData?.user}
        callerData={callerData}
        sessionId={sessionId}
        sessionToken={sessionToken}
        audioRef={audioRef}
      />
      <JoinChatModal
        isOpen={isChatModalOpen}
        setIsOpen={setIsChatModalOpen}
        appointmentData={appointment}
        callerData={callerData}
        audioRef={audioRef}
      />
    </>
  );
};

export default DefaultHeader;
