import React, { useEffect, useState } from "react";
import Checkbox from "../../form/Checkbox";
import Ac from "../../../assets/images/paymentsicon/image19.png";
import Ac1 from "../../../assets/images/paymentsicon/image20.png";
import Ac2 from "../../../assets/images/paymentsicon/image21.png";
import Ac4 from "../../../assets/images/paymentsicon/image23.png";
import Ac5 from "../../../assets/images/paymentsicon/image24.png";
import Ac6 from "../../../assets/images/paymentsicon/image25.png";
import Ac7 from "../../../assets/images/paymentsicon/image26.png";
import Ac8 from "../../../assets/images/paymentsicon/image27.png";
import Ac9 from "../../../assets/images/paymentsicon/image28.png";

import StripeForm from "./StripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { addCardService } from "../../../service/stripeService";
import { toast } from "react-toastify";
import SpinLoader from "../table/SpinLoader";

const AddNewCards = ({
  checked,
  setChecked = () => {},
  timeSlotLoaded = true,
}) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [addCardLoaded, setAddCardLoaded] = useState(true);
  const apiKey =
    "pk_test_51GxqGLGRfcBcvK9WTraDAbrRRTA7POPrTYc3fIq1L6CncO7ei5aGBbHudCeKje13HTTyHwGa78X1OZUOWfdkIcCx001yuEfXA1";
  const [stripeToken, setStripeToken] = useState(null);
  const stripePromise = loadStripe(apiKey);

  const dataArray = [
    { src: Ac, alt: "Image 1" },
    { src: Ac1, alt: "Image 2" },
    { src: Ac2, alt: "Image 3" },
    { src: Ac4, alt: "Image 4" },
    { src: Ac5, alt: "Image 5" },
    { src: Ac6, alt: "Image 6" },
    { src: Ac7, alt: "Image 7" },
    { src: Ac8, alt: "Image 8" },
    { src: Ac9, alt: "Image 9" },
    // Add more items as needed
  ];

  const addNewCard = () => {
    setAddCardLoaded(false);
    stripeToken &&
      addCardService({ card_token: stripeToken }).then((res) => {
        setAddCardLoaded(true);
        if (res?.data?.status === 200) {
          // setCardList((pre) => [...pre, res?.data?.newCardData]);
          setChecked(false);
          toast.success(res?.data?.message);
          setStripeToken(null);
        } else if (res?.data?.status === 400) {
          toast.error(res?.data?.message);
          setStripeToken(null);
          setChecked(false);
        }
      });
  };

  useEffect(() => {
    addNewCard();
  }, [stripeToken]);

  return !addCardLoaded ? (
    <>
      <div className="relative flex items-center gap-1">
        <div className="relative">
          <Checkbox
            isChecked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
        </div>
        <div className="flex items-center gap-1 flex-wrap">
          {dataArray.map((item, index) => (
            <div key={index} className="p-1 w-10 h-7 border border-neutral-400">
              <img
                src={item.src}
                alt={item.alt}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      {checked == true && (
        <div className="mt-4 w-full">
          <Elements stripe={stripePromise} options={{ locale: language }}>
            <StripeForm setToken={setStripeToken} />
          </Elements>
        </div>
      )}
    </>
  ) : (
    <div className="flex justify-center mt-4 w-full">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
    </div>
  );
};

export default AddNewCards;
