import React, { useEffect, useState } from "react";
import InterventionItem from "../single/InterventionItem";
import Button from "../form/Button";
import { fetchInterventionList } from "../../service/interventionService";
import { useSelector } from "react-redux";
import { viewallBtn } from "../elements/language";

const AreaOfIntervention = ({ interventionData, length, isheading = true }) => {
  const [data, setData] = useState([]);
  const [upDatedData, setUpDatedData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;

  useEffect(() => {
    let lengthValue = data.length > 0 && data.length;

    const generateSequence = (length) => {
      const pattern = [];
      let values = [1, 2, 3, 3, 1, 2, 2, 3, 1];

      for (let i = 0; i < length; i++) {
        pattern.push(values[i % values.length]);
      }

      return pattern;
      // const reference = [1, 2, 3];
      // const result = [];

      // for (let i = 0; i < length; i++) {
      //   result.push(reference[i % 3]);
      //   if (i > 0 && (i + 1) % 4 === 0) {
      //     result.push(reference[2]);
      //   }
      // }

      // return result;
    };
    let result = length
      ? generateSequence(length)
      : generateSequence(lengthValue);

    const updatedData =
      data.length > 0 &&
      result.length > 0 &&
      data.map((item, index) => {
        return {
          ...item,
          styleType: result[index % result.length],
        };
      });
    updatedData && setUpDatedData(updatedData);
  }, [data]);

  useEffect(() => {
    length
      ? fetchInterventionList({ limit: length }, setData, setLoaded)
      : fetchInterventionList({ limit: 500 }, setData, setLoaded);
  }, []);

  const [areaOfIntervention, setAreaOfInterventiont] = useState({});
  const Pagedata = useSelector((state) => state?.pageSlice?.data);
  useEffect(() => {
    let info =
      Pagedata.length > 0 &&
      Pagedata.filter((elm) => elm?.title?.en == "home_Area_of_Intervention");
    setAreaOfInterventiont(info[0]);
  }, [Pagedata]);

  const areaOfInterventionData = {
    title: areaOfIntervention && areaOfIntervention?.subTitle?.[language],
    description:
      areaOfIntervention && areaOfIntervention?.description?.[language],
  };

  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-14 xl:py-16 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          {!isheading && (
            <div className="w-full mx-auto max-w-6xl text-center">
              <h2
                className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{
                  __html: areaOfInterventionData?.title || "",
                }}
              ></h2>
              <p
                className="text-base lg:text-[17px] text-neutral-700 space-y-4 mt-2 md:mt-4 lg:mt-6 max-w-2xl mx-auto"
                dangerouslySetInnerHTML={{
                  __html: areaOfInterventionData?.description || "",
                }}
              ></p>
            </div>
          )}

          <div className="flex flex-wrap gap-8 mt-10 justify-center">
            {upDatedData.length > 0 &&
              upDatedData.map((elm) => (
                <InterventionItem key={elm._id} data={elm} />
              ))}
          </div>
          {interventionData?.action && (
            <div className="flex items-center justify-center mt-20">
              <Button
                buttonHasLink={true}
                buttonLink={"/area-of-intervention"}
                // buttonLabel={interventionData?.action?.label}
                buttonLabel={viewallBtn?.[language]}
                buttonClasses={
                  "!h-10 lg:!h-12 !bg-prontopsy-blue hover:!bg-prontopsy-pink !px-12"
                }
                buttonLabelClasses={"!text-base"}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default AreaOfIntervention;
