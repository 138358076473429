import axios from "axios";
import BASE_URL from "../config/host";
import { apiRequest } from "../util/async/apiUtils";

export const fetchAppointmentList = async (
  token,
  params,
  setList = () => {},
  setLoaded = () => {},
  setHasNextPage = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/appointment/list`, {
      params: { ...params },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setList(response?.data?.docs?.data);
    setHasNextPage(response?.data?.docs?.metadata?.hasNextPage);
    setLoaded(true);
    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchAppointmentSlots = async (params) => {
  try {
    const res = await apiRequest(
      `${"get"}`,
      `${"/appointment/slot-availibility"}`,
      {
        queries: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};
export const appointmentDetails = async (params) => {
  try {
    const res = await apiRequest(
      `${"get"}`,
      `${`/appointment/details/${params?.id}`}`
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const addConsultantAppointmentNote = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/note/consultant-note"}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};
export const addClientAppointmentNote = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/note/client-note"}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};

export const editAppointmentNote = async (id, params) => {
  try {
    const res = await apiRequest(
      `${"put"}`,
      `${`/note/edit/${id}`}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};

export const appointmentVerification = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${`appointment/authenticate-appointment`}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};
