import React, { useEffect, useState } from "react";
import Accordion from "../Accordian";
import { useSelector } from "react-redux";

const CategoryTwo = ({ data = [] }) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const [accord, setAccord] = useState([]);
  const [openIndex, setOpenIndex] = useState(null); // Track which accordion is open

  useEffect(() => {
    const accordionData =
      data &&
      data.length > 0 &&
      data.map((elm) => ({
        title: elm?.question?.[language],
        content: elm?.answer?.[language],
      }));
    accordionData && setAccord(accordionData);
  }, [language, data]);

  const handleToggle = (index) => {
    setOpenIndex(index === openIndex ? null : index); // Toggle state
  };

  return (
    <div className="py-5 px-5 shadow-2xl">
      {accord.length > 0 &&
        accord.map((accordion, index) => (
          <Accordion
            key={index}
            title={accordion.title}
            accordianTitle={accordion.title}
            iconClass="Custom Icon Class"
            isOpenProp={index === openIndex} // Only the openIndex will be true
            toggleAccordion={() => handleToggle(index)} // Pass function to handle toggle
          >
            {accordion.content}
          </Accordion>
        ))}
    </div>
  );
};

export default CategoryTwo;
