import axios from "axios";
import BASE_URL from "../config/host";

export const contactUsRequest = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.post(`${BASE_URL}/enquiry/add`, {
      ...params,
    });

    if (response.status === 201) {
      setLoaded(true);
    }

    return response.data;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
