import React from "react";

const NoInternet = () => {
  return (
    <div className="fixed bottom-0 left-0 w-full bg-red-600 text-white flex items-center justify-center p-4 shadow-lg">
      <svg
        className="w-6 h-6 mr-2 text-white"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.75 15a3.75 3.75 0 104.5 0M12 3v6m-7.44 3.56l1.41 1.41M21 12l-1.41-1.41M3 12l1.41-1.41M17.03 17.03l1.41 1.41"
        />
      </svg>
      <span>No Internet Connection. Please check your connection.</span>
    </div>
  );
};

export default NoInternet;
