import React, { useEffect, useState } from "react";
import ConsultationItem from "../../single/ConsultationItem";

import IconDirectAccess from "../../../assets/images/icon-direct-access.webp";
import IconOnBooking from "../../../assets/images/icon-on-booking.webp";
import { fetchConsultationCategory } from "../../../service/consultationService";
import { useSelector } from "react-redux";

const Consultation = () => {
  const [data, setData] = useState([]);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  useEffect(() => {
    fetchConsultationCategory(setData);
  }, []);

  let info =
    data.length > 0 &&
    data.map((elm, index) => ({
      _id: elm?._id,
      styleType: index + 1,
      icon: elm?.image?.url,
      name: elm?.title?.[language],
      description: elm?.description?.[language],
    }));

  const consultationData = {
    title: {
      en: "Professional<br/><span class='text-prontopsy-pink'>Consultation</span>",
      es: "Consulta<br/><span class='text-prontopsy-pink'>Profesional</span>",
      it: "Consulenza<br/><span class='text-prontopsy-pink'>Professionale</span>",
    },
    consultation: info,
  };

  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-6xl text-center">
            {consultationData?.title && (
              <h2
                className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{
                  __html: consultationData?.title[language],
                }}
              ></h2>
            )}
          </div>
          {consultationData?.consultation?.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-16 w-full max-w-6xl mx-auto">
              {consultationData?.consultation?.map((item, index) => (
                <ConsultationItem key={index} data={item} />
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Consultation;
