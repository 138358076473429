import React, { useEffect, useState } from "react";
import Image from "../elements/Image";

const SmartPortalItem = ({ data, index, length }) => {
  const [shiftRight, setShiftRight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let rightShiftCount = length - index - 2;
    let rightShift = -rightShiftCount * 80;

    if (screenWidth < 640) {
      rightShift = -rightShiftCount * 40;
    } else if (screenWidth >= 640 && screenWidth < 1024) {
      rightShift = -rightShiftCount * 60;
    } else if (screenWidth >= 1024) {
      rightShift = -rightShiftCount * 80;
    }

    setShiftRight(rightShift);
  }, [screenWidth, length, index]);

  return (
    <>
      <div
        className="relative py-6 block md:flex items-center bg-prontopsy-yellow w-full max-w-full xl:max-w-lg"
        style={{ marginLeft: shiftRight }}
      >
        {data?.icon && (
          <div className="w-16 lg:w-20 aspect-square overflow-hidden flex-shrink-0 mx-auto bg-prontopsy-blue rounded-full p-4 -ml-4 lg:-ml-10">
            <Image
              src={data?.icon}
              alt={""}
              effect={"blur"}
              className={"object-contain"}
            />
          </div>
        )}
        <div className="w-full flex-shrink space-y-2 px-10">
          {data.name && (
            <h4
              className="font-UnisonPro font-bold italic text-base sm:text-lg lg:text-xl text-prontopsy-pink !leading-[1.1]"
              dangerouslySetInnerHTML={{ __html: data.name }}
            ></h4>
          )}
          {data.description && (
            <div
              className="text-sm sm:text-base lg:text-lg text-black !leading-[1.3]"
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default SmartPortalItem;
