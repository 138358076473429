import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OtpField from "./OtpField";
import Button from "../../form/Button";

const OtpModal = ({
  isOpen,
  onClose,
  onSubmit,
  handleGoBack,
  otp,
  setOtp = () => {},
  loaded = true,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const siteData = useSelector((state) => state.siteSettings.data);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    // e.preventDefault();
    // if (!validateEmail(email)) {
    //   setError("Please enter a valid email address.");
    // } else {
    //   onSubmit(email);
    //   onClose();
    // }
    // navigate("/forgot-password");
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className=" flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="pb-[70px] pt-[50px]">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <div className="w-full">
                  <img
                    className="m-auto"
                    src={siteData?.logo?.url}
                    height="40px"
                    width="100px"
                    alt="Your Company"
                    crossorigin="anonymous"
                  />
                </div>
                <div className="text-center font-bold mt-5">
                  <h2 className="text-blue-600 text-2xl">Forget Password ?</h2>
                  <h3 className="mt-4 text-gray-600 text-xl">
                    Enter Your OTP To Reset <br /> Password
                  </h3>
                </div>
                <div className="mt-2">
                  {/* <form onSubmit={handleSubmit}> */}
                  {/* <OtpField length={6} handleOtpChange={setOtp} /> */}
                  <OtpField length={6} handleOtpChange={(e) => setOtp(e)} />

                  <Button
                    buttonFunction={onSubmit}
                    buttonLabel={" Submit"}
                    buttonClasses="w-full mt-4 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                  ></Button>
                  <br />
                  <button
                    onClick={() => handleGoBack()}
                    type="button"
                    className="w-full mt-1 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                  >
                    Go Back
                  </button>
                  <br />
                  <button
                    type="button"
                    className="mt-1 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500    "
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
