import React, { useEffect, useState } from "react";
import PageBanner from "../components/sections/PageBanner";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import ImageBanner from "../assets/images/contact.webp";
import { setTitle } from "../helpers/MetaTag";
import OurTeam from "../components/sections/Contact/OurTeam";
import FollowUs from "../components/sections/Contact/FollowUs";
import ContactInfo from "../components/sections/Contact/ContactInfo";
import { useSelector } from "react-redux";
const Contact = () => {
  setTitle("Prontopsy | Contact Us");
  const [bannerInfo, setbannerInfo] = useState({});
  const data = useSelector((state) => state?.pageSlice?.data);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const siteSettings = useSelector((state) => state.siteSettings.data);

  useEffect(() => {
    let info =
      data.length > 0 && data.filter((elm) => elm?.title?.en == "Contact_us");
    setbannerInfo(info[0]);
  }, [data]);

  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.title?.[language],
    subTitle: bannerInfo?.subTitle,
  };

  // const bannerData = {
  //   image: ImageBanner,
  //   title: "<span class='text-prontopsy-pink'>Contact us</span>",
  // }

  return (
    <>
      <PageBanner bannerData={bannerData} />
      <ContactInfo siteSettings={siteSettings} language={language} />
      <OurTeam bannerTitle={bannerData?.subTitle} language={language} />
      <FollowUs language={language} />
      <SmartPortal />
      <Register />
    </>
  );
};

export default Contact;
