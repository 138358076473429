import { useState } from "react";

const useInternetSpeed = () => {
  const [speed, setSpeed] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkInternetSpeed = () => {
    setLoading(true);
    setSpeed(null);
    setError(null);

    const image = new Image();
    const startTime = new Date().getTime();

    image.onload = () => {
      const endTime = new Date().getTime();
      const duration = (endTime - startTime) / 1000; // Time in seconds
      const bitsLoaded = 500000 * 8; // Image size in bits (500kb image)
      const speedMbps = bitsLoaded / duration / (1024 * 1024); // Speed in Mbps
      setSpeed(speedMbps.toFixed(2));
      setLoading(false);
    };

    image.onerror = () => {
      setError("Error in downloading the file.");
      setLoading(false);
    };

    // Load a small image file from the internet
    image.src =
      "https://upload.wikimedia.org/wikipedia/commons/3/3f/JPEG_example_flower.jpg?timestamp=" +
      new Date().getTime();
  };

  return { speed, loading, error, checkInternetSpeed };
};

export default useInternetSpeed;
