import React, { useEffect, useState } from "react";
import Image from "../../elements/Image";
import User from "../../../assets/images/user-01.webp";
import { useNavigate } from "react-router-dom";
import { fetchEmailList } from "../../../service/emailService";
import dayjs from "dayjs";
import SpinLoader from "../../elements/table/SpinLoader";
import flags from "../../../helpers/showFlag";
const UserEmailList = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    setLoaded(false);
    fetchEmailList().then((res) => {
      setLoaded(true);
      if (res?.data?.docs?.status === 200) {
        setList(res?.data?.docs?.data);
      }
    });
  }, []);

  return (
    <>
      {loaded ? (
        <div className="w-full bg-white shadow-xl h-[calc(100vh-8rem)] overflow-auto md:px-12 px-3 ">
          {/* <div className="flex justify-center items-center sticky top-0 z-50 bg-white">
        <div className="flex overflow-hidden mb-8 space-x-2">
          <input
            type="text"
            placeholder="Search..."
            className="px-4 py-2 w-80 text-gray-700 focus:outline-none rounded-full"
          />
          <button className="flex items-center justify-center bg-prontopsy-pink text-white p-4 rounded-full text-l w-10 h-10">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div> */}
          {list.length > 0 &&
            list.map((elm, index) => (
              <div
                key={elm?._id}
                className="relative flex items-start shadow-md px-5 py-2 cursor-pointer mt-3 md:space-x-4 space-x-2"
                onClick={() =>
                  navigate(
                    `/email-details/${elm?._id}?consultantId=${elm?.otherUserData?._id}`
                  )
                }
              >
                <div className=" md:!w-14 w-10 !aspect-square rounded-full overflow-hidden  flex flex-grow-0 flex-shrink-0">
                  <Image
                    src={elm?.otherUserData?.image?.url}
                    alt=""
                    effect="blur"
                    className="object-cover"
                  />
                </div>
                <div className="md:flex block justify-between flex-grow flex-shrink">
                  <div className="md:pt-0 pt-2">
                    <div className="flex space-x-2">
                      <h2 className="md:text-lg text-base whitespace-nowrap text-prontopsy-blue leading-none mb-[2px]">{`${elm?.otherUserData?.firstName} ${elm?.otherUserData?.lastName}`}</h2>
                      <div className="w-4 aspect-[4/3] overflow-hidden flex-shrink-0 flex">
                        <img
                          src={flags[elm?.otherUserData?.countryData?.iso2]}
                          alt={"selected?.image"}
                          effect={"blur"}
                          className={"object-contain"}
                        />
                      </div>
                    </div>

                    <div className="text-xs italic text-gray-400 mailto:mb-[8px]">
                      {elm?.otherUserData?.email}
                    </div>
                    <div className="text-xs w-full line-clamp-1">
                      {elm?.messageDetail?.text || "No "}
                    </div>
                  </div>

                  <div className="lg:w-4/12 w-full ml-auto text-sm text-right flex flex-col items-end space-y-2 md:mt-0 mt-2">
                    <h3 className="md:text-xs text-[10px]">
                      {/* {dayjs(elm?.createdAt).format("MMM D, YYYY h:mm A")} */}
                      {new Date(elm?.messageDetail?.createdAt).toLocaleString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )}
                    </h3>
                    {/* <h3 className="text-sm bg-prontopsy-blue rounded-full text-white w-6 min-h-6 flex justify-center items-center">
                2
              </h3> */}
                    {/* <div className="bg-prontopsy-pink rounded-full text-slate-100 md:text-sm text-xs w-5 h-5 !p-2 flex justify-center items-center md:relative absolute top-3 right-2">
                      2{" "}
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <SpinLoader />
      )}
    </>
  );
};

export default UserEmailList;
