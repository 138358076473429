import React from "react";

const SpinLoader = () => {
  return (
    <div className=" top-0 left-0 w-full h-[60vh] bg-gray-300 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
      </div>
    </div>
  );
};

export default SpinLoader;
