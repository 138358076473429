import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: [],
};

export const consultantStatusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setConsultantStatus: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setConsultantStatus } = consultantStatusSlice.actions;
export default consultantStatusSlice.reducer;
