import { useEffect } from "react";

function useDetectPageLeave(callback) {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      callback(); // Call your custom logic

      // Optionally prevent default action (like showing a confirmation dialog)
      //   event.preventDefault();
      //   event.returnValue = ""; // This ensures the browser shows the confirmation prompt
    };

    // Add event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [callback]); // Ensure the latest callback is used
}

export default useDetectPageLeave;
