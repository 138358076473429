import React from "react";
import Image from "../elements/Image";
import Button from "../form/Button";
import dayjs from "dayjs";
import { readmoreBtn } from "../elements/language";

const BlogItem = ({ data, language }) => {
  return (
    <div className="relative w-full space-y-2 lg:space-y-4">
      <div className="w-full aspect-[8/5] overflow-hidden">
        <Image
          src={"https://dev.api.prontopsy.com/storage/blog/17150761236607ec0dc38ff263faa1756c60b.jpeg"}
          alt={"Poster"}
          effect={"blur"}
          className={"w-full h-full object-cover"}
        />
      </div>

      {data?.createdAt && (
        <div className="flex items-center gap-1 text-neutral-700 text-xs">
          <span>
            <i class="fa-regular fa-clock"></i>
          </span>
          <span>{dayjs(data?.createdAt).format("DD/MM/YYYY")}</span>
        </div>
      )}
      <div className="flex-shrink w-full">
        {data.title && (
          <h4
            className="line-clamp-2 font-UnisonPro font-bold italic text-lg md:text-xl xl:text-2xl text-prontopsy-blue !leading-[1.1] uppercase"
            dangerouslySetInnerHTML={{ __html: data?.title?.[language] }}></h4>
        )}
        {data.shortDescription && (
          <div
            className="line-clamp-4 text-xs md:text-sm xl:text-base text-neutral-700 mt-2 md:mt-4 lg:mt-6 space-y-3"
            dangerouslySetInnerHTML={{ __html: data.shortDescription?.[language] }}></div>
        )}
      </div>
      {/* {data?.action && ( */}
      <div className="flex">
        <Button
          buttonHasLink={true}
          buttonLink={data?.action?.link}
          buttonLabel={readmoreBtn?.[language]}
          buttonClasses={"!h-10 lg:!h-12 !bg-prontopsy-blue !text-white hover:!bg-prontopsy-pink !px-12"}
          buttonLabelClasses={"!text-sm lg:!text-base !text-white !uppercase"}
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default BlogItem;
