import React from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AuthLayout from "./layouts/AuthLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import Signin from "./pages/Signin";
import Home from "./pages/Home";
import FindPsychologist from "./pages/FindPsychologist";
import HowItWorks from "./pages/HowItWorks";
import About from "./pages/About";
import Blog from "./pages/Blog";
import AreaIntervention from "./pages/AreaIntervention";
import CommunityPsychology from "./pages/CommunityPsychology";
import Contact from "./pages/Contact";
import SignUp from "./pages/SignUp";
import Feedback from "./pages/Feedback";
import InnerLayout from "./layouts/InnerLayout";
import Faq from "./pages/Faq";
import ControlPanel from "./pages/profile/ControlPanel";
import ConsultantDetails from "./pages/ConsultantDetails";
import ForgotPassword from "./components/sections/signup/ForgotPassword";
import { useSelector } from "react-redux";
import NotFoundPage from "./pages/NotFoundPage";
import ConsultantSignup from "./pages/profile/ConsultantSignup";
import InterventionDetails from "./pages/InterventionDetails";
import { profileSideBar } from "./components/elements/language";
import EmailChats from "./pages/EmailChats";
import Chats from "./pages/Chats";
import BlankLayout from "./layouts/BlankLayout";
import MyProfile from "./pages/profile/MyProfile";
import Appointments from "./pages/profile/Appointments";
import MyClients from "./pages/profile/MyClients";
import Reviews from "./pages/profile/Reviews";
import AppointmentsDetails from "./pages/profile/AppuntamentiPassatiDetails";
import ProtectedRoute from "./protectedRoute";
import UserEmailDetails from "./components/items/emailchatBox/userEmailDetails";
import ClientNoteList from "./components/items/clientNotes/ClientNoteList";
import WishList from "./pages/profile/WishList";
import FreeAuthRoute from "./FreeAuthRoute";
import CheckProfile from "./middlewares/CheckProfile";
import TextChat from "./pages/TextChat";
import ReviewPage from "./pages/ReviewPage";
import TermsandConditions from "./pages/TermsandConditions";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import NoInternet from "./pages/NoInternet";

const CreateRoutes = () => {
  const localLang = useSelector((state) => state.languageSlice.data);
  const language = localStorage.getItem("language") || localLang;
  const user = useSelector((state) => state?.initialProfileSlice?.data);
  const id = user && user?._id;

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/signin" element={<AuthLayout />}>
            <Route
              path="/signin"
              exact
              element={
                <FreeAuthRoute>
                  <Signin />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/signup" element={<AuthLayout />}>
            <Route
              path="/signup"
              exact
              element={
                <FreeAuthRoute>
                  <SignUp />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/signup-consultant" element={<AuthLayout />}>
            <Route
              path="/signup-consultant"
              exact
              element={
                <FreeAuthRoute>
                  <ConsultantSignup />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/forgot-password" element={<AuthLayout />}>
            <Route
              path="/forgot-password"
              exact
              element={
                <FreeAuthRoute>
                  <ForgotPassword />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/feedback" element={<AuthLayout />}>
            <Route path="/feedback" exact element={<Feedback />} />
          </Route>
          <Route path="/consultant-profile/:id" element={<AuthLayout />}>
            <Route
              path="/consultant-profile/:id"
              exact
              element={<ConsultantDetails />}
            />
          </Route>
          <Route path="/" element={<DefaultLayout />}>
            <Route path="/" exact element={<Home />} />
          </Route>
          <Route path="/find-psychologist" element={<DefaultLayout />}>
            <Route
              path="/find-psychologist"
              exact
              element={<FindPsychologist />}
            />
          </Route>
          <Route path="/howitworks" element={<DefaultLayout />}>
            <Route path="/howitworks" exact element={<HowItWorks />} />
          </Route>
          <Route path="/about" element={<DefaultLayout />}>
            <Route path="/about" exact element={<About />} />
          </Route>
          <Route path="/blog" element={<DefaultLayout />}>
            <Route path="/blog" exact element={<Blog />} />
          </Route>
          <Route path="/terms-of-services" element={<DefaultLayout />}>
            <Route
              path="/terms-of-services"
              exact
              element={<TermsandConditions />}
            />
          </Route>
          <Route path="/privacy-policy" element={<DefaultLayout />}>
            <Route
              path="/privacy-policy"
              exact
              element={<PrivacyPolicyPage />}
            />
          </Route>
          <Route path="/area-of-intervention" element={<DefaultLayout />}>
            <Route
              path="/area-of-intervention"
              exact
              element={<AreaIntervention />}
            />
          </Route>
          <Route path="/community-psychology" element={<DefaultLayout />}>
            <Route
              path="/community-psychology"
              exact
              element={<CommunityPsychology />}
            />
          </Route>
          <Route path="/contact-us" element={<DefaultLayout />}>
            <Route path="/contact-us" exact element={<Contact />} />
          </Route>
          <Route path="/frequently-asked-questions" element={<DefaultLayout />}>
            <Route path="/frequently-asked-questions" exact element={<Faq />} />
          </Route>
          <Route
            path="/control-panel"
            element={
              <ProtectedRoute>
                <InnerLayout
                  profileTitle={profileSideBar?.controlPanel?.[language]}
                />
              </ProtectedRoute>
            }
          >
            <Route
              path="/control-panel"
              exact
              element={
                <CheckProfile>
                  <ControlPanel />
                </CheckProfile>
              }
            />
          </Route>
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <InnerLayout
                  profileTitle={profileSideBar?.myProfile?.[language]}
                />
              </ProtectedRoute>
            }
          >
            <Route path="/profile" exact element={<MyProfile />} />
          </Route>
          <Route
            path="/view-online"
            element={
              <ProtectedRoute>
                <InnerLayout
                  profileTitle={profileSideBar?.viewOnline?.[language]}
                />
              </ProtectedRoute>
            }
          >
            <Route
              path="/view-online"
              exact
              element={<ConsultantDetails userId={id} />}
            />
          </Route>
          <Route
            path="/wish-list"
            element={
              <ProtectedRoute>
                <InnerLayout
                  profileTitle={profileSideBar?.wishList?.[language]}
                />
              </ProtectedRoute>
            }
          >
            <Route
              path="/wish-list"
              exact
              element={
                <CheckProfile>
                  <WishList />
                </CheckProfile>
              }
            />
          </Route>
          <Route
            path="/appointments"
            element={
              <ProtectedRoute>
                <InnerLayout
                  profileTitle={profileSideBar?.appointments?.[language]}
                />
              </ProtectedRoute>
            }
          >
            <Route
              path="/appointments"
              exact
              element={
                <CheckProfile>
                  <Appointments />
                </CheckProfile>
              }
            />
          </Route>
          <Route
            path="/clients"
            element={
              <ProtectedRoute>
                <InnerLayout
                  profileTitle={profileSideBar?.myClients?.[language]}
                />
              </ProtectedRoute>
            }
          >
            <Route
              path="/clients"
              exact
              element={
                <CheckProfile>
                  <MyClients />
                </CheckProfile>
              }
            />
          </Route>
          <Route
            path="/client-notes/:id"
            element={
              <ProtectedRoute>
                <InnerLayout
                  profileTitle={profileSideBar?.myClients?.[language]}
                />
              </ProtectedRoute>
            }
          >
            <Route
              path="/client-notes/:id"
              exact
              element={<ClientNoteList />}
            />
          </Route>
          <Route
            path="/email"
            element={
              <ProtectedRoute>
                <InnerLayout profileTitle="E-mail" />
              </ProtectedRoute>
            }
          >
            <Route
              path="/email"
              exact
              element={
                <CheckProfile>
                  <EmailChats />
                </CheckProfile>
              }
            />
          </Route>
          <Route
            path="/email-details/:id"
            element={
              <ProtectedRoute>
                <InnerLayout />
              </ProtectedRoute>
            }
          >
            <Route
              path="/email-details/:id"
              exact
              element={<UserEmailDetails />}
            />
          </Route>
          <Route
            path="/reviews"
            element={
              <ProtectedRoute>
                <InnerLayout
                  profileTitle={profileSideBar?.myReviews?.[language]}
                />
              </ProtectedRoute>
            }
          >
            <Route
              path="/reviews"
              exact
              element={
                <CheckProfile>
                  <Reviews />
                </CheckProfile>
              }
            />
          </Route>
          <Route
            path="/appointments-details"
            element={
              <ProtectedRoute>
                <InnerLayout
                  profileTitle={profileSideBar?.appointments?.[language]}
                />
              </ProtectedRoute>
            }
          >
            <Route
              path="/appointments-details/:id"
              exact
              element={<AppointmentsDetails />}
            />
          </Route>
          <Route path="/intervention-details/:id" element={<DefaultLayout />}>
            <Route
              path="/intervention-details/:id"
              exact
              element={<InterventionDetails />}
            />
          </Route>

          <Route
            path="/text-chat/:id"
            element={
              <ProtectedRoute>
                <BlankLayout />
              </ProtectedRoute>
            }
          >
            <Route path="/text-chat/:id" exact element={<TextChat />} />
          </Route>

          <Route
            path="/chats/:id"
            element={
              <ProtectedRoute>
                <BlankLayout />
              </ProtectedRoute>
            }
          >
            <Route path="/chats/:id" exact element={<Chats />} />
          </Route>

          <Route path="/review-appointment/:id">
            <Route
              path="/review-appointment/:id"
              exact
              element={
                <ProtectedRoute>
                  <ReviewPage />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
          <Route
            path="/no-connection"
            element={
              <DefaultLayout>
                <NoInternet />
              </DefaultLayout>
            }
            exact
          />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
