import { useEffect, useState } from "react";

const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Initialize with current status

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true); // Set online status
      window.location.reload();
    };

    const handleOffline = () => {
      setIsOnline(false); // Set offline status
    };

    // Add event listeners for online/offline changes
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return isOnline; // Optional: Return the status for further use if needed
};

export default useOnlineStatus;
