import { useEffect, useRef } from "react";

function useMediaStream() {
  const mediaStreamRef = useRef(null);

  // Function to close the camera and microphone
  const closeMediaDevices = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  // Optionally, you can start the media devices when the component mounts
  useEffect(() => {
    const startMediaDevices = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        mediaStreamRef.current = stream;
        // Do something with the stream, e.g., attach it to a video element
      } catch (err) {
        console.error("Error accessing media devices:", err);
      }
    };

    startMediaDevices();

    return () => {
      // Clean up by closing media devices when the component unmounts
      closeMediaDevices();
    };
  }, []);

  return {
    closeMediaDevices,
  };
}

export default useMediaStream;
