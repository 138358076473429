import axios from "axios";
import BASE_URL from "../config/host";

export const fetchDashboardStatistics = async (
  setData,
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/dashboard/view-stat-first`);

    if (response?.data?.status === 200) {
      setData(response?.data?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchDashboard = async (token, setData, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/dashboard/view-front`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response?.data?.status === 200) {
      setData(response?.data?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
