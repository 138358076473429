import React from "react";
import Image from "../elements/Image";

const OnlineFeatureItem = ({ data }) => {
  return (
    <>
      <div className="relative overflow-hidden py-4 md:py-6 lg:py-8 xl:py-12 px-4 md:px-6 lg:px-8 xl:px-12 bg-white text-center shadow transition-all duration-200 hover:shadow-xl">
        {data?.icon && (
          <div className="w-16 md:w-20 lg:w-24 overflow-hidden mb-8 flex-shrink-0 mx-auto bg-neutral-100 p-4 xl:p-6 rounded-full">
            <Image
              src={data?.icon}
              alt={""}
              effect={"blur"}
              className={"object-contain"}
            />
          </div>
        )}
        {data.name && (
          <h4
            className="font-UnisonPro font-bold italic text-lg lg:text-[20px] text-prontopsy-pink !leading-[1.1]"
            dangerouslySetInnerHTML={{ __html: data.name }}
          ></h4>
        )}
        {data.description && (
          <div
            className="text-sm lg:text-[17px] text-black mt-6 w-full max-w-md mx-auto"
            dangerouslySetInnerHTML={{ __html: data.description }}
          ></div>
        )}
      </div>
    </>
  );
};

export default OnlineFeatureItem;
