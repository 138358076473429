import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import { useSelector } from "react-redux";

const ParentTabs = ({
  tabs = [],
  design,
  divClass,
  mainClasses,
  tabContentdivClasses,
  buttonClass,
  textClasses,
  selectedTab,
  setSelectedTab = () => {},
  ...props
}) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;
  const handleTabSelection = (tab = {}) => {
    setTimeout(() => {
      let activeTab = [];
      let filteredTab = tabs.filter((tab) => activeTab.includes(tab._id))[0];
      if (filteredTab) {
        activeTab = [
          ...activeTab.filter((actab) => actab !== filteredTab._id),
          tab?._id,
        ];
      } else {
        activeTab = [...activeTab, tab?._id];
      }
      setSelectedTab(tab);
    }, 100);
  };

  useEffect(() => {
    if (tabs.length > 0) {
      setSelectedTab(tabs[0]);
    }
  }, [tabs.length > 0, language]);

  return (
    <div className={classNames("relative flex ", mainClasses)}>
      <nav
        className={classNames(
          "w-1/5 flex overflow-auto relative after:bg-white after:absolute after:top-0 after:left-1/2 after:transform after:-translate-x-1/2 after:h-full after:content-[''] after:z-[-1] after:lg:w-96 after:w-64",
          divClass,
          design === "style"
            ? "border-b border-slate-200 px-4 xl:px-8 gap-5 xl:gap-6"
            : "gap-3 xl:gap-4"
        )}
      >
        {tabs &&
          Array.isArray(tabs) &&
          tabs.length > 0 &&
          tabs.map((item, index) => (
            <button
              type="button"
              className={classNames(
                "relative py-3 xl:py-4 rounded-md bg-transparent border border-black transition-all duration-200",
                buttonClass,
                design === "style"
                  ? "border-b-2 border-transparent px-0"
                  : "px-6 xl:px-12",
                item._id === selectedTab?._id
                  ? design === "style"
                    ? "!border-prontopsy-pink"
                    : " !border-[#B32DBD] !bg-prontopsy-pink"
                  : design === "style"
                  ? "bg-prontopsy-pink"
                  : "!bg-prontopsy-blue"
              )}
              key={index}
              onClick={() => handleTabSelection(item)}
            >
              <div
                className={classNames(
                  "whitespace-nowrap",
                  textClasses,
                  design === "style"
                    ? "text-sm font-semibold"
                    : "text-sm xl:text-base font-medium",
                  item._id === selectedTab?._id
                    ? design === "style"
                      ? "!text-carnation-500"
                      : "text-white"
                    : "text-white"
                )}
              >
                {item.name}
              </div>
            </button>
          ))}
      </nav>
      <div
        className={classNames("w-4/5 relative bg-white", tabContentdivClasses)}
      >
        {selectedTab?.component}
      </div>
    </div>
  );
};

export default ParentTabs;
