import React from "react";
import Modal from "./Modal";
import Button from "../form/Button";
import { useSelector } from "react-redux";
import { appointmentModalLang } from "./language";
import Checkbox from "../form/Checkbox";
import Cards from "./payment/Cards";
import Image from "./Image";
import americanExpress from "../../assets/images/cardicon/American Express.png";
import dinersClub from "../../assets/images/cardicon/Diners Club.png";
import discover from "../../assets/images/cardicon/Discover.png";
import elo from "../../assets/images/cardicon/elo.png";
import hipercard from "../../assets/images/cardicon/hipercard-29.png";
import jcb from "../../assets/images/cardicon/JCB.png";
import masterCard from "../../assets/images/cardicon/MasterCard.png";
import mnp from "../../assets/images/cardicon/mnp.png";
import troy from "../../assets/images/cardicon/troy.png";
import unionPay from "../../assets/images/cardicon/UnionPay.png";
import visa from "../../assets/images/cardicon/Visa.png";

const SecondResume = ({
  isOpen,
  setIsOpen = () => {},
  handleContinue = () => {},
  loading,
  data,
  mode,
  selectedCard,
}) => {
  const lang = useSelector((state) => state?.languageSlice?.data);
  const language = localStorage.getItem("language") || lang;

  const getCardImage = (brand) => {
    switch (brand) {
      case "american_express":
      case "American Express":
        return americanExpress;
      case "diners_club":
      case "Diners Club":
        return dinersClub;
      case "discover":
      case "Discover":
        return discover;
      case "visa":
      case "Visa":
        return visa;
      case "mastercard":
      case "MasterCard":
        return masterCard;
      case "jcb":
      case "JCB":
        return jcb;
      case "unionpay":
      case "UnionPay":
        return unionPay;
      case "hipercard":
      case "Hipercard":
        return hipercard;
      case "mnp":
      case "MNP":
        return mnp;
      case "elo":
      case "ELO":
        return elo;
      default:
        return troy;
    }
  };
  return (
    <Modal
      //   bodyClass={"!bg-slate-800"}
      isOpen={isOpen}
      titleShow={false}
      //   size={"lg"}
      // modalTitle={appointmentModalLang?.title10?.[language]}
    >
      <div>
        <Button
          buttonClasses={
            "z-10 !w-10 !h-10 !rounded-full !border-black !bg-transparent shadow-md !absolute !top-2 !right-2 !text-black !flex-row !items-center !justify-center"
          }
          buttonIcon={"fa-regular fa-xmark"}
          buttonIconPosition={"left"}
          buttonFunction={() => setIsOpen(false)}
        />
        <div className="relative space-y-2  ">
          <div className="relative">
            <div className="text-2xl font-bold text-prontopsy-blue leading-none">
              {mode !== "chat"
                ? appointmentModalLang?.title10?.[language]
                : appointmentModalLang?.title12?.[language]}{" "}
              <br></br>
              <span className="text-prontopsy-pink text-2xl">{data?.name}</span>
            </div>
          </div>
          <div className="pt-5 pb-4 space-y-1">
            <div className="capitalize text-base font-bold">
              Mode :<span className="font-normal pl-1"> {mode}</span>
            </div>
            <div className="capitalize text-base font-bold">
              Price :{" "}
              <span className="font-normal pl-1">
                {data?.rate?.value + " " + data?.rate?.currency}/MIN
              </span>
              <span className="text-prontopsy-dodgerBlue pl-4">
                (Maximum amount captured :{" "}
                {data?.rate?.currency + " " + data?.rate?.value * 60})
              </span>
            </div>
            <div className="capitalize text-base font-bold flex justify-left items-center w-full">
              {/* Payment Method : <span className="font-normal pl-1">Stripe</span> */}
              Payment Method :{" "}
              <div className="flex items-center border rounded-sm p-3 ml-3 bg-slate-50">
                <div className="w-10 overflow-hidden mx-2">
                  <Image
                    src={getCardImage(selectedCard?.brand)}
                    alt={selectedCard?.brand}
                    className="w-full h-full object-contain"
                  />
                </div>
                <span className="mx-4">{`xxx-xxx-xxxx-${selectedCard?.last4}`}</span>
              </div>
            </div>
          </div>

          <div className="bg-slate-200/50 w-full py-3 !my-3 px-4 rounded-md">
            <div className="md:flex items-center gap-5 md:space-y-0 space-y-2">
              <div className="flex md:gap-5 gap-3 w-full items-center">
                <span className="text-green-600 flex-shrink-0">
                  <i className="fa-solid fa-lock"></i>
                </span>
                <div
                  className="font-RobotoSlab flex-shrink w-full text-gray-700 text-sm font-normal"
                  dangerouslySetInnerHTML={{
                    __html: appointmentModalLang?.title3?.[language],
                  }}
                />
              </div>
              <div className="h-8 min-w-40 border border-gray-400 flex items-center justify-center gap-2 rounded-md">
                <div className="font-RobotoSlab text-gray-400 text-xs font-normal">
                  Powered by
                </div>
                <div className="text-4xl text-gray-400">
                  <i class="fa-brands fa-stripe"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="relative space-y-4">
            <Checkbox
              checkboxLabel={appointmentModalLang?.title4?.[language]}
              checkboxLabelClass={`!font-RobotoSlab !text-black`}
              isChecked={true}
            />
            <Checkbox
              checkboxLabel={appointmentModalLang?.title5?.[language]}
              checkboxLabelClass={`!font-RobotoSlab !text-black`}
              isChecked={true}
            />
          </div>

          <div className="relative !mt-4 flex w-full justify-end">
            <Button
              buttonLabel="Start"
              buttonClasses={`!bg-prontopsy-blue !h-12 !px-6`}
              buttonLabelClasses={`!font-UnisonPro !text-xs !text-white !font-bold !italic`}
              buttonFunction={handleContinue}
              isDisable={loading}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SecondResume;
